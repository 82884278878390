import { useEligibilityQuery } from '@/apis/hooks'
import { UseSafeQueryOptions } from '@/apis/hooks/utils'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { Eligibility } from '@/types'
import { eurosToCents } from '@/utils'
import { useDebounce } from '@/utils/throttleDebounceHooks'
import { useFormContext } from 'react-hook-form'

/**
 * Return the eligibility matching the values filled in the PaymentCreate form
 * Also provide the related values, in order to take the associated debounce into account
 */
export const useCurrentEligibility = (
  options?: UseSafeQueryOptions<Eligibility[], unknown, Eligibility>
) => {
  const { watch } = useFormContext<PaymentCreateFormValues>()
  const [feePlan, formattedAmountInEuros] = watch([
    'purchase.feePlan',
    'purchase.formattedAmountInEuros',
  ])
  // Debounce amount to avoid unnecessary requests during typing
  const debouncedAmountInCents = useDebounce(eurosToCents(Number(formattedAmountInEuros)), 300)
  const eligibility = useEligibilityQuery(feePlan, debouncedAmountInCents, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...options,
  })

  return { eligibility, debouncedAmountInCents, feePlan }
}
