import { useDefaultCustomFormValues } from '@/components/Forms/PaymentCreate/CustomDataForm'
import { useDefaultCustomerFormValues } from '@/components/Forms/PaymentCreate/CustomerInfoForm/CustomerInfoForm'
import { DEFAULT_OFFER_FEES_FORM_VALUES } from '@/components/Forms/PaymentCreate/OfferFeesForm'

import { DEFAULT_ORDER_FORM_VALUES } from '@/components/Forms/PaymentCreate/OrderForm'

import { DEFAULT_PHONE_NUMBER_VALUES } from '@/components/Forms/PaymentCreate/PhoneNumberForm'
import {
  isDefaultPurchaseFormValues,
  useDefaultPurchaseFormValues,
} from '@/components/Forms/PaymentCreate/PurchaseForm'
import { DEFAULT_REQUIREMENTS_FORM_VALUES } from '@/components/Forms/PaymentCreate/RequirementsForm/RequirementsForm'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { useDefaultTerminalFormValues } from '@/components/SelectTerminal/SelectTerminal'
import { useMemo } from 'react'
import { DeepPartial } from 'react-hook-form'

export {
  useDefaultCustomFormValues,
  CustomDataForm,
} from '@/components/Forms/PaymentCreate/CustomDataForm'
export { CustomerInfoForm } from '@/components/Forms/PaymentCreate/CustomerInfoForm'
export { OrderForm, DEFAULT_ORDER_FORM_VALUES } from '@/components/Forms/PaymentCreate/OrderForm'
export {
  PurchaseForm,
  useDefaultPurchaseFormValues,
  isDefaultPurchaseFormValues,
} from '@/components/Forms/PaymentCreate/PurchaseForm'
export {
  OfferFeesForm,
  DEFAULT_OFFER_FEES_FORM_VALUES,
} from '@/components/Forms/PaymentCreate/OfferFeesForm'
export {
  RequirementsForm,
  DEFAULT_REQUIREMENT_FORM_VALUES,
} from '@/components/Forms/PaymentCreate/RequirementsForm'

const DEFAULT_VALUES_NOT_YET_AVAILABLE = Symbol('DEFAULT_VALUES_NOT_YET_AVAILABLE')

export const isDefaultPaymentFormValues = (
  values: DeepPartial<PaymentCreateFormValues> | typeof DEFAULT_VALUES_NOT_YET_AVAILABLE
): values is DeepPartial<PaymentCreateFormValues> => values !== DEFAULT_VALUES_NOT_YET_AVAILABLE

export const useDefaultPaymentFormValues = ():
  | DeepPartial<PaymentCreateFormValues>
  | typeof DEFAULT_VALUES_NOT_YET_AVAILABLE => {
  const defaultCustomFormValues = useDefaultCustomFormValues()
  const defaultPurchaseFormValues = useDefaultPurchaseFormValues()
  const defaultTerminalFormValues = useDefaultTerminalFormValues()
  const defaultCustomerFormValues = useDefaultCustomerFormValues()
  return useMemo(
    () =>
      isDefaultPurchaseFormValues(defaultPurchaseFormValues)
        ? {
            ...defaultCustomFormValues,
            ...defaultCustomerFormValues,
            ...DEFAULT_ORDER_FORM_VALUES,
            ...defaultPurchaseFormValues,
            ...DEFAULT_OFFER_FEES_FORM_VALUES,
            ...DEFAULT_PHONE_NUMBER_VALUES,
            ...DEFAULT_REQUIREMENTS_FORM_VALUES,
            link: {},
            ...defaultTerminalFormValues,
          }
        : DEFAULT_VALUES_NOT_YET_AVAILABLE,
    [
      defaultCustomFormValues,
      defaultCustomerFormValues,
      defaultPurchaseFormValues,
      defaultTerminalFormValues,
    ]
  )
}
