import React, { VoidFunctionComponent } from 'react'
import { useScreenBreakpoint } from '@/utils/useScreenBreakpoint'
import { RequirementsTableLg } from '@/components/RequirementsTable/RequirementsTableLg'
import { RequirementsTableSm } from '@/components/RequirementsTable/RequirementsTableSm'
import { RequirementsTableProps } from '@/components/RequirementsTable/props'

export const RequirementsTable: VoidFunctionComponent<RequirementsTableProps> = ({ ...props }) => {
  const isMediumScreen = useScreenBreakpoint('md')

  return isMediumScreen ? <RequirementsTableLg {...props} /> : <RequirementsTableSm {...props} />
}
