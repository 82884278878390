import s from '@/components/Installments/InstallmentsTotal/InstallmentsTotal.module.css'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

type Props = {
  feesInEuros: number
  offerFees: boolean
}

export const InstallmentsTotalFees: VoidFunctionComponent<Props> = ({ feesInEuros, offerFees }) => (
  <div className={s.fees}>
    <div>
      <FormattedMessage
        id="installments.total-fees"
        defaultMessage="Fees"
        description="This is the line  displayed in the installment plan summary in the payment form, where the seller can see the total amount of fees the customer will have to pay if the merchant decided to do some fee sharing"
      />
    </div>
    <div className={s.feesNumbers}>
      {offerFees && (
        <span className={s.discountedFees}>
          <FormattedNumber value={feesInEuros} style="currency" currency="EUR" />
        </span>
      )}
      <FormattedNumber value={offerFees ? 0 : feesInEuros} style="currency" currency="EUR" />
    </div>
  </div>
)
