import { Barcode } from '@/components/Barcode/Barcode'
import s from '@/components/Pages/PaymentBarcode/PaymentBarcode.module.css'
import { GreyZone, Header } from '@/components/PaymentsModal/Templates'
import { Payment } from '@/types'
import { paymentIdToShortPaymentId } from '@/utils/paymentIdToShortPaymentId'
import { usePaymentDetails } from '@/utils/usePaymentDetails'
import { Button, Header as HeaderRC } from '@alma/react-components'
import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

type Props = {
  payment: Payment
  goBack: () => void
}
export const PaymentBarcodeContent: FunctionComponent<Props> = ({ payment, goBack }) => {
  const { amountAndPlan, createdDate, customerName } = usePaymentDetails(payment)

  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />
      <div className={s.content}>
        <GreyZone>
          <div className={s.barcodeContainer}>
            <HeaderRC size="md" as="h3">
              <FormattedMessage
                id="paymentBarcode.info.text"
                defaultMessage="Barcode to print only for an in-store sale"
                description="On the barcode modal for MDM validated payment, there is a text with more information for the salesperson."
              />
            </HeaderRC>
            <Barcode shortPaymentId={paymentIdToShortPaymentId(payment.id)} />
            <div>{paymentIdToShortPaymentId(payment.id)}</div>
          </div>
        </GreyZone>
      </div>
      <Button block color="primary" onClick={goBack}>
        <FormattedMessage
          id="paymentBarcode.close"
          defaultMessage="Close"
          description="In the payment barcode popup, there is a CTA to close the popup."
        />
      </Button>
    </>
  )
}
