import { App } from '@/components/App'
import '@/index.css'
import '@/polyfill'
import '@alma/react-components/global.css'
import '@alma/react-components/style.css'

import React from 'react'
import ReactDOM from 'react-dom'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
