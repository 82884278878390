export enum Locale {
  en = 'en',
  fr = 'fr',
  de = 'de',
  it = 'it',
  es = 'es',
  nl = 'nl',
  pt = 'pt',
}

export function isLocale(value: unknown): value is Locale {
  return typeof value === 'string' && Boolean(Locale[value as Locale])
}
