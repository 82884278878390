import { FeePlan } from '@/types'

export const sortFeePlanOptions = (feePlans: FeePlan[]): FeePlan[] => {
  const sortedFeePlanOptions = []
  const deferredDays = feePlans
    .filter((feePlan) => feePlan.deferred_days)
    .sort((a, b) => a.deferred_days - b.deferred_days)
  const deferredMonths = feePlans
    .filter((feePlan) => feePlan.deferred_months)
    .sort((a, b) => a.deferred_months - b.deferred_months)
  const installmentsCount = feePlans
    .filter(
      (feePlan) =>
        feePlan.installments_count && feePlan.deferred_days === 0 && feePlan.deferred_months === 0
    )
    .sort((a, b) => a.installments_count - b.installments_count)

  // Fee plan should be sorted by deferred days, deferred months, then installments count : cf https://app.clickup.com/t/20427503/AL-950
  sortedFeePlanOptions.push(...deferredDays, ...deferredMonths, ...installmentsCount)

  return sortedFeePlanOptions
}
