import s from '@/components/ClearForm/ClearForm.module.css'
import { Button } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

interface ClearFormProps {
  disabled: boolean
  onClick: () => void
}

export const ClearForm: VoidFunctionComponent<ClearFormProps> = ({ disabled, onClick }) => (
  <div className={s.clearForm}>
    <Button
      color="link-red"
      disabled={disabled}
      onClick={() => onClick()}
      data-testid="button-clear-form"
    >
      <FormattedMessage
        id="home.clear.form.button"
        defaultMessage="Clear forms"
        description="At the end of the payment for, there is a button to clear all fields (customer info, amount, feeplan option and merchant specific info). This is useful when you have to clear the form after a first successful payment."
      />
    </Button>
  </div>
)
