// This file is ignored from code-coverage as it relies on an external
// Zendesk script to be injected in the header and loaded from their servers
/* istanbul ignore file */

import { getZendeskToken } from '@/apis/api'
import { useMe } from '@/components/AuthenticationManager'
import { useTracking } from '@/thirdParties/analytics'
import { handleError, parseAxiosError } from '@/utils'
import { loadExternalService } from '@/utils/externalService'
import React, { useEffect, useMemo, useState, VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IconName } from '@alma/react-components'
import { HelpCenterLink } from '@/components/HelpCenter/HelpCenterLink'

declare global {
  interface Window {
    zE?: ((target: string, action: string, property?: unknown) => void) & {
      hide: () => void
      activate: (option: { hideOnClose?: boolean }) => void
    }
    zESettings?: unknown
    zEmbed?: unknown
    zEACLoaded?: boolean
  }
}

export const ZendeskChatButton: VoidFunctionComponent<{
  handleZendeskClick: () => void
}> = ({ handleZendeskClick }) => {
  const intl = useIntl()
  const me = useMe()

  const [chatIsOpen, setChatIsOpen] = useState(false)
  const track = useTracking('chat', chatIsOpen)
  const ZENDESK_KEY = me.merchant.pos_zendesk_chat_widget_key

  const translatedMessages = useMemo(
    () => ({
      chatHeader: intl.formatMessage({
        id: 'zendesk-chat-title',
        defaultMessage: 'Need help for a sale in store ?',
        description: 'this is the message in the header of the zendesk chat window',
      }),
      chatConciergeFirstLine: intl.formatMessage({
        id: 'zendesk-chat-concierge-first-line',
        defaultMessage: 'Ask your question here !',
        description:
          'On zendesk chat window, underneath the header title, there is another sub-header to guide the merchant. This is the first line of this sub header',
      }),
      chatConciergeSecondLine: intl.formatMessage({
        id: 'zendesk-chat-concierge-second-line',
        defaultMessage: 'We will answer in less than 5 minutes',
        description:
          'On zendesk chat window, underneath the header title, there is another sub-header to guide the merchant. This is the second line of this sub header',
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We want to make sure everything is forced reloaded when the local changes
    [intl, me.locale]
  )

  useEffect(() => {
    if (!ZENDESK_KEY) {
      return undefined
    }

    // Configure Zendesk by setting them here.
    // cf. https://developer.zendesk.com/embeddables/docs/widget/settings
    // Or ... let the ZenDesk users configure everything, from their dashboard.
    window.zESettings = {
      color: {
        theme: '#FA5022',
        launcherText: '#FFFFFF',
      },
      webWidget: {
        chat: {
          title: { '*': translatedMessages.chatHeader },
          concierge: {
            name: translatedMessages.chatConciergeFirstLine,
            title: { '*': translatedMessages.chatConciergeSecondLine },
          },
        },
        position: { horizontal: 'right' },
        offset: {
          // Here those values are meant to put the support chat exactly where we want
          horizontal: '50px',
          vertical: '100px',
        },
        authenticate: {
          chat: {
            jwtFn: (callback: (jwt: string) => void) =>
              getZendeskToken()
                .then(callback)
                .catch((e) => handleError(parseAxiosError(e))),
          },
        },
      },
    }

    let loadedScript: Element | undefined
    void loadExternalService({
      id: 'ze-snippet',
      src: `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`,
    })
      .then((script) => {
        loadedScript = script
        window.zE?.('webWidget', 'reset')
        window.zE?.('webWidget', 'clear')
        window.zE?.('webWidget', 'setLocale', me.locale)
        // This pre-fills the form with the user information if the token authentication fails
        window.zE?.('webWidget', 'prefill', {
          name: { value: me.name },
          email: { value: me.email },
        })
        window.zE?.('webWidget', 'updateSettings', window.zESettings)
        window.zE?.('webWidget:on', 'close', () => {
          setChatIsOpen(false)
          track('contact_close')
        })
        window.zE?.('webWidget', 'hide')
      })
      .catch((error) => {
        handleError(error)
      })

    return () => {
      window.zE?.('webWidget', 'updateSettings', {
        authenticate: {
          chat: {
            jwtFn: () => {},
          },
        },
      })
      window.zE?.('webWidget', 'logout')
      window.zE?.('webWidget', 'reset')
      window.zE?.('webWidget', 'hide')
      if (loadedScript) {
        document.head.removeChild(loadedScript)
      }
      delete window.zESettings
      delete window.zE
      delete window.zEmbed
      delete window.zEACLoaded
    }
  }, [ZENDESK_KEY, me, track, translatedMessages])

  if (ZENDESK_KEY) {
    return (
      <HelpCenterLink
        icon={IconName.bubble}
        onClick={() => {
          window.zE?.('webWidget', 'helpCenter:reauthenticate')
          setChatIsOpen(true)
          track('contact_open')
          window.zE?.activate({ hideOnClose: true })
          handleZendeskClick()
        }}
        iconColor="green"
        buttonTitle={
          <FormattedMessage
            id="help.center.link.to.zendesk.chat"
            defaultMessage="Contact support"
            description="This is a link that opens our zendesk chat modal."
          />
        }
      />
    )
  }
  return null
}
