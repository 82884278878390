import s from '@/components/PaymentsModal/TerminalSteps/Steps/PaymentStepDetail.module.css'
import React, { ReactNode, VoidFunctionComponent } from 'react'

interface Props {
  icon?: ReactNode
  title: ReactNode
  text?: ReactNode
}

export const PaymentStepDetail: VoidFunctionComponent<Props> = ({ icon, title, text }) => (
  <>
    <div className={s.status} data-testid="paymentStatus">
      {icon}
      <div className={s.step}>{title}</div>
    </div>
    {text && <div>{text}</div>}
  </>
)
