import { useEmbedded } from '@/apis/embedded.context'
import { useMe } from '@/components/AuthenticationManager'
import { useGetPaymentQuery } from '@/apis/hooks'
import { useActionsButtons } from '@/components/ActionsButtons'
import { ModalContent } from '@/components/PaymentsModal/ModalContent'
import { useTracking } from '@/thirdParties/analytics'
import { useGoBack } from '@/utils/useGoBack'
import { Modal } from '@alma/react-components'
import React, { useEffect, VoidFunctionComponent } from 'react'
import { useOutletContext, useParams, useResolvedPath, useSearchParams } from 'react-router-dom'
import { ActiveTags, hasFeatureTag } from '@/types'

export const PaymentDetails: VoidFunctionComponent = () => {
  const { paymentId } = useParams<'paymentId'>()
  const { pathname } = useResolvedPath('..')
  const goBack = useGoBack(pathname)
  const track = useTracking('payment_details')
  const { embedded, publishPaymentStatus } = useEmbedded()
  const [searchParams] = useSearchParams()
  const me = useMe()

  // We want to poll data more regularly on storybook to have a full flow without waiting for 15 sec to display each payment step
  const interval = process.env.NODE_ENV === 'storybook' ? 2_500 : 15_000

  const getPaymentQuery = useGetPaymentQuery(paymentId, {
    refetchInterval: (paymentData) => (paymentData?.status === 'validated' ? false : interval),
    enabled: !searchParams.has('update-phone-number'),
  })

  const { data: payment } = getPaymentQuery

  const { cancelOrRestartIsOpened, CancelOrRestartDialogs, ActionButtons } = useActionsButtons(
    track,
    payment
  )

  useEffect(() => {
    if (!payment?.id || !payment.status) {
      return
    }

    publishPaymentStatus(payment.id, payment.status)
  }, [payment?.id, payment?.status, publishPaymentStatus])

  // useOutletContext works only when a context is provided to Outlet
  // We need the context to access resetForm from <Home /> but
  // PaymentDetails can also be called from PaymentFollowup where there is no need to reset form
  // We need to check that context is provided
  // That's why the form const [resetForm] = useOutletContext<(() => void)[]>()
  // is not used directly. To make it work, we need to check if (resetFunction) to use resetForm
  const resetFunction = useOutletContext<(() => void)[]>()

  const handleClose = () => {
    if (resetFunction) {
      const [closeDetailPaymentModal] = resetFunction
      closeDetailPaymentModal()
    } else {
      goBack()
    }
  }

  return (
    <>
      <CancelOrRestartDialogs onCancelled={goBack} />
      <Modal
        isOpen={!cancelOrRestartIsOpened}
        onClose={handleClose}
        scrollable
        size="lg"
        padding="xl"
        closeIcon={
          (me.merchant.id !== payment?.merchant_id &&
            payment?.origin === 'pos_terminal' &&
            payment?.status !== 'validated') ||
          hasFeatureTag(ActiveTags.cantExitFinalize, me)
            ? false
            : !embedded
        }
        shouldCloseOnOverlayClick={false}
      >
        <ModalContent
          getPaymentQuery={getPaymentQuery}
          track={track}
          pathname={pathname}
          ActionButtons={ActionButtons}
        />
      </Modal>
    </>
  )
}
