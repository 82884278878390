import { postContext } from '@/apis/api'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { useSafeMutation, UseSafeMutationOptions } from '@/apis/hooks/utils'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const useContextMutation = (options: UseSafeMutationOptions) =>
  useSafeMutation(
    [CacheKeys.Context],
    async () => {
      const fp = await FingerprintJS.load()

      const result = await fp.get()

      return postContext({
        murmur: result.visitorId,
      })
    },
    options
  )
