import { getPayment } from '@/apis/api'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { useSafeQuery, UseSafeQueryOptions } from '@/apis/hooks/utils'
import { useMe } from '@/components/AuthenticationManager'
import { Payment } from '@/types'
import { useQueryClient } from 'react-query'

const PAYMENT_QUERY_KEY = 'Payment'
export const getPaymentQueryKey = (id: string | undefined) => [PAYMENT_QUERY_KEY, id]

export const useGetPaymentQuery = (
  id: string | undefined,
  options?: UseSafeQueryOptions<Payment, unknown, Payment, (string | undefined)[]>
) => {
  const client = useQueryClient()
  const me = useMe()

  return useSafeQuery(
    getPaymentQueryKey(id),
    async ({ signal }) => getPayment(id as string, signal),
    {
      enabled: !!id,
      initialData: () =>
        client
          .getQueryData<Payment[]>([CacheKeys.PaymentPoll, me.merchant.id])
          ?.find((payment) => payment.id === id),
      initialDataUpdatedAt: () =>
        client.getQueryState([CacheKeys.PaymentPoll, me.merchant.id])?.dataUpdatedAt,
      refetchOnMount: false,
      ...options,
      type: Payment,
    }
  )
}
