import { AxiosInstance } from 'axios'

export const logUnreachableHostsGenerator =
  (client: AxiosInstance) => async (merchantId: string, unreachableHosts: string[]) => {
    await client.get<void>(
      `v1/pos/log-unreachable-hosts?${new URLSearchParams({
        merchant: merchantId,
        hosts: unreachableHosts.join(','),
      })}`
    )
  }
