import s from '@/components/SelectTerminal/SelectTerminal.module.css'
import { FeePlan } from '@/types'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

const MAX_INSTALLMENTS = 4

export const useTerminalValidation = (feePlan: FeePlan | undefined) =>
  useMemo(() => {
    if (feePlan && (feePlan.deferred_days > 0 || feePlan.deferred_months > 0)) {
      return (
        <div className={s.terminalError}>
          <FormattedMessage
            id="home.submit.ept.invalid.noDeffered"
            defaultMessage="Payment with EPT is unavailable for deferred payment"
            description="When paying from an EPT and a deferred fee plan is selected, message below the (disabled) pay button to explain why this option is unavailable"
          />
        </div>
      )
    }
    if (feePlan && feePlan.installments_count > MAX_INSTALLMENTS) {
      return (
        <div className={s.terminalError}>
          <FormattedMessage
            id="home.submit.ept.invalid.maxInstallments"
            defaultMessage="Payment with EPT is unavailable for more than 4 installments"
            description="When paying from an EPT and more than 4 installments selected, message below the (disabled) pay button to explain why this option is unavailable"
          />
        </div>
      )
    }
    return null
  }, [feePlan])
