import { GatewayPaymentStatus, usePayOnTerminal, useTerminalPayment } from '@/apis/adyenGateway'
import { WithActionButtonsProps } from '@/components/ActionsButtons/ActionsButtons'
import { OnlineSteps } from '@/components/PaymentsModal/OnlineSteps'
import s from '@/components/PaymentsModal/PaymentStatus.module.css'
import { ResendToTerminalForm } from '@/components/PaymentsModal/TerminalSteps/ResendToTerminalForm'
import { PaymentStepDetail } from '@/components/PaymentsModal/TerminalSteps/Steps/PaymentStepDetail'
import {
  titleByUnknown,
  useTerminalStatus,
} from '@/components/PaymentsModal/TerminalSteps/useTerminalStatus'
import { sendErrorToSentry } from '@/thirdParties/sentry'
import { PaymentOnTerminal } from '@/types'
import { usePaymentDetails } from '@/utils/usePaymentDetails'
import { Button, Header, Icon, LoadingIndicator } from '@alma/react-components'
import React, { useEffect, VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

type PaymentTerminalStatusProps = {
  payment: PaymentOnTerminal
  refreshPayment: () => void
  pathname?: string
} & WithActionButtonsProps

if (process.env.NODE_ENV === 'development') {
  type Defined<T> = T extends undefined ? never : T
  // This is documentation to make sure no status escape handling: if a status is added you need to handle it and document here how you did
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const whereAreAllStatusesHandled: Record<
    | Defined<GatewayPaymentStatus['payment']>['status']
    | 'already initialized payment'
    | 'no terminal status',
    string
  > = {
    'already initialized payment':
      'when payment is not cancellable, this mean it has already been processed fully so we display usual status',
    'no terminal status':
      'when payment is cancellable we consider no terminal status an excuse to allow resending to terminal',
    success: 'useEffect: clear websocket and refresh to clear “cancellable” status',
    retry: 'canResend variable: retry button and header',
    failure: 'isError variable: error display and header',
    locked: 'isError variable: error display and header; “force unlock” button',
    ongoing: 'happy path :) display the current step with its description',
  }
}

export const PaymentTerminalStatus: VoidFunctionComponent<PaymentTerminalStatusProps> = ({
  payment,
  refreshPayment,
  pathname,
  ActionButtons,
}) => {
  const { createdDate, customerName } = usePaymentDetails(payment)
  const payOnTerminal = usePayOnTerminal()
  const { terminalStatus, forceUnlockTerminal, terminatePaymentOnTerminal } = useTerminalPayment(
    payment.id
  )
  const {
    isTerminalLoading,
    headerToDisplay,
    errorIconToDisplay,
    errorToDisplay,
    isError,
    canResend,
    currentStepErrorCode,
    currentStep,
    isTechnicalError,
  } = useTerminalStatus(terminalStatus)

  useEffect(() => {
    if (terminalStatus?.payment?.status === 'success') {
      terminatePaymentOnTerminal()
      refreshPayment()
    }
  }, [refreshPayment, terminalStatus?.payment?.status, terminatePaymentOnTerminal])

  useEffect(() => {
    if (isTechnicalError) {
      sendErrorToSentry(
        new Error(`Terminal payment failed with a technical error: ${currentStepErrorCode}`),
        {
          level: 'error',
          extra: { currentStep, currentStepErrorCode },
          fingerprint: [
            'terminal-payment-error',
            typeof currentStepErrorCode === 'string' ? currentStepErrorCode : 'unknown-error',
          ],
        }
      )
    }
  }, [currentStep, currentStepErrorCode, isTechnicalError])

  if (!payment.cancelable) {
    return (
      <OnlineSteps
        payment={payment}
        track={(e) => e}
        pathname={pathname || ''}
        ActionButtons={ActionButtons}
      />
    )
  }

  const getModalContent = () => {
    if (currentStep) {
      return (
        <PaymentStepDetail
          icon={currentStep.status === 'failure' && errorIconToDisplay}
          title={
            currentStep.status === 'failure' ? (
              <>
                {errorToDisplay}
                {currentStepErrorCode && isTechnicalError && (
                  <p className={s.errorCode}>Code: {currentStepErrorCode}</p>
                )}
              </>
            ) : (
              titleByUnknown[currentStep.code] ?? currentStep.title
            )
          }
        />
      )
    }
    if (isTerminalLoading) {
      return <PaymentStepDetail title={<LoadingIndicator />} />
    }
    return (
      <PaymentStepDetail
        icon={<Icon icon="close" color="var(--alma-red)" />}
        title={
          <FormattedMessage
            id="payment-terminal-status.error.title"
            defaultMessage="Payment has failed or ETP connexion has been lost, please try again"
            description="Display instead of step when an unknown error occurred"
          />
        }
      />
    )
  }
  return (
    <div className={s.paymentStatus}>
      <Header level={5} as="h2">
        {isError ? (
          <FormattedMessage
            id="payment-terminal-status.header.error"
            defaultMessage="Error"
            description="Title in the payment popup when the payment has failed at a terminal payment step"
          />
        ) : (
          <>{headerToDisplay}</>
        )}
      </Header>
      <div className={s.origin}>
        <div className={s.customer}>{customerName}</div>
        <div className={s.createdDate}>{createdDate}</div>
      </div>
      {getModalContent()}
      {terminalStatus?.payment?.status === 'locked' && (
        <Button onClick={forceUnlockTerminal}>
          <FormattedMessage
            id="payment-terminal-status.button.force-unload"
            defaultMessage="Force unlock terminal"
            description="label for the button to force unlock the payment terminal from the payment popup. The button will be displayed only if the terminal is locked."
          />
        </Button>
      )}
      {canResend && (
        <ResendToTerminalForm
          onSubmit={({ terminal }) => {
            terminatePaymentOnTerminal()
            payOnTerminal(payment.id, terminal)
          }}
        />
      )}
    </div>
  )
}
