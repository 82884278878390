import { AppErrorBoundary } from '@/components/AppErrorPage/AppErrorBoundary'
import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const TranslatedErrorBoundary: FunctionComponent = ({ children }) => (
  <AppErrorBoundary
    boundary="translated"
    title={
      <FormattedMessage
        id="global-error.title"
        defaultMessage="Error"
        description="Title of the general error page"
      />
    }
    buttonLabel={
      <FormattedMessage
        id="global-error.refresh-button.label"
        defaultMessage="Refresh"
        description="Action button of the general error page. If clicked will refresh the current web page"
      />
    }
  >
    {children}
  </AppErrorBoundary>
)
