import { PostBillingAddress, PostPayment } from '@/apis/api/postPayment'
import { ApiPayment } from '@/types'
import { AxiosInstance } from 'axios'

// This payload is reverse-engineered from the server-side code.
export type PostPaymentUpdate = {
  payment?: {
    installments_count?: number
    // This is the fee plan reference for the merchant ("merchant reference" in FeePlan)
    fee_plan_reference?: string
    customer_fee_variable?: number
    // On the API it is only possible to update 'city' | 'postal_code' | 'line1' | 'country' due to restrict function
    billing_address?: Partial<
      Pick<PostBillingAddress, 'city' | 'postal_code' | 'line1' | 'country'>
    >
    // Locale will be updated if it is present, even if it was set before.
    locale?: PostPayment['payment']['locale']
    custom_data?: Partial<PostPayment['payment']['custom_data']>
    // We could set this to disallow p1x, but it's not a feature in PoS for now.
    allow_p1x?: never
    // the following exist in server-side, but we don't need them in PoS (tey apply to later steps in the payment process)
    shipping_info?: never
  }
  // It is only possible to update customer info that was not set before
  // In customer, "adresses" can only be set if it was not there before (there is no comparison of the array)
  customer?: Partial<PostPayment['customer']>
  // the following exist in server-side, but we don't need them in PoS (tey apply to later steps in the payment process)
  account?: never
  preferred_payment_method?: never
  card?: never
  card_external_id?: never
  bank_account_external_id?: never
  bank_account_data?: never
}

export const postPaymentUpdateGenerator =
  (client: AxiosInstance) => async (paymentId: string, payload: PostPaymentUpdate) => {
    const { data } = await client.post<ApiPayment>(`v1/payments/${paymentId}`, payload)
    return data
  }
