import React, { createContext, FunctionComponent, useCallback, useContext } from 'react'
import { FieldPath } from 'react-hook-form/dist/types/path'

export type DisabledFields = string[] | true

const DisabledFieldsContext = createContext<DisabledFields>([])

export const DisabledFieldsProvider: FunctionComponent<{
  disabledFields: DisabledFields
}> = ({ disabledFields, children }) => (
  <DisabledFieldsContext.Provider value={disabledFields}>{children}</DisabledFieldsContext.Provider>
)

export function useDisableField<TFieldValues>() {
  const disabledFields = useContext(DisabledFieldsContext)

  return {
    disableField: useCallback(
      <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
        fieldName: TFieldName
      ) => disabledFields === true || disabledFields.includes(fieldName),
      [disabledFields]
    ),
  }
}
