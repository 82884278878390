import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import {
  ButtonsAction,
  GreyZone,
  Header,
  ItDoesNotWorkCollapsible,
  List,
  ListItem,
} from '@/components/PaymentsModal/Templates'
import { HorizontalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const BankingDataVerificationDeclaration: VoidFunctionComponent<StepProps> = ({
  updatedDate,
  amountAndPlan,
  customerName,
  createdDate,
  ActionButtons,
}) => {
  const intl = useIntl()
  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />{' '}
      <GreyZone
        progressBarTitle={intl.formatMessage({
          id: 'payment.step.detail.progress.title.verifications',
          defaultMessage: 'Verifications',
          description:
            'Title of the progress bar in the payment detail modal for the verifications steps',
        })}
        progress={40}
      >
        <HorizontalTitle updated={updatedDate}>
          <FormattedMessage
            id="payment.detail.requirementStep.banking_data_verification_declaration"
            defaultMessage="Declarative resources check"
            description="Title of the payment requirement step in which we ask the customer to declare his income"
          />
        </HorizontalTitle>
        <div>
          <div className={s.listWithLabel}>
            <FormattedMessage
              id="payment.detail.ressources.declaration.info"
              defaultMessage="Your customer fills in two fields:"
              description="In the payment's detail modal, this text explains what field should be filled by the customer to declare their ressources, at this step"
            />
          </div>
          <List>
            <ListItem>
              <FormattedMessage
                id="payment.detail.ressources.declaration.info_first-field"
                defaultMessage="Their net monthly income: net salary, property income, allowances, etc."
                description="In the payment's detail modal, this text explains the first field that should be filled by the customer to declare their ressources."
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id="payment.detail.ressources.declaration.info_second-field"
                defaultMessage="Their monthly charges: credits, loans, rent, pension, etc."
                description="In the payment's detail modal, this text explains the second field that should be filled by the customer to declare their ressources."
              />
            </ListItem>
          </List>
        </div>
      </GreyZone>
      <ItDoesNotWorkCollapsible>
        <div>
          <FormattedMessage
            id="payment.detail.ressources.declaration.not.working.info"
            defaultMessage="Your client must select their current account. This is the only account on which we can verify his financial situation."
            description="In the payment's detail modal this explains that the customer should connect their bank account for verification at this step."
          />
        </div>
        <ButtonsAction>
          <ActionButtons.Restart color="link" />
          <ActionButtons.Cancel color="link" />
        </ButtonsAction>
      </ItDoesNotWorkCollapsible>
    </>
  )
}
