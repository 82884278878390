import { postLogin } from '@/apis/api'
import { isRedirectMe } from '@/apis/utils'
import { Logo } from '@/assets/img/Logo'
import { ME_QUERY_KEY } from '@/components/AuthenticationManager/AuthenticationManagerContext'
import { EnvironmentBanner } from '@/components/EnvironmentBanner/EnvironmentBanner'
import { emailPattern, requiredFieldError } from '@/components/Forms/validation'
import s from '@/components/Pages/Login/Login.module.css'
import { config } from '@/config'
import { useTracking } from '@/thirdParties/analytics'
import { Button, Card, Header, TextField } from '@alma/react-components'
import axios from 'axios'
import React, { useMemo, useState, VoidFunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'

type LoginForm = {
  email: string
  password: string
}

export const Login: VoidFunctionComponent = () => {
  const intl = useIntl()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const track = useTracking('log_in')

  const { register, formState, watch, handleSubmit, reset, resetField, setValue } =
    useForm<LoginForm>({
      defaultValues: { email: '', password: '' },
      mode: 'onTouched',
      shouldUseNativeValidation: false,
    })

  const email = watch('email') ?? ''

  const queryClient = useQueryClient()
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const tryRedirect = useMutation(['tryRedirect'], async ({ email }: { email: string }) => {
    try {
      const data = await postLogin({ email, password: 'plop', return_url: window.location.href })

      if (isRedirectMe(data)) {
        window.location.assign(data.redirect)
      }
    } catch {
      // Completely swallow error, we actually don't care at all if this request does not redirect
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const login = useMutation(['login'], async ({ email, password }: LoginForm) => {
    try {
      track('log_in')
      const me = await postLogin({ email, password, return_url: window.location.href })
      if (isRedirectMe(me)) {
        return window.location.assign(me.redirect)
      }

      queryClient.setQueryData(ME_QUERY_KEY, me)
      track('log_in_success')
    } catch (e) {
      track('log_in_error')
      reset(undefined, {
        // We declare all submitted values as "non-dirty" by resetting their “dirty” and “default values” state
        keepDirty: false,
        keepDefaultValues: false,
        keepTouched: false,
        keepValues: true,
        keepIsSubmitted: true,
        keepSubmitCount: true,
        keepIsValid: true,
        keepErrors: true,
      })
      return e
    }
    return undefined
  })

  const required = requiredFieldError(intl)
  const loginPattern = emailPattern(intl)

  const wrongPasswordError = useMemo(() => {
    if (formState.isDirty) {
      return undefined
    }
    if (axios.isAxiosError(login.data) && login.data.response?.status === 401) {
      return intl.formatMessage({
        id: 'login-page.form.wrong-identifiers',
        defaultMessage: 'Password or email incorrect. Please retry.',
        description:
          'Error message that appears in red below the password field when you enter the wrong combination of email and password, on the POS login page.',
      })
    }
    if (login.data instanceof Error) {
      return login.data.message
    }
    return undefined
  }, [formState.isDirty, intl, login.data])

  return (
    <>
      <EnvironmentBanner />
      <form
        className={s.background}
        onSubmit={handleSubmit((values) => login.mutateAsync(values))}
        data-testid="login"
      >
        <Card className={s.loginCard}>
          <Logo className={s.logo} />
          <Header>
            <FormattedMessage
              id="login-page.title"
              defaultMessage="Access to PoS interface"
              description="Title of the login page of POS. It appears below the Alma logo."
            />
          </Header>
          <div className={s.formFields}>
            <TextField
              id="email"
              {...register('email', {
                required,
                pattern: loginPattern,
                setValueAs: (value) => {
                  const sanitizedValue = value.toLowerCase().replace(/\s/g, '')
                  if (value !== sanitizedValue) {
                    setValue('email', sanitizedValue)
                  }
                  return sanitizedValue
                },
              })}
              error={formState.errors.email?.message}
              type="text"
              isDirty={formState.dirtyFields.email}
              onClearClick={() => resetField('email')}
              className={s.loginFormField}
              data-testid="login-email-input"
              label={
                <FormattedMessage
                  id="login-page.form.email-label"
                  defaultMessage="Email address"
                  description="Label of the email field on the login page of POS. The format of the field is checked. Only email addresses are expected. If the email is not valid, or if the field is empty, an error message appears in red below the field."
                />
              }
              placeholder={intl.formatMessage({
                id: 'login-page.form.email-placeholder',
                defaultMessage: 'john.marston@getalma.eu',
                description:
                  'Placeholder within the field « Email address», in login page of POS. Used as an example to help filling in the field. It disappears when the user starts to type in.',
              })}
            />
            {passwordVisible && (
              <TextField
                id="password"
                {...register('password', { required })}
                error={formState.errors.password?.message ?? wrongPasswordError}
                type="password"
                className={s.loginFormField}
                data-testid="login-password-input"
                label={
                  <FormattedMessage
                    id="login-page.form.password-label"
                    defaultMessage="Password"
                    description="Label of the password field on the login page of POS. While the field is empty, the login button below is greyed and inactive, and a warning in read below the field indicates that the field is mandatory."
                  />
                }
                {...{
                  /* eslint-disable-next-line jsx-a11y/no-autofocus -- rule does not apply to a field appearing on user event (in this case click on the “continue” button */
                  autoFocus: true,
                }}
              />
            )}
          </div>
          {passwordVisible ? (
            <>
              <Button type="submit" block disabled={!formState.isValid}>
                <FormattedMessage
                  id="login-form.login.label"
                  defaultMessage="Sign in"
                  description="Label of the login button on the POS login page once you've entered your email login and password, that's what happens when you don't have a Google suite account."
                />
              </Button>
              <a
                className={s.link}
                href={`${config.DASHBOARD_URL}/forgot-password?email=${encodeURIComponent(email)}`}
              >
                <FormattedMessage
                  id="login-page.password-forgotten"
                  defaultMessage="Forgot password?"
                  description="Label of the link below the login button on the POS login page. Clicking on it redirects you the merchant dashboard where you can reset your password."
                />
              </a>
            </>
          ) : (
            <>
              <Button
                disabled={!email.match(loginPattern.value)}
                block
                onClick={() => {
                  setPasswordVisible(true)
                  tryRedirect.mutate({ email })
                }}
              >
                <FormattedMessage
                  id="login-form.continue.label"
                  defaultMessage="Continue"
                  description="Label of the login button on the POS login page. The seller enters her/his email and then either the password field is displayed, either his acocunt is linked to a Google suite account and he/she can use Google connect"
                />
              </Button>
              <a
                className={s.link}
                href={`${config.WEBSITE_URL}/register`}
                onClick={() => track('create_account')}
              >
                <FormattedMessage
                  id="login-page.create-account"
                  defaultMessage="Create an account"
                  description="Label of the link that allows to create an account on the login page of POS."
                />
              </a>
            </>
          )}
        </Card>
      </form>
    </>
  )
}
