import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const NoPaymentError: VoidFunctionComponent = () => (
  <FormattedMessage
    id="paymentDetails.error"
    defaultMessage="Unable to retrieve payment"
    description="This is the error displayed when the popup to track payment progress could not be displayed for technical reasons"
  />
)
