/* eslint-disable react/jsx-props-no-spreading */

import { WithActionButtonsProps } from '@/components/ActionsButtons/ActionsButtons'
import { useMe } from '@/components/AuthenticationManager'
import {
  BankingDataVerificationBI,
  BankingDataVerificationDeclaration,
  Converted,
  Expired,
  IdVideoVerification,
  PaymentValidation,
  PhoneCreditVerification,
  PhonePnxVerification,
  ProofOfAddress,
  Rejected,
  StartPayment,
  StepProps,
  UpdatePhoneNumber,
  ErrorStep,
  UnknownStep,
} from '@/components/PaymentsModal/OnlineSteps/Steps'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { ActiveTags, hasFeatureTag, Payment } from '@/types'
import { usePaymentDetails } from '@/utils/usePaymentDetails'
import { isCredit } from '@/utils/utils'
import React, { VoidFunctionComponent } from 'react'
import { useSearchParams } from 'react-router-dom'

type Props = {
  payment: Payment
  pathname: string
} & TrackerProps &
  WithActionButtonsProps

export const OnlineSteps: VoidFunctionComponent<Props> = ({
  payment,
  track,
  pathname,
  ActionButtons,
}) => {
  const me = useMe()
  const paymentDetails = usePaymentDetails(payment)
  const [searchParams] = useSearchParams()

  const { requirementStep, customer, installments_count: installmentsCount } = payment

  const stepProps: StepProps = {
    ...paymentDetails,
    ActionButtons,
    track,
  }

  const isUserMdm = hasFeatureTag(ActiveTags.mdm, me)
  switch (requirementStep) {
    case 'customer_info':
    case 'valid_payment_method':
    case 'payment_not_configured':
      // Warning probably FIX V2: actually the customer info & payment not configured display the valid payment method
      // CF: https://app.clickup.com/t/20427503/AL-860
      return searchParams.has('update-phone-number') ? (
        <UpdatePhoneNumber {...stepProps} payment={payment} />
      ) : (
        <StartPayment {...stepProps} payment={payment} />
      )
    case 'phone_verification':
      return isCredit({ installments_count: installmentsCount ?? 0 }) ? (
        <PhoneCreditVerification {...stepProps} />
      ) : (
        <PhonePnxVerification {...stepProps} />
      )
    case 'banking_data_verification_BI':
      return <BankingDataVerificationBI {...stepProps} />
    case 'banking_data_verification_declaration':
      return <BankingDataVerificationDeclaration {...stepProps} />
    case 'id_video_verification':
      return <IdVideoVerification {...stepProps} />
    case 'payment_validation':
      return <PaymentValidation {...stepProps} />
    case 'proof_of_address':
      return <ProofOfAddress {...stepProps} />
    case 'rejected':
      return <Rejected {...stepProps} isUserMDM={isUserMdm} />
    case 'expired':
      return <Expired {...stepProps} isUserMDM={isUserMdm} />
    case 'error_step':
      return <ErrorStep {...stepProps} />
    case 'converted':
      return (
        <Converted
          {...stepProps}
          customerEmail={customer.email}
          pathname={pathname}
          isUserMDM={isUserMdm}
          payment={payment}
        />
      )
    default:
      return <UnknownStep id={paymentDetails.id} step="unknown_step" />
  }
}
