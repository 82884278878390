import { getPaymentPoll } from '@/apis/api'
import { GetPaymentPollOptions } from '@/apis/api/getPaymentPoll'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { useSafeQuery, UseSafeQueryOptions } from '@/apis/hooks/utils'
import { useMe } from '@/components/AuthenticationManager'
import { Payment } from '@/types'
import { QueryKey } from 'react-query'

export const getPaymentPollQueryKey = (
  id: string,
  ...options: GetPaymentPollOptions[]
): QueryKey => [
  CacheKeys.PaymentPoll,
  id,
  // Param options are spread to avoid adding undefined
  // in the array when option is not provided
  ...options,
]

export const useGetPaymentPollQuery = (
  options: GetPaymentPollOptions,
  queryOptions?: UseSafeQueryOptions<Array<Payment>>
) => {
  const me = useMe()
  return useSafeQuery(
    getPaymentPollQueryKey(me.merchant.id, options),
    async ({ signal }) => getPaymentPoll(options, signal),
    {
      // Refetch the data every minutes
      refetchInterval: 60000,
      ...queryOptions,
    }
  )
}
