import { usePayOnTerminal } from '@/apis/adyenGateway'
import { sendPaymentBySms } from '@/apis/api'
import { PaymentMethod, useCreatePaymentMutation, useGetPaymentQuery } from '@/apis/hooks'
import { UsePaymentMutation } from '@/apis/hooks/mutations/useCreatePaymentMutation'
import { useUpdatePaymentMutation } from '@/apis/hooks/mutations/useUpdatePaymentMutation'
import { getPaymentQueryKey } from '@/apis/hooks/queries/useGetPaymentQuery'
import { useMe } from '@/components/AuthenticationManager'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { PaymentContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/types'
import { usePaymentCreationContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/usePaymentCreationContext'
import { useLocale } from '@/intl/I18nContext'
import { ClickTracker, eventPropsFromFeePlan } from '@/thirdParties/analytics'
import { LogEventName, useDatadogLogger } from '@/thirdParties/datadog'
import { ActiveTags, hasFeatureTag, Payment, PaymentOrigin } from '@/types'
import { handleError, parseAxiosError } from '@/utils'
import { toastMessage } from '@alma/react-components'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

const usePaymentMethods = (mutation: UsePaymentMutation) => {
  const intl = useIntl()
  const [lastMethodUsed, setLastMethodUsed] = useState<PaymentMethod>()

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const payOnTerminal = usePayOnTerminal()
  const payBySms = useCallback(
    (values: PaymentCreateFormValues) =>
      mutation.mutateAsync(
        {
          values,
          options: {
            return_url: values.link.returnUrl ?? null,
            customer_cancel_url: values.link.customerCancelUrl ?? null,
            origin: PaymentOrigin.posSms,
          },
          trackName: 'sms_link',
        },
        {
          onSuccess: async (payment) => {
            try {
              await sendPaymentBySms(payment.id)
              setLastMethodUsed(
                lastMethodUsed === PaymentMethod.sms ? PaymentMethod.smsSecond : PaymentMethod.sms
              )
              toastMessage({
                type: 'info',
                title: intl.formatMessage(
                  {
                    id: 'toast.sms.send.confirmation',
                    defaultMessage: 'Payment link sent to {phoneNumber}',
                    description:
                      'This is a message displayed when a payment link was successfully sent by text message. It is displayed for 5 seconds as a confirmation.',
                  },
                  { phoneNumber: values.phone.number }
                ),
                duration: 5000,
                closable: true,
              })
            } catch (e) {
              handleError(
                parseAxiosError(e, {
                  userMessage: intl.formatMessage({
                    id: 'toast.sms.send.error',
                    defaultMessage: 'Failed to send payment link by text',
                    description:
                      'This is a message displayed when a payment link was not sent for technical reasons. It is displayed for 5 seconds as a confirmation.',
                  }),
                })
              )
            }
            navigate(`/payment/${payment.id}`, { replace: true })
          },
        }
      ),
    [mutation, navigate, lastMethodUsed, intl]
  )

  const payOnScreen = useCallback(
    (values: PaymentCreateFormValues) =>
      mutation.mutateAsync(
        {
          values,
          options: {
            return_url: values.link.returnUrl ?? window.location.href,

            customer_cancel_url: window.location.href,
            origin: PaymentOrigin.posDevice,
          },
          trackName: 'pay_on_page',
        },
        {
          onSuccess: (payment) => {
            setLastMethodUsed(PaymentMethod.onScreen)
            /* istanbul ignore else: we don't need to cover the case specific to storybook */
            if (process.env.NODE_ENV !== 'storybook') {
              window.location.assign(payment.url)
            } else {
              toastMessage({
                type: 'info',
                title: 'Redirected to payment page',
                message: (
                  <>
                    Storybook can not simulate a redirection. This toast is made to let the user
                    know that the pay on this screen function worked correctly. It should have
                    redirected to <a href={payment.url}>Mocks/Payment-page/default</a>
                  </>
                ),
                duration: Infinity,
                closable: true,
              })
            }
          },
        }
      ),
    [mutation]
  )

  const payByLink = useCallback(
    (values: PaymentCreateFormValues) =>
      mutation.mutateAsync(
        {
          values,
          options: {
            return_url: values.link.returnUrl ?? null,
            customer_cancel_url: values.link.customerCancelUrl ?? null,
            origin: PaymentOrigin.posLink,
          },
          trackName: 'create_link',
        },
        {
          onSuccess: () => {
            setLastMethodUsed(PaymentMethod.link)
            setSearchParams(new URLSearchParams('link'))
          },
        }
      ),
    [mutation, setSearchParams]
  )

  const payWithTerminal = useCallback(
    (values: PaymentCreateFormValues) => {
      const terminal = values.terminal?.selected
      if (!terminal) {
        throw new Error('Tried a payment on terminal with no terminal selected')
      }

      return mutation.mutateAsync(
        {
          values,
          options: {
            return_url: null,
            customer_cancel_url: null,
            origin: PaymentOrigin.posTerminal,
          },
          trackName: 'pay_with_terminal',
        },
        {
          onSuccess: (payment) => {
            setLastMethodUsed(PaymentMethod.terminal)
            payOnTerminal(payment.id, terminal)
            navigate(`/payment/${payment.id}`)
          },
        }
      )
    },
    [mutation, navigate, payOnTerminal]
  )

  const clearPayment = useCallback(() => {
    setLastMethodUsed(undefined)
  }, [])

  return {
    payBySms,
    payOnScreen,
    payByLink,
    payWithTerminal,
    lastMethodUsed,
    clearPayment,
    isLinkAvailable: searchParams.has('link'),
  }
}

export type PaymentMethodsHandler = Pick<
  ReturnType<typeof usePaymentMethods>,
  'payBySms' | 'payOnScreen' | 'payByLink' | 'payWithTerminal' | 'lastMethodUsed'
>

export const useCreatePaymentMethods = (track: ClickTracker) => {
  const { locale } = useLocale()
  const [createdPayment, setCreatedPayment] = useState<Payment>()
  const logger = useDatadogLogger()
  const me = useMe()
  const { setPaymentCreationContext, paymentCreationContext } = usePaymentCreationContext()
  const createPaymentMutation = useCreatePaymentMutation(locale, createdPayment, {
    onMutate: ({ trackName, values }) => {
      track(trackName, eventPropsFromFeePlan(values.purchase.feePlan, values.offerFees?.offered))
    },
    onSuccess: (payment, variables) => {
      if (payment !== createdPayment) {
        setCreatedPayment(payment)
        logger.info({
          message: 'Payment successfully created',
          data: {
            eventName: LogEventName.createPayment,
            paymentId: payment.id,
            merchantId: me.merchant.id,
            canMerchantOfferFees: me.can_disable_customer_fees ?? false,
            feesOffered: variables.values.offerFees?.offered ?? false,
            isMerchantIobsp: hasFeatureTag(ActiveTags.iobsp, me),
            merchantActiveTags: me.merchant.pos_active_tags,
            paymentMethodUsed: variables.options.origin,
            installmentCount: payment.installments_count,
            paymentContext: paymentCreationContext,
            requirements_mode: variables.values.requirements.mode,
          },
        })
      }
      // When payment has been created, reset the Payment Creation context to the "default" value
      // To prepare for next payment
      setPaymentCreationContext(PaymentContext.newPayment)
    },
  })

  const { clearPayment: clearLastMethod, ...methods } = usePaymentMethods(createPaymentMutation)
  const clearPayment = useCallback(() => {
    setCreatedPayment(undefined)
    clearLastMethod()
  }, [clearLastMethod])

  return {
    ...methods,
    createdPayment,
    clearPayment,
  }
}

export const useUpdatePaymentMethods = (paymentId: string | undefined, track: ClickTracker) => {
  const logger = useDatadogLogger()
  const me = useMe()
  const originalPayment = useGetPaymentQuery(paymentId)
  const queryClient = useQueryClient()
  const updatePaymentMutation = useUpdatePaymentMutation(originalPayment.data, {
    onMutate: ({ trackName, values }) => {
      track(trackName, eventPropsFromFeePlan(values.purchase.feePlan, values.offerFees?.offered))
    },
    onSuccess: (payment, variables) => {
      queryClient.setQueryData(getPaymentQueryKey(paymentId), payment)
      logger.info({
        message: 'Payment successfully finalized',
        data: {
          eventName: LogEventName.finalizePayment,
          paymentId: payment.id,
          merchantId: me.merchant.id,
          paymentMerchantId: payment.merchant_id,
          finalizeFromOtherMerchant: me.merchant.id !== payment.merchant_id,
          canMerchantOfferFees: me.can_disable_customer_fees ?? false,
          feesOffered: variables.values.offerFees?.offered ?? false,
          isMerchantIobsp: hasFeatureTag(ActiveTags.iobsp, me),
          merchantActiveTags: me.merchant.pos_active_tags,
          paymentMethodUsed: variables.options.origin,
          installmentCount: payment.installments_count,
          requirements_mode: variables.values.requirements.mode,
        },
      })
    },
  })

  const methods = usePaymentMethods(updatePaymentMutation)

  return {
    ...methods,
    payment: originalPayment.data,
  }
}
