import { useMe } from '@/components/AuthenticationManager'
import { useDisableField } from '@/components/Forms/disabledFields'
import s from '@/components/Forms/PaymentCreate/PurchaseForm/PurchaseForm.module.css'
import { sortFeePlanOptions } from '@/components/Forms/PaymentCreate/PurchaseForm/purchaseFormUtils'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { eventPropsFromFeePlan } from '@/thirdParties/analytics'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { FeePlan } from '@/types'
import { feePlanKey } from '@/utils/utils'
import { ToggleButtonsField } from '@alma/react-components'
import cx from 'classnames'
import React, { useCallback, VoidFunctionComponent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

export const InstallmentsField: VoidFunctionComponent<
  TrackerProps & { feePlans: FeePlan[]; feePlan: FeePlan | undefined }
> = ({ track, feePlans, feePlan }) => {
  const me = useMe()
  const intl = useIntl()
  const { control } = useFormContext<PaymentCreateFormValues>()
  const { disableField } = useDisableField<PaymentCreateFormValues>()

  const feePlanLabel = useCallback(
    (plan: FeePlan) => {
      if (plan.deferred_days) {
        return intl.formatMessage(
          {
            id: `home.purchase.feePlan.deferred.days.label`,
            defaultMessage: 'd+{days}',
            description:
              'This is the label to describe a feeplan for deferred payment, N days after the initial purchase',
          },
          { days: plan.deferred_days }
        )
      }
      if (plan.deferred_months) {
        return intl.formatMessage(
          {
            id: `home.purchase.feePlan.deferred.months.label`,
            defaultMessage: 'm+{months}',
            description:
              'This is the label to describe a feeplan for deferred payment, N months after the initial purchase',
          },
          { months: plan.deferred_months }
        )
      }
      return intl.formatMessage(
        {
          id: `home.purchase.feePlan.countLabel`,
          defaultMessage: '{count}x',
          description: 'This is the label to describe a feeplan for split payment in N times',
        },
        { count: plan.installments_count }
      )
    },
    [intl]
  )

  return me.merchant.customer_choose_installments ? (
    <div className={s.infoMessage}>
      <div>
        <FormattedMessage
          id="home.purchase.feePlan"
          defaultMessage="Installments"
          description="This is the title of the part of the payment form where the details of the installment plan is showed (after filling in the amount & selecting the feeplan options)"
        />
      </div>
      <div>
        <FormattedMessage
          id="feeplans.healthcare.message"
          defaultMessage="Your customer's payment schedule will be available on their payment page."
          description="This is the message displayed in the specific case where the merchant is in the healthcare industry. Because of the regulation, he is not allowed to see the details of the installment plan chose by the end customer. He can only initiate a payment by filling in customer info and purchase amount and the end customer will chose the number of installments on his own."
        />
      </div>
    </div>
  ) : (
    <Controller
      control={control}
      name="purchase.feePlan"
      render={({ field }) => (
        <ToggleButtonsField
          id={field.name}
          value={
            /* Not using field.value:
             * because of a bug in react-hook-form, when we change the default value and the value at the same time,
             * the field.value is not updated and stays `undefined` */
            feePlan
          }
          options={sortFeePlanOptions(feePlans)}
          optionKey={feePlanKey}
          optionLabel={feePlanLabel}
          onChange={(item) => {
            track('installments_choice', eventPropsFromFeePlan(item))
            field.onChange(item)
            field.onBlur()
          }}
          className={cx({
            [s.feePlans]: feePlans.length < 10,
            // We can display at most *8* buttons before they get hidden with the sizes of the fields and the breakpoints.
            [s.scrollable]: feePlans.length > 8 && feePlans.length < 11,
            [s.newLine]: feePlans.length > 10,
          })}
          label={
            <FormattedMessage
              id="home.purchase.feePlan"
              defaultMessage="Installments"
              description="This is the title of the part of the payment form where the details of the installment plan is showed (after filling in the amount & selecting the feeplan options)"
            />
          }
          disabled={disableField('purchase.feePlan')}
        />
      )}
    />
  )
}
