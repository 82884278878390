import { useMe } from '@/components/AuthenticationManager'
import s from '@/components/Menu/Menu.module.css'
import { countriesEligibleToReferral } from '@/components/ReferralProgram/MerchantLanguageCode'
import { config } from '@/config'
import { useLocale } from '@/intl/I18nContext'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { Locale } from '@/types'
import { Icon } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const ReferralProgram: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const me = useMe()
  const { locale } = useLocale()

  const getInternationalizedLink = () => {
    const merchantIdWithoutPrefix = me.merchant.id.split('merchant_')[1]
    let localeCode =
      countriesEligibleToReferral.find(
        (language) => language.value === me.merchant.country.toLowerCase()
      )?.code ?? 'en-GB'

    if (localeCode === 'nl-BE' && locale === Locale.fr) {
      localeCode = 'fr-BE'
    }

    return `${config.WEBSITE_URL}/${localeCode}/sponsorship-program?merchant_id=${merchantIdWithoutPrefix}&source=POS`
  }

  return (
    <div className={s.sectionContent}>
      <div className={s.sectionItem}>
        <a
          href={getInternationalizedLink()}
          target="_blank"
          rel="noreferrer"
          onClick={() => track('referral-origin-pos')}
          className={s.menuLink}
          data-testid="referralLink"
        >
          <div className={s.linkDashboard}>
            <FormattedMessage
              id="menu.referral.link"
              defaultMessage="I sponsor my first referral"
              description="Label of a link that opens a typeform page, for the referral program of the merchant."
            />
            <Icon icon="external-link" width="20px" height="20px" />
          </div>
        </a>
      </div>
    </div>
  )
}
