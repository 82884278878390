import { FullAddress, readMapboxFeature } from '@/apis/geocoding'
import { useClearAddressSearch, useSearchAddressQuery } from '@/apis/hooks'
import { useMe } from '@/components/AuthenticationManager'
import { useDisableField } from '@/components/Forms/disabledFields'
import s from '@/components/Forms/PaymentCreate/CustomerInfoForm/CustomerInfoForm.module.css'
import {
  isDistrictVisible,
  isStateVisible,
} from '@/components/Forms/PaymentCreate/CustomerInfoForm/fieldUtils'
import {
  ADDRESS_NOT_IN_LIST_CASE,
  PaymentCreateFormValues,
} from '@/components/Forms/PaymentCreate/types'
import {
  emailPattern,
  firstNamePattern,
  lastNamePattern,
  maxLengthFieldError,
  nameWithOnlySpaces,
  nameWithOnlySpacesMessage,
  requiredFieldError,
} from '@/components/Forms/validation'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { ActiveTags, hasFeatureTag } from '@/types'
import { MapboxPlaceType } from '@/types/Mapbox'
import { useThrottle } from '@/utils/throttleDebounceHooks'
import { AutocompleteSelectField, Button, TextField } from '@alma/react-components'
import cx from 'classnames'
import React, { useEffect, useMemo, useRef, useState, VoidFunctionComponent } from 'react'
import { Controller, DeepPartial, useFormContext, useFormState } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

const DEFAULT_ADDRESS_MANUAL = {
  address: '',
  zipCode: '',
  district: '',
  country: '',
  city: '',
  state: '',
}
type CustomerInfoProps = TrackerProps

// eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
const __UNSAFE_DEFAULT_CUSTOMER_FORM_VALUES: Pick<
  DeepPartial<PaymentCreateFormValues>,
  'customer'
> = {
  customer: {
    firstName: '',
    name: '',
    email: '',
    addressAutocomplete: undefined,
    addressComplement: '',
    addressManual: DEFAULT_ADDRESS_MANUAL,
  },
}

export const useDefaultCustomerFormValues = (): Pick<
  DeepPartial<PaymentCreateFormValues>,
  'customer'
> => {
  const me = useMe()

  const filledBy = useMemo(() => {
    if (me.use_terminal && me.terminals.length > 0 && hasFeatureTag(ActiveTags.ept, me)) {
      return 'merchant'
    }
    return me.merchant.can_customer_info_be_optional ? 'customer' : 'merchant'
  }, [me])

  return useMemo(
    () => ({
      customer: {
        ...__UNSAFE_DEFAULT_CUSTOMER_FORM_VALUES.customer,
        filledBy,
      },
    }),
    [filledBy]
  )
}

export const CustomerInfoForm: VoidFunctionComponent<CustomerInfoProps> = ({ track }) => {
  const intl = useIntl()

  const { errors, dirtyFields } = useFormState<PaymentCreateFormValues>()
  const { disableField } = useDisableField<PaymentCreateFormValues>()
  const { register, control, watch, setValue, setFocus, resetField } =
    useFormContext<PaymentCreateFormValues>()

  const autocompleteInputRef = useRef<HTMLInputElement | null>(null)
  const [countryFieldValue, addressFieldValue, addressAutocompleteFieldValue] = watch([
    'customer.addressManual.country',
    'customer.addressManual.address',
    'customer.addressAutocomplete',
  ])
  const stateIsVisible = isStateVisible(countryFieldValue)
  const districtIsVisible = isDistrictVisible(countryFieldValue)

  const required = requiredFieldError(intl)
  const maxLength = maxLengthFieldError(intl)

  const [addressSearch, setAddressSearch] = useState('')
  const [userInAutocomplete, setUserInAutocomplete] = useState(false)
  const searchThrottle = useThrottle(addressSearch.trim())

  const clearSearch = useClearAddressSearch()

  const onAutocompleteChange = (selectEvent: {
    target: {
      value: FullAddress | typeof ADDRESS_NOT_IN_LIST_CASE
    }
  }) => {
    if (selectEvent.target.value === ADDRESS_NOT_IN_LIST_CASE) {
      // We need to wait for state to be applied in
      // order for customer.address in the DOM
      requestAnimationFrame(() => setFocus('customer.addressManual.address'))
      track('address_autocomplete_manual')
      setValue('customer.addressManual', {
        ...DEFAULT_ADDRESS_MANUAL,
        address: addressSearch ?? '',
      })
      return undefined
    }

    track('address_autocomplete_use')
    // The value required for the payment creation are set even if the fields are not displayed so the customer address is correctly filled.
    setValue('customer.addressManual', {
      ...DEFAULT_ADDRESS_MANUAL,
      address: autocompleteInputRef.current?.value ?? '',
    })
    return clearSearch()
  }

  const searchResult = useSearchAddressQuery(searchThrottle, {
    onError: async () => {
      setValue('customer.addressAutocomplete', ADDRESS_NOT_IN_LIST_CASE)
      track('address_autocomplete_mapbox_error')
      await onAutocompleteChange({
        target: {
          value: ADDRESS_NOT_IN_LIST_CASE,
        },
      })
    },
  })
  const addressSuggestions = useMemo(
    () =>
      searchResult.data
        ? searchResult.data.features
            .filter((feature) => feature.place_type[0] === MapboxPlaceType.address)
            .map<FullAddress | typeof ADDRESS_NOT_IN_LIST_CASE>(readMapboxFeature)
            .concat(ADDRESS_NOT_IN_LIST_CASE)
        : // We want to display the option to fill address manually even if there is no searchResult.data
          [ADDRESS_NOT_IN_LIST_CASE as typeof ADDRESS_NOT_IN_LIST_CASE],
    [searchResult.data]
  )

  const addressNotInListLabel = intl.formatMessage({
    id: 'address.not.in.list.option',
    defaultMessage: 'Enter the address manually',
    description:
      'This is an option in the autocomplete address suggestion to allow the user to fill the address manually if they can not find it in the provided suggestion list',
  })

  const filledBy = watch('customer.filledBy')

  const customerInfoRequired = filledBy === 'merchant' ? required : undefined

  const reUseAutocomplete = async () => {
    // We need to wait for state to be applied in
    // order for customer.addressAutocomplete in the DOM

    // FIXME : setFocus seems to not working here for some reason (fieldRef.focus is not a function)
    // we manage to make it work by this workaround but we need to find a better solution
    requestAnimationFrame(() => document.getElementById('customer.addressAutocomplete')?.focus())
    track('address_autocomplete_restore')
    setValue('customer.addressAutocomplete', undefined as unknown as FullAddress)
  }

  useEffect(() => {
    // Address search should be set to '' when the form is cleared/reset
    if (!addressAutocompleteFieldValue && !addressFieldValue) {
      setAddressSearch('')
    }
  }, [addressAutocompleteFieldValue, addressFieldValue])

  useEffect(() => {
    if (!customerInfoRequired) {
      resetField('customer.addressAutocomplete')
      resetField('customer.addressManual')
      resetField('customer.addressComplement')
      resetField('customer.firstName')
      resetField('customer.name')
      resetField('customer.email')
    }
  }, [customerInfoRequired, resetField])

  return (
    <div className={s.formContainer} data-testid="customer-info-form">
      <div className={s.fieldGroup}>
        <TextField
          {...register('customer.firstName', {
            required: customerInfoRequired,
            maxLength,
            pattern: firstNamePattern(intl),
            validate: {
              noNameWithOnlySpaces: (nameText) =>
                nameWithOnlySpaces(nameText, nameWithOnlySpacesMessage(intl)),
            },
          })}
          placeholder={intl.formatMessage({
            id: 'customer.info.form.firstname.placeholder',
            defaultMessage: 'John',
            description:
              'Placeholder within the field "First name", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
          })}
          error={errors.customer?.firstName?.message}
          type="text"
          isDirty={dirtyFields.customer?.firstName}
          onClearClick={() => resetField('customer.firstName')}
          id="customer.firstName"
          label={
            <FormattedMessage
              id="customer.info.form.firstName"
              defaultMessage="First name"
              description="Field label in the customer details section of POS page. Mandatory field (if not filled-in, an error message appears in red below the field)."
            />
          }
          disabled={disableField('customer.firstName')}
        />
        <TextField
          {...register('customer.name', {
            required: customerInfoRequired,
            maxLength,
            pattern: lastNamePattern(intl),
            validate: {
              noNameWithOnlySpaces: (nameText) =>
                nameWithOnlySpaces(nameText, nameWithOnlySpacesMessage(intl)),
            },
          })}
          placeholder={intl.formatMessage({
            id: 'customer.info.form.name.placeholder',
            defaultMessage: 'Marston',
            description:
              'Placeholder within the field "Name", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
          })}
          error={errors.customer?.name?.message}
          type="text"
          isDirty={dirtyFields.customer?.name}
          onClearClick={() => resetField('customer.name')}
          id="customer.name"
          label={
            <FormattedMessage
              id="customer.info.form.lastName"
              defaultMessage="Name"
              description="Field label in the customer details section of POS page. Mandatory field (if not filled-in, an error message appears in red below the field)."
            />
          }
          disabled={disableField('customer.name')}
        />
      </div>
      <div className={s.fieldGroup}>
        <TextField
          {...register('customer.email', {
            required: customerInfoRequired,
            maxLength,
            pattern: emailPattern(intl),
          })}
          placeholder={intl.formatMessage({
            id: 'customer.info.form.email.placeholder',
            defaultMessage: 'john.marston@getalma.eu',
            description:
              'Placeholder within the field "Email", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.Placeholder within the field « Email », in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
          })}
          error={errors.customer?.email?.message}
          type="text"
          isDirty={dirtyFields.customer?.email}
          onClearClick={() => resetField('customer.email')}
          id="customer.email"
          label={
            <FormattedMessage
              id="customer.info.form.email"
              defaultMessage="Email"
              description="Field label in the customer details section of POS page. The format of the field is checked. Only email addresses are expected. If the email is not valid, or if the field is not filled-in, an error message appears in red below the field."
            />
          }
          disabled={disableField('customer.email')}
        />
      </div>
      {addressAutocompleteFieldValue !== ADDRESS_NOT_IN_LIST_CASE ? (
        <>
          <Controller
            control={control}
            name="customer.addressAutocomplete"
            rules={{
              onChange: onAutocompleteChange,
              onBlur: () => setUserInAutocomplete(false),
              required: customerInfoRequired,
            }}
            render={({ field, fieldState }) => (
              <AutocompleteSelectField
                {...field}
                isDirty={Boolean(addressSearch)}
                onClearClick={() => setAddressSearch('')}
                onFocus={() => setUserInAutocomplete(true)}
                ref={autocompleteInputRef}
                controlledInputValue={userInAutocomplete || field.value ? addressSearch : ''}
                onSearch={setAddressSearch}
                options={addressSuggestions}
                blurButtonMessage={intl.formatMessage({
                  id: 'mobile.autocomplete.blur.address',
                  defaultMessage: 'Clear',
                  description:
                    'On the address autocomplete field, when user is on a mobile device, there is a button in the field that can be used to clear the field and unfocus',
                })}
                itemLabel={(item) =>
                  item === ADDRESS_NOT_IN_LIST_CASE ? addressNotInListLabel : item.label
                }
                placeholder={
                  addressSearch.trim()
                    ? intl.formatMessage(
                        {
                          id: 'customer.info.form.addressAutocomplete.filled.placeholder',
                          defaultMessage: '{addressSearch} -- select in list',
                          description:
                            'Placeholder within the field "Address autocomplete", in the customer details section of POS page. {addressSearch} is the value the user started to type in the field. Used as an indication to help understanding the search behavior.',
                        },
                        { addressSearch }
                      )
                    : intl.formatMessage({
                        id: 'customer.info.form.addressAutocomplete.placeholder',
                        defaultMessage: 'Type to search address',
                        description:
                          'Placeholder within the field "Address autocomplete", in the customer details section of POS page. Used as an indication to help understanding the search behavior in the field. It disappears when the user starts to type in.',
                      })
                }
                error={fieldState.error?.message}
                type="text"
                id="customer.addressAutocomplete"
                label={
                  <FormattedMessage
                    id="customer.info.form.address"
                    defaultMessage="Address"
                    description="Field label in the customer details section of POS page. Mandatory field (if not filled-in, an error message appears in red below the field)."
                  />
                }
                disabled={disableField('customer.addressAutocomplete')}
                suggestionClassName={s.addressSuggestion}
              />
            )}
          />
          <TextField
            {...register('customer.addressComplement', {
              maxLength,
            })}
            placeholder={intl.formatMessage({
              id: 'customer.info.form.addressComplement.placeholder',
              defaultMessage: 'PO box, floor...',
              description:
                'Placeholder within the field "Additional address", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
            })}
            error={errors.customer?.addressComplement?.message}
            type="text"
            isDirty={dirtyFields.customer?.addressComplement}
            onClearClick={() => resetField('customer.addressComplement')}
            id="customer.addressComplement"
            label={
              <FormattedMessage
                id="customer.info.form.address.complement"
                defaultMessage="Additional address (optional)"
                description="Field label in the customer details section of POS page. Optional field."
              />
            }
            disabled={disableField('customer.addressComplement')}
          />
        </>
      ) : (
        <>
          <TextField
            {...register('customer.addressManual.address', {
              required: customerInfoRequired,
              maxLength,
            })}
            placeholder={intl.formatMessage({
              id: 'customer.info.form.address.placeholder',
              defaultMessage: '2 liberty street',
              description:
                'Placeholder within the field "Address", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
            })}
            error={errors.customer?.addressManual?.address?.message}
            type="text"
            isDirty={dirtyFields.customer?.addressManual?.address}
            onClearClick={() => resetField('customer.addressManual.address')}
            id="customer.addressManual.address"
            label={
              <FormattedMessage
                id="customer.info.form.address"
                defaultMessage="Address"
                description="Field label in the customer details section of POS page. Mandatory field (if not filled-in, an error message appears in red below the field)."
              />
            }
            disabled={disableField('customer.addressManual.address')}
          />
          <TextField
            {...register('customer.addressComplement', {
              maxLength,
            })}
            placeholder={intl.formatMessage({
              id: 'customer.info.form.addressComplement.placeholder',
              defaultMessage: 'PO box, floor...',
              description:
                'Placeholder within the field "Additional address", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
            })}
            error={errors.customer?.addressComplement?.message}
            type="text"
            isDirty={dirtyFields.customer?.addressComplement}
            onClearClick={() => resetField('customer.addressComplement')}
            id="customer.addressComplement"
            label={
              <FormattedMessage
                id="customer.info.form.address.complement"
                defaultMessage="Additional address (optional)"
                description="Field label in the customer details section of POS page. Optional field."
              />
            }
            disabled={disableField('customer.addressComplement')}
          />
          <div
            className={cx(s.fieldGroup, {
              [s.hasNewField]: stateIsVisible || districtIsVisible,
            })}
          >
            <TextField
              {...register('customer.addressManual.zipCode', {
                required: customerInfoRequired,
                maxLength,
              })}
              error={errors.customer?.addressManual?.zipCode?.message}
              type="text"
              isDirty={dirtyFields.customer?.addressManual?.zipCode}
              onClearClick={() => resetField('customer.addressManual.zipCode')}
              id="customer.addressManual.zipCode"
              placeholder={intl.formatMessage({
                id: 'customer.info.form.zipCode.placeholder',
                defaultMessage: '75002',
                description:
                  'Placeholder within the field "Postal code", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
              })}
              label={
                <FormattedMessage
                  id="customer.info.form.zip.code"
                  defaultMessage="Postal code"
                  description="Field label in the customer details section of POS page. Mandatory field (if not filled-in, an error message appears in red below the field)."
                />
              }
              disabled={disableField('customer.addressManual.zipCode')}
            />
            <TextField
              {...register('customer.addressManual.city', {
                required: customerInfoRequired,
                maxLength,
              })}
              error={errors.customer?.addressManual?.city?.message}
              type="text"
              isDirty={dirtyFields.customer?.addressManual?.city}
              onClearClick={() => resetField('customer.addressManual.city')}
              id="customer.addressManual.city"
              placeholder={intl.formatMessage({
                id: 'customer.info.form.city.placeholder',
                defaultMessage: 'Paris',
                description:
                  'Placeholder within the field "City", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
              })}
              label={
                <FormattedMessage
                  id="customer.info.form.city"
                  defaultMessage="City"
                  description="'Field label in the customer details section of POS page. Mandatory field (if not filled-in, an error message appears in red below the field).'"
                />
              }
              disabled={disableField('customer.addressManual.city')}
            />
            {stateIsVisible && (
              <TextField
                {...register('customer.addressManual.state', {
                  maxLength,
                })}
                error={errors.customer?.addressManual?.state?.message}
                type="text"
                isDirty={dirtyFields.customer?.addressManual?.state}
                onClearClick={() => resetField('customer.addressManual.state')}
                id="customer.addressManual.state"
                label={
                  <FormattedMessage
                    id="customer.info.form.state"
                    defaultMessage="State / Province"
                    description="Field label in the customer details section of POS page. Mandatory field that only appears for specific countries like Spain and Ireland that require additional info. (if not filled-in, an error message appears in red below the field)."
                  />
                }
                disabled={disableField('customer.addressManual.state')}
              />
            )}
            {districtIsVisible && (
              <TextField
                {...register('customer.addressManual.district', {
                  maxLength,
                })}
                error={errors.customer?.addressManual?.district?.message}
                type="text"
                isDirty={dirtyFields.customer?.addressManual?.district}
                onClearClick={() => resetField('customer.addressManual.district')}
                id="customer.addressManual.district"
                label={
                  <FormattedMessage
                    id="customer.info.form.district"
                    defaultMessage="District/Townland"
                    description="Field label in the customer details section of POS page. Mandatory field that only appears for specific countries like Spain and Ireland that require additional info. (if not filled-in, an error message appears in red below the field)."
                  />
                }
                disabled={disableField('customer.addressManual.district')}
              />
            )}
            <TextField
              {...register('customer.addressManual.country', {
                required: customerInfoRequired,
                maxLength,
              })}
              error={errors.customer?.addressManual?.country?.message}
              type="text"
              isDirty={dirtyFields.customer?.addressManual?.country}
              onClearClick={() => resetField('customer.addressManual.country')}
              id="customer.addressManual.country"
              placeholder={intl.formatMessage({
                id: 'customer.info.form.country.placeholder',
                defaultMessage: 'France',
                description:
                  'Placeholder within the field "Country", in the customer details section of POS page. Used as an example to help filling in the field. It disappears when the user starts to type in.',
              })}
              label={
                <FormattedMessage
                  id="customer.info.form.country"
                  defaultMessage="Country"
                  description="'Field label in the customer details section of POS page. Mandatory field (if not filled-in, an error message appears in red below the field).'"
                />
              }
              disabled={disableField('customer.addressManual.country')}
            />
          </div>
          <Button
            type="button"
            color="link"
            onClick={reUseAutocomplete}
            disabled={disableField('customer.addressAutocomplete')}
          >
            <FormattedMessage
              id="display.autocomplete.again"
              defaultMessage="See address list again"
              description="If the user don't find the correct address while looking at the suggestions, they can fill the address manually. This button is made for the user to reactivate autocomplete and see again the suggestion list"
            />
          </Button>
        </>
      )}
    </div>
  )
}
