import { useLoggedIn } from '@/components/AuthenticationManager'
import { config } from '@/config'
import {
  EventProps,
  isPianoAnalytics,
  pianoAnalytics,
} from '@/thirdParties/analytics/pianoAnalytics'
import { escapeForPianoAnalytics, getAvailablePaymentMethods } from '@/thirdParties/analytics/utils'
import { handleError } from '@/utils'
import { useCallback, useEffect } from 'react'

/**
 * Call this method to track a click
 *
 * @param eventName Name of the clicked button
 * @param eventProps Additional properties to track. Those must be declared in ATInternet DataModel
 */
export type ClickTracker = (eventName: string, eventProps?: EventProps) => void

/**
 * Convenience props to pass a click tracker to a component
 */
export type TrackerProps = { track: ClickTracker }

/**
 * This hook is used to track page views and navigation.
 *
 * Insert it in any page component that needs to be tracked.
 *
 * @param pageName the name of the page that'll appear on AT Internet tracking
 * @param canTrack set to false to prevent tracking on page load (for exemple when the modal is closed)
 * @returns a function trackEvent to track any event you need to track
 */
export const useTracking = (pageName: string, canTrack = true): ClickTracker => {
  const loggedIn = useLoggedIn()

  const pa = pianoAnalytics()

  useEffect(() => {
    try {
      if (!isPianoAnalytics(pa)) {
        return
      }
      pa.setConfigurations({
        site: config.PIANO_ANALYTICS_SITE_ID,
        collectDomain: 'https://logs1412.xiti.com',
      })
      // We need to explicitly specify the properties we want to send
      // With our events for the privacy exempt mode
      pa.privacy.include.properties(
        [
          'merchant',
          'merchant_country',
          'available_payments',
          'fees_offering_option',
          'chat_option',
          'merchant_role',
          'fees_offered',
          'installments',
          'payment_type',
        ],
        ['exempt'],
        ['page.display', 'click.action']
      )
      pa.privacy.setMode('exempt')
      if (loggedIn.loggedIn) {
        pa.setProperties(
          {
            merchant: escapeForPianoAnalytics(loggedIn.me.merchant.id),
            merchant_country: escapeForPianoAnalytics(loggedIn.me.merchant.country),
            available_payments: getAvailablePaymentMethods(loggedIn.me),
            fees_offering_option: loggedIn.me.can_disable_customer_fees,
            chat_option: Boolean(loggedIn.me.merchant.pos_zendesk_chat_widget_key),
            merchant_role:
              typeof loggedIn.me.role_for_merchant === 'string'
                ? escapeForPianoAnalytics(loggedIn.me.role_for_merchant)
                : null,
          },
          { persistent: true }
        )
      }
    } catch (e) {
      handleError(e)
      // Ignore error: we just prevent the app from crashing in case of an analytics problem
      // Example problem: `delete window.pa` initialization `send` uses the hardcoded global
    }
  }, [loggedIn, pa])

  useEffect(() => {
    try {
      if (canTrack && isPianoAnalytics(pa)) {
        pa.sendEvent('page.display', { page: pageName })
      }
    } catch (e) {
      handleError(e)
      // Ignore error: we just prevent the app from crashing in case of an analytics problem
      // Example problem: `delete window.pa` initialization `send` uses the hardcoded global
    }
  }, [pa, canTrack, pageName])

  return useCallback(
    (eventName, eventProps?: EventProps) => {
      try {
        if (!isPianoAnalytics(pa)) {
          return
        }

        if (eventProps) {
          pa.setProperties(eventProps, { persistent: false })
        }

        pa.sendEvent('click.action', {
          page: pageName,
          click: eventName,
        })
      } catch (e) {
        handleError(e)
        // Ignore error: we just prevent the app from crashing in case of an analytics problem
        // Example problem: `delete window.ATInternet` after Tag initialization `send` uses the hardcoded global)
      }
    },
    [pa, pageName]
  )
}
