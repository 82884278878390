/**
 * Normalizes a string for locale independent string comparison
 * This does standard unicode nortmalization, removes all diactrical signs, and lowercases the result
 */
export const normalize = (input: string) =>
  input.normalize
    ? input
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : input.toLowerCase()

export const camelToSnakeCase = (string: string) =>
  string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
