import React, { VoidFunctionComponent } from 'react'
import s from '@/components/FinalizeHeader/FinalizeHeader.module.css'
import cx from 'classnames'
import { useMe } from '@/components/AuthenticationManager'
import { useParams } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { useGetPaymentQuery } from '@/apis/hooks'
import { DefaultHeader } from '@/components/PageHeader'

export const FinalizeHeader: VoidFunctionComponent = () => {
  const me = useMe()

  const realUser = me.real_user

  const { paymentId } = useParams<'paymentId'>()
  const { data: payment } = useGetPaymentQuery(paymentId)

  const finalizeFromSameMerchant = me.merchant.id === payment?.merchant_id

  return (
    <div className={cx(s.finalizeHeadersContainer, { [s.headerRealUser]: realUser })}>
      {payment && !finalizeFromSameMerchant && (
        <div className={s.finalizeHeader}>
          <div className={s.finalizeLeftContour} />
          <div className={s.finalizeRightContour} />
          <div className={s.container}>
            {(payment?.custom_data?.origin || payment?.merchant_display_name) && (
              <div className={s.merchantName}>
                {payment?.custom_data?.origin && typeof payment?.custom_data?.origin === 'string'
                  ? payment?.custom_data?.origin
                  : payment?.merchant_display_name}
              </div>
            )}
            <div className={s.orderReferenceTitle}>
              <FormattedMessage
                id="finalize.header.order.reference.title"
                defaultMessage="Order reference:"
                description="Title of the order reference in the finalize header"
              />
              <div className={s.orderReference}>{payment?.orders[0].merchant_reference}</div>
            </div>
          </div>
        </div>
      )}
      <DefaultHeader hideCloseButton={!finalizeFromSameMerchant}>
        <FormattedMessage
          id="send.header.title"
          defaultMessage="Finalize payment"
          description="Title of the page that opens when PoS is opened with an existing payment to send. THis page will display the Home form but with filled-in fields greyed out."
        />
        <br />
        <small>{me.merchant.name}</small>
      </DefaultHeader>
    </div>
  )
}
