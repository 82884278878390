import { useQueryClient } from 'react-query'
import { useSafeMutation, UseSafeMutationOptions } from '@/apis/hooks/utils'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { postLogout } from '@/apis/api'

export const useLogoutMutation = (options: UseSafeMutationOptions) => {
  const queryClient = useQueryClient()
  return useSafeMutation(
    [CacheKeys.Logout],
    async () => {
      const result = await postLogout()
      queryClient.setQueryData(CacheKeys.Me, undefined)
      return result
    },
    options
  )
}
