import s from '@/components/Installments/InstallmentsTotal/InstallmentsTotal.module.css'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

type Props = {
  interestInEuros: number
  annualInterestRateInPercent: number
}

export const InstallmentsTotalInterest: VoidFunctionComponent<Props> = ({
  interestInEuros,
  annualInterestRateInPercent,
}) => (
  <div className={s.fees}>
    <div>
      <FormattedMessage
        id="installments.total.credit-interest-title"
        defaultMessage="Cost of credit"
        description="This is the line displayed in the installment plan summary in the payment form when the selected plan is a credit plan, where the seller can see the total interest the customer will have to pay (IOBSP certified merchants only)."
      />
    </div>
    <div>
      <FormattedMessage
        id="installments.total.credit-interest-amount"
        defaultMessage="{amount} (APRC {aprc})"
        description="Amount of interest the client will have to pay, as an amount in euros ({amount}) and as an APRC ({aprc} - annual percentage rate of charge, e.g. TAEG in French). Both numbers are already formatted correctly with their currency or percent sign."
        values={{
          amount: <FormattedNumber value={interestInEuros} style="currency" currency="EUR" />,
          aprc: (
            <FormattedNumber
              value={annualInterestRateInPercent / 100}
              style="percent"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          ),
        }}
      />
    </div>
  </div>
)
