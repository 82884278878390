import { AxiosInstance } from 'axios'
import { BillingAddress } from '@/types/Payment'
import { Eligibility } from '@/types/Eligibility'

export type EligibilityQueries = {
  deferred_days?: number
  deferred_months?: number
  installments_count: number
  deferred_trigger?: boolean
}
export interface EligibilityRequest {
  purchase_amount: number
  queries?: EligibilityQueries[]
  origin?: string
  billing_address?: BillingAddress
  shipping_address?: BillingAddress
}

export const eligibilityCheckGenerator =
  (client: AxiosInstance) => async (eligibilityData: EligibilityRequest, signal?: AbortSignal) => {
    const { data } = await client.post<Eligibility[]>('v2/payments/eligibility', eligibilityData, {
      signal,
    })
    return data
  }
