import s from '@/components/PaymentCard/PaymentCard.module.css'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { Payment } from '@/types'
import { usePaymentDetails } from '@/utils/usePaymentDetails'
import { Badge, Button, Card } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = TrackerProps & {
  payment: Payment
  className?: string
}

export const PaymentCard: VoidFunctionComponent<Props> = ({ payment, className, track }) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const intl = useIntl()

  const { paymentStatus, amountAndPlan, createdDate, customerName } = usePaymentDetails(payment)

  return (
    <Card className={className} padding="lg">
      <div className={s.content} data-testid="payment-card">
        <div className={s.infoBlock}>
          <div className={s.name}>{customerName}</div>
          <div className={s.date}>{createdDate}</div>
          <div className={s.payment}>{amountAndPlan}</div>
          <Button
            data-testid={`payment-detail-button-${payment.id}`}
            color="link"
            role="link"
            onClick={() => {
              track('payment_detail_open')
              navigate(`/payments/${payment.id}${search}`)
            }}
          >
            <FormattedMessage
              id="payment.detail.statusLink.success"
              defaultMessage="View payment"
              description="This is the link in the past payment list to the detailed payment popup when payment are successful. That's where you can find the info of the current step at which the end customer currently is"
            />
          </Button>
        </div>
        <div className={s.badges}>
          {payment.origin === 'pos_terminal' && (
            <Badge
              color="grey"
              label={intl.formatMessage({
                id: 'payment-card.badge.ept',
                defaultMessage: 'EPT',
                description:
                  'Badge label for payments created using payments terminal in the payments list',
              })}
            />
          )}
          <Badge {...paymentStatus} />
        </div>
      </div>
    </Card>
  )
}
