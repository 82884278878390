export const countriesWithStateList: string[] = [
  'spain',
  'espana',
  'espagne',
  'spanien',
  'spagna',
  'spanje',
]

export const countriesWithDistrictList: string[] = [
  'ireland',
  'irland',
  'irlanda',
  'irlande',
  'ierland',
]

export const isStateVisible = (value: string | undefined) => {
  if (!value) return false
  return countriesWithStateList.includes(
    value
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase()
  )
}

export const isDistrictVisible = (value: string | undefined) => {
  if (!value) return false
  return countriesWithDistrictList.includes(
    value
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase()
  )
}
