import { postPayment } from '@/apis/api'
import { createPaymentPayload, PaymentOriginOptions } from '@/apis/createPaymentPayload'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { useSafeMutation, UseSafeMutationOptions } from '@/apis/hooks/utils'
import { hydratePayment } from '@/apis/utils'
import { useMe } from '@/components/AuthenticationManager'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { Locale, Payment } from '@/types'
import { useIntl } from 'react-intl'

export enum PaymentMethod {
  sms = 'sms',
  smsSecond = 'linkSecond',
  onScreen = 'onScreen',
  link = 'link',
  terminal = 'terminal',
}

export interface UseCreatePaymentMutationParameters {
  values: PaymentCreateFormValues
  options: PaymentOriginOptions
  /** Name of the flow for tracking purpose */
  trackName: string
}

export const useCreatePaymentMutation = (
  locale: Locale,
  createdPayment?: Payment,
  options?: UseSafeMutationOptions<Payment, unknown, UseCreatePaymentMutationParameters>
) => {
  const me = useMe()
  const intl = useIntl()

  return useSafeMutation(
    [CacheKeys.PaymentCreate, locale, createdPayment],
    async ({ values, options: paymentOriginOptions }: UseCreatePaymentMutationParameters) => {
      let payment = createdPayment

      if (!payment) {
        const apiPayment = await postPayment(
          createPaymentPayload(
            {
              ...paymentOriginOptions,
              ...values,
              locale,
              customerChooseInstallments: me.merchant.customer_choose_installments,
              merchantCanOfferFees: me.can_disable_customer_fees,
            },
            me
          )
        )
        payment = hydratePayment(apiPayment)
      }

      return payment
    },
    {
      errorParser: () => ({
        userMessage: intl.formatMessage({
          id: 'toast.create.payment.error',
          defaultMessage: 'Failed to create payment',
          description: `This is a message displayed when the seller tried to create a payment in the payment form and it didn't work for technical reasons. It is displayed for 5 seconds as a confirmation.`,
        }),
      }),
      ...options,
    }
  )
}

export type UsePaymentMutation = ReturnType<typeof useCreatePaymentMutation>
