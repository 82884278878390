import { useSafeMutation, UseSafeMutationOptions } from '@/apis/hooks/utils'
import { postSelectedMerchant } from '@/apis/api'
import { CacheKeys } from '@/apis/hooks/CacheKeys'

interface UseSelectMerchantMutationParameters {
  merchantId: string
}

export const useSelectMerchantMutation = (
  options?: UseSafeMutationOptions<void, unknown, UseSelectMerchantMutationParameters>
) =>
  useSafeMutation(
    [CacheKeys.MerchantSelect],
    async ({ merchantId }: UseSelectMerchantMutationParameters) =>
      postSelectedMerchant({ merchant_external_id: merchantId }),
    options
  )
