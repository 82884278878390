import React from 'react'
import { ZendeskChatButton } from '@/components/ZendeskChatButton'
import { config } from '@/config'
import s from '@/components/HelpCenter/HelpCenter.module.css'
import { Button, Icon, IconName } from '@alma/react-components'
import { openStonlyGuidedTour } from '@/thirdParties/stonly/stonly'
import { FormattedMessage } from 'react-intl'
import { useTracking } from '@/thirdParties/analytics'
import { useLocale } from '@/intl/I18nContext'
import { HelpCenterLink } from '@/components/HelpCenter/HelpCenterLink'

export const HelpCenter: React.FC<{
  handleZendeskClick: () => void
  onCloseHelpCenter: () => void
  canDisplayLinkToTutorial: boolean
  noDisplayStonly?: boolean
}> = ({ handleZendeskClick, canDisplayLinkToTutorial, onCloseHelpCenter, noDisplayStonly }) => {
  const language = useLocale().locale
  const isLocaleFr = language === 'fr'

  const track = useTracking('help_center')

  const youtubeLink = () => {
    if (isLocaleFr) {
      return 'https://www.youtube.com/watch?v=rxeuQzqhFx0&list=PLaDfvr2DVV_xEEhRwBf7i-93Bte-KckMl&index=2'
    }
    if (language === 'it') {
      return 'https://www.youtube.com/watch?v=6xhPSkkzDPA'
    }
    return 'https://www.youtube.com/watch?v=hurtBMIBsoM&list=PLaDfvr2DVV_x13gWl3-Wc0Evlu07Kmt2Y&index=2'
  }

  return (
    <div className={s.helpCenterModal}>
      <div className={s.helpCenterHeader}>
        <div className={s.helpCenterTitle}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Icon icon="lifebelt" />
            <FormattedMessage
              id="help.center.header.title"
              defaultMessage="Help center"
              description="This is the title of the help center modal header."
            />
          </div>
          <Button
            data-testid="help-center-close-icon"
            type="button"
            className={s.closeButton}
            onClick={() => {
              track('close_help_center')
              onCloseHelpCenter()
            }}
          >
            <Icon icon="close" width="16px" height="16px" strokeWidth="20" />
          </Button>
        </div>
        <div className={s.helpCenterSubtitle}>
          <FormattedMessage
            id="help.center.header.subtitle"
            defaultMessage="Find useful information to help you use Alma POS"
            description="This is the subtitle of the help center modal header."
            values={{
              newLine: <br />,
            }}
          />
        </div>
      </div>
      <div className={s.helpCenterButtons}>
        <HelpCenterLink
          icon={IconName.book}
          onClick={() => track('faq_open')}
          iconColor="orange"
          href={`https://help.almapay.com/hc/${language}/categories/360001387300-Marchand`}
          buttonTitle={
            <FormattedMessage
              id="help.center.link.to.faq"
              defaultMessage="FAQ"
              description="This is a link that opens our faq page."
            />
          }
        />
        {canDisplayLinkToTutorial && isLocaleFr && !noDisplayStonly && (
          // Stonly is only in french so we want to display the link only if the language is FR
          <HelpCenterLink
            icon={IconName.tv}
            onClick={() => {
              openStonlyGuidedTour('WdKOqkRIV0')
              track('stonly_open')
              onCloseHelpCenter()
            }}
            iconColor="blue"
            buttonTitle={
              <FormattedMessage
                id="help.center.link.to.tutorial"
                defaultMessage="Product tour"
                description="IMPORTANT: The tutorial only exists in French right now, please translate with '(FR)' at the end as an indicator. Label of a link that starts the Stonly tutorial. Only visible if Stonly has loaded."
              />
            }
          />
        )}
        <HelpCenterLink
          icon={IconName['video-camera']}
          onClick={() => track('youtube_video_open')}
          iconColor="yellow"
          href={youtubeLink()}
          buttonTitle={
            <FormattedMessage
              id="help.center.link.to.youtube.videos"
              defaultMessage="Payment experience"
              description="This is a link that opens our youtube videos about POS."
            />
          }
        />
        <HelpCenterLink
          icon={IconName['rectangle-stack']}
          onClick={() => track('training_materials_open')}
          iconColor="red"
          href={`${config.DASHBOARD_URL}/how-to-use-alma`}
          buttonTitle={
            <FormattedMessage
              id="help.center.link.to.training.material"
              defaultMessage="Knowledge base"
              description="This is a link that opens our training material page in the dashboard."
            />
          }
        />
        <ZendeskChatButton handleZendeskClick={handleZendeskClick} />
      </div>
    </div>
  )
}
