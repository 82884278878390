import React, { VoidFunctionComponent } from 'react'
import s from '@/components/Pages/PaymentSimulatedPage/PaymentSimulatedPage.module.css'
import { SimulatedPayment } from '@/types'
import { Button } from '@alma/react-components'
import {
  useAmountAndPlanForConfiguredPayment,
  useCreatedDate,
  useCustomerName,
} from '@/utils/usePartialPaymentDetails'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router'

type Props = {
  simulatedPayment: SimulatedPayment
}

export const SimulatedPaymentItem: VoidFunctionComponent<Props> = ({ simulatedPayment }) => {
  const timestampDate = Date.parse(simulatedPayment.created) / 1000

  const createdDate = useCreatedDate(timestampDate)

  const customerName = useCustomerName(
    simulatedPayment.address?.first_name,
    simulatedPayment.address?.last_name
  )

  const amountAndPlan = useAmountAndPlanForConfiguredPayment(
    simulatedPayment.fee_plan.installments_count,
    simulatedPayment.purchase_amount,
    simulatedPayment.fee_plan.deferred_days,
    simulatedPayment.fee_plan.deferred_months
  )

  const navigate = useNavigate()

  return (
    <div className={s.paymentLine}>
      <div className={s.customerName}>{customerName}</div>
      <div> | </div>
      <div>{createdDate}</div>
      <div> | </div>
      <div> {amountAndPlan} </div>
      <div>
        <Button
          color="link"
          onClick={() => navigate('/', { state: { simulatedPayment } })}
          data-testid="use-simulation-info-button"
        >
          <FormattedMessage
            id="use.informations.button"
            defaultMessage="Use the informations"
            description="This is a button that allows us to use the customer informations of the simulated payment to create a payment"
          />
        </Button>
      </div>
    </div>
  )
}
