/* istanbul ignore file */
import s from '@/components/EnvironmentBanner/EnvironmentBanner.module.css'
import { config } from '@/config'
import React, { VoidFunctionComponent } from 'react'

export const EnvironmentBanner: VoidFunctionComponent = () => {
  const envsToDisplayBanner = ['sandbox', 'staging', 'local_dev', 'dev']

  if (!envsToDisplayBanner.includes(config.ENV)) return null

  return (
    <div className={s.background} data-testid="environment-banner">
      <div className={s.env}>{config.ENV.toUpperCase()}</div>
    </div>
  )
}
