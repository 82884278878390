import { useMe } from '@/components/AuthenticationManager'
import { Payment, isAtLeastRole } from '@/types'
import { Banner } from '@alma/react-components'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const usePaymentNotRelatedToMerchant = (payment?: Payment) => {
  const me = useMe()
  const paymentMerchantAuthorized = me.merchants.find(
    ({ value, user_role }) =>
      value === payment?.merchant_id &&
      isAtLeastRole({ role_for_merchant: user_role }, 'salesperson_finalize_only')
  )
  return (
    !paymentMerchantAuthorized &&
    me.merchant.id !== payment?.merchant_id && (
      <Banner type="error">
        <FormattedMessage
          id="payment.error.wrong.merchant.id"
          defaultMessage="This payment does not exist for {merchant}."
          values={{
            merchant: me.merchant.name,
          }}
          description="Error message shown when a user tries to finalize or visualize a payment that was created by another merchant. Variables : merchant : name of the current user merchant."
        />{' '}
      </Banner>
    )
  )
}
