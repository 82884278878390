import { SelectTerminalDropdown } from '@/components/SelectTerminal/SelectTerminalDropdown'
import { Terminal } from '@/types'
import { Button } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import s from '@/components/PaymentsModal/TerminalSteps/ResendToTerminalForm.module.css'
import { useDefaultTerminalFormValues } from '@/components/SelectTerminal/SelectTerminal'

type RetrySendToTerminalFormValues = {
  terminal: Terminal
}

type Props = {
  onSubmit: (values: RetrySendToTerminalFormValues) => void
}

export const ResendToTerminalForm: VoidFunctionComponent<Props> = ({ onSubmit }) => {
  const defaultTerminalFormValue = useDefaultTerminalFormValues()

  const form = useForm<RetrySendToTerminalFormValues>({
    defaultValues: { terminal: defaultTerminalFormValue?.terminal?.selected },
  })
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={s.formElement}>
        <SelectTerminalDropdown name="terminal" />

        <Button block type="submit" color="secondary" disabled={!form.formState.isValid}>
          <FormattedMessage
            id="payment-terminal-status.resend.label"
            defaultMessage="Resend to terminal"
            description="When payment on terminal failed but can be retried, this button will be displayed"
          />
        </Button>
      </form>
    </FormProvider>
  )
}
