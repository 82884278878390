import { getFeePlans } from '@/apis/api'
import { useMe } from '@/components/AuthenticationManager'
import { useSafeQuery } from '@/apis/hooks/utils'
import { CacheKeys } from '@/apis/hooks/CacheKeys'

export const useFeePlansQuery = (kind: string, only: string, deferred: boolean) => {
  const me = useMe()
  return useSafeQuery(
    [CacheKeys.FeePlans, me.merchant.id],
    async ({ signal }) => getFeePlans(kind, only, deferred, signal),
    { refetchOnMount: false, refetchOnWindowFocus: false }
  )
}
