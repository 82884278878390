import s from '@/components/Barcode/Barcode.module.css'
import JsBarcode from 'jsbarcode'
import React, { useEffect, useState, VoidFunctionComponent } from 'react'

type Props = {
  shortPaymentId: string
}
export const Barcode: VoidFunctionComponent<Props> = ({ shortPaymentId }) => {
  const [barcodeTarget, setBarcodeTarget] = useState<SVGElement | null>(null)

  useEffect(() => {
    if (barcodeTarget === null) {
      return
    }

    JsBarcode(barcodeTarget, shortPaymentId, {
      format: 'CODE128',
      width: 1,
      font: 'Venn',
      fontSize: 16,
      displayValue: false,
    })
  }, [barcodeTarget, shortPaymentId])

  return <svg className={s.barcode} data-testid="barcode" ref={setBarcodeTarget} />
}
