import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { config } from '@/config'

if (!['local_dev', 'test'].includes(config.ENV)) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.ENV,
    release: import.meta.env.VITE_BUILD_VERSION,
    integrations: [new BrowserTracing()],
    normalizeDepth: 10,
    ignoreErrors: [
      // ResizeObserver loop completed with undelivered notifications
      // ResizeObserver loop limit exceeded
      /ResizeObserver loop/,
      /static\.zdassets\.com/,
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Dependencies
      /\.stonly\.js/,
      /stonly\.com/,
      /stonly-widget/,
      /static\.zdassets\.com/,
      /ekr\/snippet/,
      /delighted\.com/,
      /d2yyd1h5u9mauk\.cloudfront\.net/, // Delighted
    ],
  })
}
