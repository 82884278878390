import { typedEntries, typedFromEntries } from '@/utils/objects'

export type HostsToCheck<HostNames extends string> = Record<HostNames, () => Promise<boolean>>
export type CheckedHosts<HostNames extends string> = Record<HostNames, boolean>

export const checkAccess = async <HostNames extends string>(
  hostsToCheck: HostsToCheck<HostNames>
): Promise<CheckedHosts<HostNames>> =>
  typedFromEntries(
    await Promise.all(
      typedEntries(hostsToCheck).map(([key, predicate]) =>
        predicate().then((checked) => [key, checked] as const)
      )
    )
  )

export const filterReachable = <HostNames extends string>(
  checkedHosts: CheckedHosts<HostNames>,
  reachable: boolean
): HostNames[] =>
  typedEntries(checkedHosts)
    .filter(([, checked]) => checked === reachable)
    .map(([hostname]) => hostname)

export const checkImageAccess = (imageSrc: string) => () =>
  new Promise<boolean>((resolve) => {
    const onSuccess = () => resolve(true)
    const onFailure = () => resolve(false)

    const image = new Image()
    image.addEventListener('load', onSuccess)
    image.addEventListener('error', onFailure)

    const timeout = setTimeout(() => {
      image.removeEventListener('load', onSuccess)
      image.removeEventListener('error', onFailure)
      image.src = ''
      onFailure()
    }, 10_000)

    const clear = () => clearTimeout(timeout)
    image.addEventListener('load', clear)
    image.addEventListener('error', clear)

    image.src = imageSrc
  })

export const checkPredicate = (predicate: () => boolean) => () =>
  new Promise<boolean>((resolve) => {
    const check = (nbChecks: number) => {
      if (predicate()) {
        resolve(true)
      } else if (nbChecks <= 0) {
        resolve(false)
      } else {
        setTimeout(() => check(nbChecks - 1), 1_000)
      }
    }

    check(10)
  })
