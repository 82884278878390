import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { SimulatedPayment } from '@/types'
import { centsToEuros, onlyDefined } from '@/utils'

import { DeepPartial } from 'react-hook-form'

export function fillFormWithSimulatedPayment(
  simulatedPayment: SimulatedPayment
): DeepPartial<PaymentCreateFormValues> {
  const addressManual = onlyDefined({
    address: simulatedPayment.address.line1,
    city: simulatedPayment.address.city,
    country: simulatedPayment.address.country,
    zipCode: simulatedPayment.address.postal_code,
    countySublocality: simulatedPayment.address.county_sublocality,
    stateProvince: simulatedPayment.address.state_province,
  })

  const hasAddress = Object.keys(addressManual).length > 0

  // Phone is mandatory on the API side, but we need to check if the rest of the address is empty
  // in order to open or close the customer info form.
  const addressWithNoPhone = Object.fromEntries(
    Object.keys(simulatedPayment.address)
      .filter((addressItem) => addressItem !== 'phone')
      .map((addressItem) => [
        addressItem,
        simulatedPayment.address[addressItem as keyof SimulatedPayment['address']],
      ])
  )

  const allAddressKeysUndefined: boolean = Object.keys(addressWithNoPhone).every(
    (addressItem) => !addressWithNoPhone[addressItem as keyof SimulatedPayment['address']]
  )

  return {
    customer: onlyDefined({
      filledBy: allAddressKeysUndefined ? 'customer' : 'merchant',
      name: simulatedPayment.address.last_name,
      firstName: simulatedPayment.address.first_name,
      email: simulatedPayment.address.email,
      addressManual: hasAddress ? addressManual : undefined,
      addressComplement: simulatedPayment.address.line2,
      company: simulatedPayment.address.company,
    }),
    purchase: onlyDefined({
      formattedAmountInEuros: centsToEuros(simulatedPayment.purchase_amount).toString(),
      feePlan: { ...simulatedPayment.fee_plan },
    }),
    phone: onlyDefined({
      number: simulatedPayment.address.phone,
    }),
  }
}
