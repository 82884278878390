import { PaymentStatus } from '@/types'

export enum PaymentPeriodFilter {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export const PAYMENT_PERIOD_FILTERS = Object.values(PaymentPeriodFilter)

export type PaymentStatusFilter = 'all' | PaymentStatus

export const PaymentStatusFilters = [
  'all',
  'pending',
  'validated',
  'expired',
  'denied',
] as Array<PaymentStatusFilter>

export type PaymentListFormValues = {
  name: string
  period: PaymentPeriodFilter
  status: PaymentStatusFilter
}
