/**
 * Triggers the download of a file with specified content
 * @param content - The content of the file
 * @param fileName - The name of the file that is created, this is how the file will be named on the user's computer
 * @param options - Blob creation options, use its 'type' property to specify the MIME type of the file
 */
export function triggerFileDownload(content: string, fileName: string, options?: BlobPropertyBag) {
  const blob = new Blob([content], options)
  const url = URL.createObjectURL(blob)

  // Create a hidden link to download the file and click it
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()

  // Cleanup
  link.remove()
  URL.revokeObjectURL(url)
}
