import { PaymentListFormValues } from '@/components/Forms/PaymentList/types'
import { usePaymentFollowupContext } from '@/components/Pages'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { normalize } from '@/utils'
import { AutocompleteField, IconName } from '@alma/react-components'
import React, { useMemo, VoidFunctionComponent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

/**
 * Search algorithm scoring ponderation, for the autocompletion suggestion
 * There are 2 main axis
 * - Wether the string is at the begining of the first/last name, or in the middle of one of them
 * - Wether the string matched with the first or last name
 */
const NAME_AUTOCOMPLETE_PONDERATION = {
  place: {
    start: 5,
    other: 1,
  },
  name: {
    first: 1,
    last: 3,
  },
}

interface NameIndexEntry {
  label: string
  lastName: string
  firstName: string
  normalized: string
}

export const NameSearchInput: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const { control, watch, resetField } = useFormContext<PaymentListFormValues>()
  const intl = useIntl()
  const nameSearched = watch('name')

  const { sortedPayments } = usePaymentFollowupContext()

  // Index all unique names for the search filter auto-suggestion
  const nameIndex: Array<NameIndexEntry> = useMemo(() => {
    /** Index to keep track of which names have already been indexed */
    const indexedNames: Array<string> = []
    return sortedPayments.reduce((index, payment) => {
      const { first_name: firstName, last_name: lastName } = payment.customer
      // Ignore if a payment user has no name
      if (!firstName || !lastName) return index
      const normalized = normalize(`${firstName} ${lastName}`)
      // Ignore if the name has already been indexed
      if (indexedNames.includes(normalized)) return index
      index.push({
        label: `${firstName} ${lastName}`,
        normalized,
        lastName: normalize(lastName),
        firstName: normalize(firstName),
      })
      indexedNames.push(normalized)
      return index
    }, [] as Array<NameIndexEntry>)
  }, [sortedPayments])

  // Filter out matching names for name filter auto-suggestion
  const nameOptions: Array<string> = useMemo(() => {
    // Ignore when name input is empty
    if (!nameSearched) return []
    // The search input is normalized and tokenized by whitespace, as we're expecting latin languages for now
    const normalizedNameInput = normalize(nameSearched)
    const searchTokens = normalizedNameInput.split(' ')

    const result: Array<{ item: string; score: number }> = []
    for (let i1 = 0; i1 < nameIndex.length; i1 += 1) {
      const option = nameIndex[i1]
      let score = 0
      for (let i = 0; i < searchTokens.length; i += 1) {
        const token = searchTokens[i]
        // Skip if token not in name at all
        // eslint-disable-next-line no-continue
        if (!option.normalized.includes(token)) continue

        const calculateScore = (item: string, tokenStr: string, baseScore: number): number => {
          const placementScore = item.startsWith(tokenStr)
            ? NAME_AUTOCOMPLETE_PONDERATION.place.start
            : NAME_AUTOCOMPLETE_PONDERATION.place.other
          const matchFactor = tokenStr.length / item.length
          return (baseScore + placementScore) * matchFactor
        }

        score += calculateScore(option.firstName, token, NAME_AUTOCOMPLETE_PONDERATION.name.first)
        score += calculateScore(option.lastName, token, NAME_AUTOCOMPLETE_PONDERATION.name.last)
      }
      if (score) result.push({ item: option.label, score })
    }
    return result.sort((a, b) => b.score - a.score).map(({ item }) => item)
  }, [nameIndex, nameSearched])

  return (
    <Controller
      control={control}
      name="name"
      render={({ field }) => (
        <AutocompleteField
          {...field}
          iconColor={{ left: '--off-black' }}
          icon={{ left: IconName.search }}
          autoChangeOnSelect
          options={nameOptions}
          isDirty={Boolean(nameSearched)}
          onClearClick={() => resetField('name')}
          itemLabel={(option) => option as string}
          blurButtonMessage={intl.formatMessage({
            id: 'mobile.autocomplete.blur.name-search',
            defaultMessage: 'Clear',
            description:
              'On the search payment by name autocomplete field, when user is on a mobile device, there is a button in the field that can be used to clear the field and unfocus',
          })}
          type="text"
          id="name"
          onSelect={() => track('filter-payments-name-selected')}
          maxItems={5}
          placeholder={intl.formatMessage({
            id: 'payment.list.filter.period.name.placeholder',
            defaultMessage: 'First name, last name',
            description:
              'This is the placeholder of the filter in the past payment list allowing the seller to filter by name',
          })}
          label={
            <FormattedMessage
              id="payment.list.filter.label.name"
              defaultMessage="Search someone"
              description="This is the name of the filter in the past payment list allowing the seller to filter by customer name. There are some autocomplete suggestions with all names from former payments made"
            />
          }
        />
      )}
    />
  )
}
