import s from '@/components/Forms/PaymentCreate/RequirementsForm/RequirementsForm.module.css'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { Icon, LegendMessage, RadioButton } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

export const DEFAULT_REQUIREMENTS_FORM_VALUES: Pick<
  Partial<PaymentCreateFormValues>,
  'requirements'
> = {
  requirements: { mode: 'standard' },
}

export const RequirementsForm: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const { register } = useFormContext<PaymentCreateFormValues>()
  const { isSubmitSuccessful } = useFormState<PaymentCreateFormValues>()

  return (
    <div className={s.requirements}>
      <RadioButton
        {...register('requirements.mode', { value: 'standard' })}
        id="requirements.mode.standard"
        value="standard"
        disabled={isSubmitSuccessful}
        onClick={() => track('fast_mode_max_requirements')}
        label={
          <div className={s.modeOption}>
            <div className={s.optionTitle}>
              <FormattedMessage
                id="home.requirements.mode.standard"
                defaultMessage="We've got time"
                description="In payment form, requirements card, title of the option to choose standard mode"
              />
              <Icon icon="coffee" />
            </div>
            <LegendMessage light>
              <FormattedMessage
                id="home.requirements.mode.standard.details"
                defaultMessage="Standard journey | 90% success rate"
                description="In payment form, requirement card, subtitle of the standard mode option"
              />
            </LegendMessage>
          </div>
        }
      />
      <RadioButton
        {...register('requirements.mode', { value: 'fast' })}
        id="requirements.mode.fast"
        data-testid="button-fast-mode-activated"
        value="fast"
        disabled={isSubmitSuccessful}
        onClick={() => track('fast_mode_min_requirements')}
        color="orange"
        label={
          <div className={s.modeOption}>
            <div className={s.optionTitle}>
              <FormattedMessage
                id="home.requirements.mode.fast"
                defaultMessage="We're in a hurry"
                description="In payment form, requirements card, title of the option to choose fast mode"
              />
              <Icon icon="lightning" />
            </div>
            <LegendMessage light>
              <FormattedMessage
                id="home.requirements.mode.fast.details"
                defaultMessage="Fast journey | 60% success rate"
                description="In payment form, requirement card, subtitle of the fast mode option"
              />
            </LegendMessage>
          </div>
        }
      />
    </div>
  )
}
