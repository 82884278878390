/**
 * This represents the list of merchants countries that are eligible to the referral program.
 * Do not add another country in there as the menu will show the referral link to the new country.
 */
export const countriesEligibleToReferral = [
  { value: 'fr', code: 'fr-FR' },
  { value: 'de', code: 'de-DE' },
  { value: 'es', code: 'es-ES' },
  { value: 'it', code: 'it-IT' },
  { value: 'nl', code: 'nl-NL' },
  { value: 'be', code: 'nl-BE' },
]
