import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import {
  ButtonsAction,
  GreyZone,
  Header,
  ItDoesNotWorkCollapsible,
} from '@/components/PaymentsModal/Templates'
import { HorizontalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const BankingDataVerificationBI: VoidFunctionComponent<StepProps> = ({
  updatedDate,
  amountAndPlan,
  createdDate,
  customerName,
  ActionButtons,
}) => {
  const intl = useIntl()
  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />
      <GreyZone
        progressBarTitle={intl.formatMessage({
          id: 'payment.step.detail.progress.title.verifications',
          defaultMessage: 'Verifications',
          description:
            'Title of the progress bar in the payment detail modal for the verifications steps',
        })}
        progress={40}
      >
        <HorizontalTitle updated={updatedDate}>
          <FormattedMessage
            id="payment.detail.requirementStep.banking_data_verification_BI"
            defaultMessage="Automatic resource check"
            description="Title of the payment requirement step in which we analyze the client's bank account history"
          />
        </HorizontalTitle>
        <div>
          <FormattedMessage
            id="payment.detail.BI.authorization.info"
            defaultMessage="Your customer selects his bank and connects to it using his bank identifiers (present on his account statements). Next, he selects his checking account so that Alma can assess his financial situation."
            description="In the payment's detail modal this explains that the customer should connect their bank account for verification at this step."
          />
        </div>
      </GreyZone>
      <ItDoesNotWorkCollapsible>
        <div>
          <FormattedMessage
            id="payment.detail.ressources.declaration.not.working.info"
            defaultMessage="Your client must select their current account. This is the only account on which we can verify his financial situation."
            description="In the payment's detail modal this explains that the customer should connect their bank account for verification at this step."
          />
        </div>
        <ButtonsAction>
          <ActionButtons.Restart color="link" />
          <ActionButtons.Cancel color="link" />
        </ButtonsAction>
      </ItDoesNotWorkCollapsible>
    </>
  )
}
