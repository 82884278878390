import { PostBillingAddress, PostPayment } from '@/apis/api/postPayment'
import { PostPaymentUpdate } from '@/apis/api/postPaymentUpdate'
import { hasInstallments } from '@/apis/utils'
import {
  ADDRESS_NOT_IN_LIST_CASE,
  CustomerInfoFormValue,
  PaymentCreateFormValues,
  PhoneNumberFormValue,
} from '@/components/Forms/PaymentCreate/types'
import { ActiveTags, Locale, Me, Payment, PaymentOrigin, hasFeatureTag } from '@/types'
import { eurosToCents } from '@/utils/prices'

const createCustomerAddressFromValues = (
  customer: CustomerInfoFormValue,
  phone: PhoneNumberFormValue
) =>
  // This customer address is done after the form submit validation so we don't have to test if some required field are OK
  // But, if customer info are optional, autofillAddress might remain undefined but still valid.
  // In this case, we read values from manualAddress, even if it's empty.
  !customer.addressAutocomplete || customer.addressAutocomplete === ADDRESS_NOT_IN_LIST_CASE
    ? {
        city: customer.addressManual.city,
        country: customer.addressManual.country,
        email: customer.email,
        first_name: customer.firstName,
        last_name: customer.name,
        line1: customer.addressManual.address,
        line2: customer.addressComplement,
        phone: phone.number,
        postal_code: customer.addressManual.zipCode,
        state_province: customer.addressManual.state,
        county_sublocality: customer.addressManual.district,
      }
    : {
        city: customer.addressAutocomplete.city,
        country: customer.addressAutocomplete.country,
        email: customer.email,
        first_name: customer.firstName,
        last_name: customer.name,
        line1: customer.addressAutocomplete.address,
        line2: customer.addressComplement,
        phone: phone.number,
        postal_code: customer.addressAutocomplete.zipCode,
        state_province: customer.addressAutocomplete.state,
        county_sublocality: customer.addressAutocomplete.district,
      }

export interface PaymentOriginOptions {
  origin: PaymentOrigin
  customer_cancel_url: string | null
  return_url: string | null
}

type CreatePaymentOptions = PaymentCreateFormValues &
  PaymentOriginOptions & {
    locale: Locale
    customerChooseInstallments: boolean
    merchantCanOfferFees: boolean
  }

export const createPaymentPayload: (options: CreatePaymentOptions, me: Me) => PostPayment = (
  {
    phone,
    customer,
    order,
    purchase,
    custom,
    origin,
    locale,
    customer_cancel_url,
    return_url,
    offerFees,
    customerChooseInstallments,
    requirements,
    merchantCanOfferFees,
  }: CreatePaymentOptions,
  me
) => {
  const feePlanSelected = (!customerChooseInstallments && purchase.feePlan) || undefined

  const customerAddress =
    customer.filledBy === 'merchant' ? createCustomerAddressFromValues(customer, phone) : undefined
  // Here we verify that the merchant has the tag deferred_capture
  const additionalOptions = hasFeatureTag(ActiveTags.deferredCapture, me)
    ? {
        capture_method: 'manual',
      }
    : {}
  return {
    customer: {
      first_name: customerAddress && customer.firstName,
      last_name: customerAddress && customer.name,
      email: customerAddress && customer.email,
      phone: phone.number,
      addresses: customerAddress && [customerAddress],
    },
    order: {
      comment: order.comment,
      merchant_reference: order.reference,
    },
    payment: {
      deferred_days: feePlanSelected?.deferred_days || 0,
      deferred_months: feePlanSelected?.deferred_months || 0,
      installments_count: feePlanSelected?.installments_count || null,
      billing_address: customerAddress,
      customer_cancel_url,
      locale,
      origin,
      purchase_amount: eurosToCents(Number(purchase.formattedAmountInEuros)),
      return_url,
      custom_data: custom,
      customer_fee_variable: merchantCanOfferFees && offerFees?.offered ? 0 : undefined,
      fail_fast: requirements.mode === 'fast',
      ...additionalOptions,
    },
  }
}

type CreatePaymentUpdateOptions = {
  values: PaymentCreateFormValues
  originalPayment: Payment
  /** Don't set this parameter for now, we don't want to override the locale set by the initial call yet */
  locale?: Locale
  customerChooseInstallments: boolean
  merchantCanOfferFees: boolean
  origin: PaymentOrigin
}

export const createPaymentUpdatePayload: (
  options: CreatePaymentUpdateOptions
) => PostPaymentUpdate = ({
  values: { purchase, customer, phone, offerFees, custom },
  originalPayment,
  locale,
  customerChooseInstallments,
  merchantCanOfferFees,
  origin,
}) => {
  const feePlanSelected =
    customerChooseInstallments || hasInstallments(originalPayment) ? undefined : purchase.feePlan
  const customerAddress =
    customer.filledBy === 'merchant' ? createCustomerAddressFromValues(customer, phone) : undefined

  const billingAddress =
    customerAddress &&
    Object.fromEntries(
      Object.entries(customerAddress).filter(
        ([attribute]) => !originalPayment.billing_address?.[attribute as keyof PostBillingAddress]
      )
    )

  // Payment to finalize can be created with custom_data that are not set in the custom_data_template
  // No field will be displayed in PoS for those custom_data, but we don't want to erase it from the finalized payment.
  // If there is custom_data from the payment, we need to get it and merge them into the custom_data object created from the PoS fields.
  const customDataFromOriginalPayment = Object.fromEntries(
    Object.keys(originalPayment.custom_data ?? {}).map((key) => [
      key,
      originalPayment.custom_data?.[key] ?? '',
    ])
  )
  const mergedCustomData = { ...customDataFromOriginalPayment, ...custom }

  return {
    payment: {
      installments_count: feePlanSelected?.installments_count,
      fee_plan_reference: undefined, // new API does not return the merchant reference
      // Billing address can only accept values that were not previously defined
      billing_address: billingAddress,
      locale,
      custom_data: mergedCustomData,
      customer_fee_variable: merchantCanOfferFees && offerFees?.offered ? 0 : undefined,
      origin,
    },
    customer: {
      first_name: originalPayment?.customer?.first_name ? undefined : customer.firstName,
      last_name: originalPayment?.customer?.last_name ? undefined : customer.name,
      email: originalPayment?.customer?.email ? undefined : customer.email,
      phone: originalPayment?.customer?.phone ? undefined : phone.number,
      addresses: customerAddress && [customerAddress],
    },
  }
}
