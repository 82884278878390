import { loadExternalService } from '@/utils/externalService'
import { FeePlanKey } from '@/utils/utils'

// We list the properties we want to send to our event that are CNIL exempt friendly
type ExemptPropertiesList =
  | 'merchant'
  | 'merchant_country'
  | 'available_payments'
  | 'fees_offering_option'
  | 'chat_option'
  | 'merchant_role'
  | 'fees_offered'
  | 'installments'
  | 'payment_type'

export type PianoAnalytics = {
  setConfiguration: (site: string, siteId: number) => void
  setConfigurations: (conf: { collectDomain: string; site: number | string }) => void
  privacy: {
    setMode: (mode: string) => void
    include: {
      properties: (propsArray: ExemptPropertiesList[], modes: string[], events: string[]) => void
    }
  }
  setProperties: (
    props: PageViewDataType | EventProps,
    options?: { persistent?: boolean; events?: string[] }
  ) => void
  sendEvent: (eventName: string, props: SendEventProps) => void
} & Record<string, unknown>

declare global {
  interface Window {
    pa?: PianoAnalytics
  }
}

export type SendEventProps = {
  page: string
  click?: string
}

export type EventProps = {
  fees_offered?: boolean
  installments: FeePlanKey
  payment_type: 'credit' | 'pnx' | 'deferred'
}

// Data required when tracking a page view
export type PageViewDataType = {
  merchant: string
  merchant_country: string
  available_payments: string[]
  fees_offering_option: boolean
  chat_option: boolean
  merchant_role: string | null
}

const pianoAnalyticsUrl = 'https://tag.aticdn.net/piano-analytics.js'
export function loadAnalytics() {
  if (process.env.NODE_ENV === 'storybook' || process.env.NODE_ENV === 'test') {
    // at internet is not started on storybook and test env
    return Promise.resolve()
  }

  return loadExternalService({ src: pianoAnalyticsUrl })
}
export function isPianoAnalytics(pa: PianoAnalytics | undefined): pa is PianoAnalytics {
  return (pa && 'setConfigurations' in pa) || false
}

export function pianoAnalytics() {
  return window?.pa
}

export function isPianoAnalyticsLoaded() {
  return isPianoAnalytics(pianoAnalytics())
}
