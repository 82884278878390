import { searchAddress } from '@/apis/geocoding'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { useSafeQuery, UseSafeQueryOptions } from '@/apis/hooks/utils'
import { MapboxGeocodingResult } from '@/types/Mapbox'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

export const useSearchAddressQuery = (
  search: string,
  options?: UseSafeQueryOptions<MapboxGeocodingResult, unknown, MapboxGeocodingResult, string[]>
) =>
  useSafeQuery([CacheKeys.Geocoding, search], async ({ signal }) => searchAddress(search, signal), {
    enabled: search.length > 4,
    retry: 1,
    keepPreviousData: true,
    ...options,
  })

export const useClearAddressSearch = () => {
  const client = useQueryClient()
  return useCallback(() => client.invalidateQueries(CacheKeys.Geocoding), [client])
}
