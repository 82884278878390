import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import {
  ButtonsAction,
  GreyZone,
  Header,
  ItDoesNotWorkCollapsible,
  List,
  ListItem,
} from '@/components/PaymentsModal/Templates'
import { HorizontalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const IdVideoVerification: VoidFunctionComponent<StepProps> = ({
  amountAndPlan,
  customerName,
  createdDate,
  updatedDate,
  ActionButtons,
}) => {
  const intl = useIntl()
  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />{' '}
      <GreyZone
        progressBarTitle={intl.formatMessage({
          id: 'payment.step.detail.progress.title.verifications',
          defaultMessage: 'Verifications',
          description:
            'Title of the progress bar in the payment detail modal for the verifications steps',
        })}
        progress={40}
      >
        <HorizontalTitle updated={updatedDate}>
          <FormattedMessage
            id="payment.detail.requirementStep.id_video_verification"
            defaultMessage="Identity verification"
            description="Title of the payment requirement step in which we verify the customer's identity documents"
          />
        </HorizontalTitle>

        <List>
          <ListItem>
            <FormattedMessage
              id="payment.detail.idVideoVerification.risk"
              defaultMessage="We must verify the identity of your customer to avoid any risk of fraud."
              description="In the payment detail modal, we need to check identity of the customer"
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="payment.detail.idVideoVerification.idPicture"
              defaultMessage="Your client must take a picture of his ID (ID card, passport, residence permit)"
              description="In order to check the identity of the customer, he must take a picture of his ID"
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="payment.detail.idVideoVerification.videoCapture"
              defaultMessage="He will then have to take a video of his face so that we can compare it with the ID provided. In some cases, we will ask him/her to fill in some information about his/her residence."
              description="In order to verify the identity of the customer, he must take a video of his face to compare it with the ID provided."
            />
          </ListItem>
        </List>
      </GreyZone>
      <ItDoesNotWorkCollapsible>
        <div className={s.listWithLabel}>
          <FormattedMessage
            id="payment.detail.idVideoVerification.not.working.info"
            defaultMessage="To help your client take a good photo of their ID card, make sure that :"
            description="In the payment's detail modal, this text explains steps to verify the customer's identity"
          />
        </div>
        <List>
          <ListItem>
            <FormattedMessage
              id="payment.detail.idVideoVerification.not.working.corner"
              defaultMessage="all 4 corners of the document are included in the photo"
              description="In the payment's detail modal, this text explains that the customer should care about corner of his ID"
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="payment.detail.idVideoVerification.not.working.background"
              defaultMessage="the background is plain"
              description="On the payments details page, this text explains that the customer should care that the background is plain"
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="payment.detail.idVideoVerification.not.working.reflection"
              defaultMessage="there is no reflection"
              description="On the payments details page, this text explains that the customer should care that there is no reflection on his ID"
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="payment.detail.idVideoVerification.not.working.writing"
              defaultMessage="the writing is clear"
              description="On the payments details page, this text explains that the customer should care that the writing is clear on his ID"
            />
          </ListItem>
        </List>
        <ButtonsAction>
          <ActionButtons.Restart color="link" />
          <ActionButtons.Cancel color="link" />
        </ButtonsAction>
      </ItDoesNotWorkCollapsible>
    </>
  )
}
