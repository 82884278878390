import { NameSearchInput } from '@/components/Forms/PaymentList/PaymentSearchForm/NameSearchInput'
import s from '@/components/Forms/PaymentList/PaymentSearchForm/PaymentSearchForm.module.css'
import {
  PAYMENT_PERIOD_FILTERS,
  PaymentListFormValues,
  PaymentPeriodFilter,
  PaymentStatusFilter,
  PaymentStatusFilters,
} from '@/components/Forms/PaymentList/types'
import { usePaymentFollowupContext } from '@/components/Pages'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { normalize } from '@/utils'
import { useScreenBreakpoint } from '@/utils/useScreenBreakpoint'
import { Card, CardTemplateCollapsible, DropdownField, Header } from '@alma/react-components'
import React, { useCallback, useEffect, useMemo, VoidFunctionComponent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

export const PaymentSearchForm: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const intl = useIntl()
  const [collapsed, setCollapsed] = React.useState(false)
  const { control } = useFormContext<PaymentListFormValues>()
  const isLargeScreen = useScreenBreakpoint('md')

  const paymentPeriodLabel = useCallback(
    (filter: PaymentPeriodFilter) => {
      switch (filter) {
        case 'day':
          return intl.formatMessage({
            id: 'payment.list.filter.period.day',
            defaultMessage: 'Today',
            description: `This is one of the date filter option in the past payment list. This allows you to filter payments done since yesterday midnight until now`,
          })
        case 'week':
          return intl.formatMessage({
            id: 'payment.list.filter.period.week',
            defaultMessage: 'This week',
            description:
              'This is one of the date filter option in the past payment list. This allows you to filter payments done since the day 7 days before today at midnight until now',
          })
        case 'month':
          return intl.formatMessage({
            id: 'payment.list.filter.period.month',
            defaultMessage: 'This month',
            description: `This is one of the date filter option in the past payment list. This allows you to filter payments done since the day 30 days before today at midnight until now`,
          })
        default:
          return intl.formatMessage({
            id: 'payment.list.filter.period.year',
            defaultMessage: 'This year',
            description:
              'This is one of the date filter option in the past payment list. This allows you to filter payments done since the day 365 days before today at midnight until now',
          })
      }
    },
    [intl]
  )

  const paymentPeriodOptions = useMemo(
    () =>
      PAYMENT_PERIOD_FILTERS.map((period) => ({
        label: paymentPeriodLabel(period),
        value: period,
      })),
    [paymentPeriodLabel]
  )

  const paymentStatusLabel = useCallback(
    (filter: PaymentStatusFilter) => {
      switch (filter) {
        case 'all':
          return intl.formatMessage({
            id: 'payment.list.filter.status.all',
            defaultMessage: 'All',
            description:
              'This is one of the status filter option in the past payment list. This allows you to filter payments at all statuses available (paid, expired, refused, ongoing)',
          })
        case 'validated':
          return intl.formatMessage({
            id: 'payment.list.filter.status.done',
            defaultMessage: 'Validated',
            description:
              'This is one of the status filter option in the past payment list. This allows you to filter payments that are at the "paid" status, which means the first installment has been paid or secured in the case of deferred payment',
          })
        case 'expired':
          return intl.formatMessage({
            id: 'payment.list.filter.status.expired',
            defaultMessage: 'Expired',
            description:
              'This is one of the status filter option in the past payment list. This allows you to filter payments that are at the "expired" status, which means the payment link expired according to the validity time that was set for the payment link. It is 2880 minutes by defaut, ie. 48h but it can be changed to the seller\'s preferencs',
          })
        case 'denied':
          return intl.formatMessage({
            id: 'payment.list.filter.status.denied',
            defaultMessage: 'Denied',
            description:
              'This is one of the status filter option in the past payment list. This allows you to filter payments that are at the "denied" status, which means that Alma did not allow the installment plan for this payment.',
          })
        default:
          return intl.formatMessage({
            id: 'payment.list.filter.status.pending',
            defaultMessage: 'Pending',
            description:
              'This is one of the status filter option in the past payment list. This allows you to filter payments that are at the "ongoing" status, which means that the end customer is able to go through his/her checkout process.',
          })
      }
    },
    [intl]
  )

  const paymentStatusOptions = PaymentStatusFilters.map((status) => ({
    label: paymentStatusLabel(status),
    value: status,
  }))

  // Apply filters
  const { watch } = useFormContext<PaymentListFormValues>()
  const [period, status, name] = watch(['period', 'status', 'name'])
  const { sortedPayments, setFilteredPayments } = usePaymentFollowupContext()
  useEffect(() => {
    const filteredPayments = sortedPayments.filter((payment) => {
      // Filter by status
      if (status !== 'all' && status !== payment.status) return false
      // Filter by name
      if (name) {
        const evalName = `${payment.customer.first_name} ${payment.customer.last_name}`
        if (!normalize(evalName).includes(normalize(name))) return false
      }
      return true
    })

    setFilteredPayments(filteredPayments)
  }, [period, status, name, sortedPayments, setFilteredPayments])

  const form = (
    <>
      <NameSearchInput track={track} />
      <Controller
        control={control}
        name="period"
        render={({ field }) => (
          <DropdownField
            {...field}
            label={
              <FormattedMessage
                id="payment.list.filter.period.name"
                defaultMessage="Date"
                description="This is the name of the filter in the past payment list allowing the seller to filter by date (today, this week, this month, this year)"
              />
            }
            id="period"
            options={paymentPeriodOptions}
            allowEmptyOption={false}
          />
        )}
      />
      <Controller
        control={control}
        name="status"
        render={({ field }) => (
          <DropdownField
            {...field}
            label={
              <FormattedMessage
                id="payment.list.filter.status.name"
                defaultMessage="Status"
                description="This is the name of the filter in the past payment list allowing the seller to filter by status (paid, ongoing, refused, expired or all)"
              />
            }
            id="status"
            options={paymentStatusOptions}
            allowEmptyOption={false}
          />
        )}
      />
    </>
  )
  return (
    <>
      {isLargeScreen ? (
        <Card className={s.container} data-testid="payment-search-form" padding="lg">
          <Header size="sm">
            <FormattedMessage
              id="payments.list.filter.title"
              defaultMessage="Search and filter"
              description="This is the title of the part in past payment list where you can enter filters to narrow/expand the list (by date, by status and by customer name) and/or download a matching export of the past payment made that are currently displayed"
            />
          </Header>
          {form}
        </Card>
      ) : (
        <CardTemplateCollapsible
          data-testid="payment-search-form"
          className={s.container}
          header={
            <Header size="sm">
              <FormattedMessage
                id="payments.list.filter.title"
                defaultMessage="Search and filter"
                description="This is the title of the part in past payment list where you can enter filters to narrow/expand the list (by date, by status and by customer name) and/or download a matching export of the past payment made that are currently displayed"
              />
            </Header>
          }
          borderColor="gray"
          padding="lg"
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          chevronColor="var(--alma-orange)"
        >
          {form}
        </CardTemplateCollapsible>
      )}
    </>
  )
}
