import { useMe } from '@/components/AuthenticationManager'
import { useLastTerminal } from '@/components/SelectTerminal/useLastTerminal'
import { getTerminal } from '@/components/SelectTerminal/utils'
import { Dropdown } from '@alma/react-components'
import React, { useMemo, VoidFunctionComponent } from 'react'
import { useController, useFormContext } from 'react-hook-form'

type Props = {
  name: string
  disabled?: boolean
}

export const SelectTerminalDropdown: VoidFunctionComponent<Props> = ({ name, disabled }) => {
  const { watch } = useFormContext()

  const {
    field: { onChange },
  } = useController({ name })
  const me = useMe()
  const currentTerminal = watch(name)
  const [, setLastTerminalToStorage] = useLastTerminal()
  const terminalOptions = useMemo(
    () =>
      me.terminals.map((terminal) => ({
        value: terminal.provider_reference,
        label: terminal.name,
      })),
    [me]
  )

  return (
    <Dropdown
      options={terminalOptions}
      onChange={(e) => {
        onChange(getTerminal(me, e.target.value))
        setLastTerminalToStorage(e.target.value)
      }}
      value={currentTerminal?.provider_reference}
      name={name}
      data-testid="test-select-terminal"
      disabled={disabled}
    />
  )
}
