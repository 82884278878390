import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import s from '@/components/Pages/PaymentDetails/PaymentDetails.module.css'
import { GreyZone, Header, ListItem } from '@/components/PaymentsModal/Templates'
import {
  useAmountAndPlanForConfiguredPayment,
  useCreatedDate,
  useCustomerName,
} from '@/utils/usePartialPaymentDetails'
import { Button, Checkbox, Header as HeaderRC, Modal } from '@alma/react-components'
import { getUnixTime } from 'date-fns'
import React, { BaseSyntheticEvent, useState, VoidFunctionComponent } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (e: BaseSyntheticEvent) => void
}
export const IDCheckModal: VoidFunctionComponent<Props> = ({ isOpen, onClose, onSubmit }) => {
  const intl = useIntl()
  const { watch } = useFormContext<PaymentCreateFormValues>()
  const [IDCheck, setIDCheck] = useState(false)

  const [firstName, lastName, purchaseAmount, feePlan] = watch([
    'customer.firstName',
    'customer.name',
    'purchase.formattedAmountInEuros',
    'purchase.feePlan',
  ])
  const nowDate = getUnixTime(Date.now())
  const createdDate = useCreatedDate(nowDate)
  const customerName = useCustomerName(firstName, lastName)
  const amountAndPlanConfigured = useAmountAndPlanForConfiguredPayment(
    feePlan?.installments_count ?? 0,
    Number(purchaseAmount),
    feePlan?.deferred_days ?? 0,
    feePlan?.deferred_months ?? 0,
    { isAlreadyInEuros: true }
  )

  const amountAndPlan = feePlan
    ? amountAndPlanConfigured
    : // When plan is not defined, healthcare mode is activated so we only display the amount
      intl.formatMessage(
        {
          id: 'payment.detail.not_started',
          defaultMessage: '{amount, number, ::currency/EUR}',
          description:
            'Displays the total purchase amount of a payment in a monetary style. This is reused in multiple places.',
        },
        {
          amount: purchaseAmount,
        }
      )
  return (
    <Modal shouldCloseOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="lg">
      <div className={s.paymentDetails}>
        <Header name={customerName} createdDate={createdDate} amountAndPlan={amountAndPlan} />
        <GreyZone>
          <HeaderRC size="md">
            <FormattedMessage
              id="id.check.for.ept.modal.title"
              defaultMessage="ID verification"
              description="When a merchant wants to create a purchase of 1000€ or more, they should ask for the customer's ID to continue the purchase. This is the title of the modal to check the ID"
            />
          </HeaderRC>
          <strong>
            <FormattedMessage
              id="id.check.for.ept.modal.description"
              defaultMessage="You must copy and verify one of the following identity document to initiate the payment:"
              description="When a merchant wants to create a purchase of 1000€ or more, they should ask for the customer's ID to continue the purchase. This text explain what the merchant has to do. {line} is meant to display text on several lines, for UI."
            />
          </strong>
          <ul>
            <ListItem>
              <FormattedMessage
                id="id.check.for.ept.modal.list.ID.card"
                defaultMessage="French or European national identity card"
                description="When a merchant wants to create a purchase of 1000€ or more, they should ask for the customer's ID to continue the purchase. This is an item of the accepted ID list."
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id="id.check.for.ept.modal.list.passport"
                defaultMessage="French or European passport"
                description="When a merchant wants to create a purchase of 1000€ or more, they should ask for the customer's ID to continue the purchase. This is an item of the accepted ID list."
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id="id.check.for.ept.modal.list.permit"
                defaultMessage="Residence permit"
                description="When a merchant wants to create a purchase of 1000€ or more, they should ask for the customer's ID to continue the purchase. This is an item of the accepted ID list."
              />
            </ListItem>
          </ul>
        </GreyZone>
        <div className={s.blockActions}>
          <Checkbox
            id="confirmation.manual.ID.check"
            label={
              <FormattedMessage
                id="id.check.for.ept.modal.checkbox.label"
                defaultMessage="I confirm that I have copied the client's identity document"
                description="When a merchant wants to create a purchase of 1000€ or more, they should ask for the customer's ID to continue the purchase. This is the label of the confirmation check box."
              />
            }
            checked={IDCheck}
            onChange={(e) => setIDCheck(e.target.checked)}
          />
          <Button
            color="primary"
            type="submit"
            block
            disabled={!IDCheck}
            onClick={(e: BaseSyntheticEvent) => {
              onClose()
              onSubmit(e)
            }}
          >
            <FormattedMessage
              id="id.check.for.ept.modal.continue.button"
              defaultMessage="Initiate the payment"
              description="When a merchant wants to create a purchase of 1000€ or more, they should ask for the customer's ID to continue the purchase. This is the button to confirm the check has been dne and continue the payment"
            />
          </Button>
          <Button color="link" type="button" onClick={onClose}>
            <FormattedMessage
              id="id.check.for.ept.modal.cancel.button"
              defaultMessage="Cancel payment"
              description="When a merchant wants to create a purchase of 1000€ or more, they should ask for the customer's ID to continue the purchase. This is the button to cancel the payment"
            />
          </Button>
        </div>
      </div>
    </Modal>
  )
}
