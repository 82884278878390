import { eligibilityCheckGenerator } from '@/apis/api/eligibilityCheck'
import { getFeePlansGenerator } from '@/apis/api/getFeePlans'
import { getMeGenerator } from '@/apis/api/getMe'
import { getPaymentGenerator } from '@/apis/api/getPayment'
import { getPaymentPollGenerator } from '@/apis/api/getPaymentPoll'
import { getSimulatedPaymentGenerator } from '@/apis/api/getSimulatedPayment'
import { getZendeskTokenGenerator } from '@/apis/api/getZendeskToken'
import { logUnreachableHostsGenerator } from '@/apis/api/logUnreachableHosts'
import { patchUpdateUserLocaleGenerator } from '@/apis/api/patchUpdateUserLocale'
import { postCancelPaymentGenerator } from '@/apis/api/postCancelPayment'
import { postContextGenerator } from '@/apis/api/postContext'
import { postLoginGenerator } from '@/apis/api/postLogin'
import { postLogoutGenerator } from '@/apis/api/postLogout'
import { postPaymentGenerator } from '@/apis/api/postPayment'
import { postPaymentUpdateGenerator } from '@/apis/api/postPaymentUpdate'
import { postSelectedMerchantGenerator } from '@/apis/api/postSelectedMerchant'
import { sendPaymentBySmsGenerator } from '@/apis/api/sendPaymentBySms'
import { makeClient, onErrorStatusHandlerGenerator } from '@/apis/client'
import { config } from '@/config'

const client = makeClient(`${config.API_URL}`)

export const onApiAuthenticationError = (handler: () => void) =>
  onErrorStatusHandlerGenerator(client)(handler, 401, 403)

export const eligibilityCheck = eligibilityCheckGenerator(client)
export const getFeePlans = getFeePlansGenerator(client)
export const getMe = getMeGenerator(client)
export const getPayment = getPaymentGenerator(client)
export const getPaymentPoll = getPaymentPollGenerator(client)
export const getZendeskToken = getZendeskTokenGenerator(client)
export const logUnreachableHosts = logUnreachableHostsGenerator(client)
export const cancelPayment = postCancelPaymentGenerator(client)
export const postLogin = postLoginGenerator(client)
export const postContext = postContextGenerator(client)
export const postLogout = postLogoutGenerator(client)
export const postPayment = postPaymentGenerator(client)
export const postPaymentUpdate = postPaymentUpdateGenerator(client)
export const postSelectedMerchant = postSelectedMerchantGenerator(client)
export const patchUpdateUserLocale = patchUpdateUserLocaleGenerator(client)
export const sendPaymentBySms = sendPaymentBySmsGenerator(client)
export const getSimulatedPayment = getSimulatedPaymentGenerator(client)
