import * as Sentry from '@sentry/browser'

export const loadExternalService = ({
  id,
  snippet,
  src,
  async = true,
}: {
  id?: string
  snippet?: string
  src?: string
  async?: boolean
}) =>
  new Promise<HTMLScriptElement>((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    if (id) {
      script.id = id
    }
    if (snippet) {
      script.textContent = snippet
    }
    if (src) {
      script.src = src
    }
    script.async = async
    script.addEventListener('load', () => {
      resolve(script)
    })
    script.addEventListener('error', (ev) => {
      Sentry.addBreadcrumb({
        level: 'error',
        message: 'Error while loading external script',
        data: { src, snippet },
      })

      reject(ev.error)
      document.head.removeChild(script)
    })

    document.head.appendChild(script)
  })
