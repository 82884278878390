import { IntlShape } from 'react-intl'
import { PaymentStatus } from '@/types'

/** Get the string that should be displayed as status information for a payment */
export function getPaymentStatusString(status: PaymentStatus, intl: IntlShape) {
  switch (status) {
    case 'expired':
      return intl.formatMessage({
        id: 'payment.detail.status.expired.label',
        defaultMessage: 'Expired',
        description:
          'This is one of the status filter option in the past payment list. This allows you to filter payments that are expired',
      })
    case 'validated':
      return intl.formatMessage({
        id: 'payment.detail.status.validated.label',
        defaultMessage: 'Validated',
        description:
          'This is one of the status filter option in the past payment list. This allows you to filter payments that have been successfully processed',
      })
    case 'denied':
      return intl.formatMessage({
        id: 'payment.detail.status.denied.label',
        defaultMessage: 'Denied',
        description:
          'This is one of the status filter option in the past payment list. This allows you to filter payments that Alma has not accepted',
      })
    default:
      return intl.formatMessage({
        id: 'payment.detail.status.pending.label',
        defaultMessage: 'Pending',
        description:
          'This is one of the status filter option in the past payment list. This allows you to filter payments that are available and where the end customer can go through the process. This is where he/she may be blocked or dropped it, hence the need for follow-up',
      })
  }
}
