import { DefaultHeader, HomeHeader } from '@/components/PageHeader'
import { Home } from '@/components/Pages/Home/CreatePaymentPage/Home'
import { PaymentCreationContextProvider } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/PaymentCreationContextProvider'
import { FinalizePaymentPage } from '@/components/Pages/Home/FinalizePaymentPage/FinalizePaymentPage'
import { PaymentBarcode } from '@/components/Pages/PaymentBarcode/PaymentBarcode'
import { PaymentDetails } from '@/components/Pages/PaymentDetails/PaymentDetails'
import { PaymentFollowup } from '@/components/Pages/PaymentFollowup'
import { PaymentSimulatedPage } from '@/components/Pages/PaymentSimulatedPage'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, Route, Routes } from 'react-router-dom'
import { FinalizeHeader } from '@/components/FinalizeHeader'

export function RoutesHandler() {
  return (
    <>
      <Routes>
        <Route path="finalize/:paymentId" element={<FinalizeHeader />} />
        <Route path="/*" element={<HomeHeader />} />
        <Route
          path="payments/*"
          element={
            <DefaultHeader>
              <FormattedMessage
                id="payments.header.title"
                defaultMessage="Payments"
                description='Title of the page that opens when clicking on the button "Consult payments" on the right-hand corner of POS page. This page displays the list of payments done via the POS application.'
              />
            </DefaultHeader>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/"
          element={
            <PaymentCreationContextProvider>
              <Home />
            </PaymentCreationContextProvider>
          }
        >
          <Route path="payment" element={<Navigate to="/" replace />} />
          <Route path="payment/:paymentId" element={<PaymentDetails />} />
          <Route path="payment/:paymentId/barcode" element={<PaymentBarcode />} />
          <Route path="simulated-payments" element={<PaymentSimulatedPage />} />
        </Route>
        <Route path="payments" element={<PaymentFollowup />}>
          <Route path=":paymentId" element={<PaymentDetails />} />
          <Route path=":paymentId/barcode" element={<PaymentBarcode />} />
        </Route>
        <Route path="finalize/:paymentId" element={<FinalizePaymentPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  )
}
