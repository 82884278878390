import React, { VoidFunctionComponent, useMemo, useState } from 'react'
import { Button } from '@alma/react-components'
import { HelpCenter } from '@/components/HelpCenter/HelpCenter'
import s from '@/components/HelpCenter/HelpCenter.module.css'
import { isStonlyLoaded } from '@/thirdParties/stonly/stonly'
import { useTracking } from '@/thirdParties/analytics/useTracking'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { useLocation } from 'react-router'

export const HelpCenterButton: VoidFunctionComponent = () => {
  const [isModaleVisible, setIsModaleVisible] = useState(true)
  const canDisplayLinkToTutorial = useMemo(() => isStonlyLoaded(), [])

  const location = useLocation()
  const noDisplayStonly = !!location.pathname.includes('/payments')

  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const closeHelpCenter = () => {
    setIsPopUpOpen(false)
  }

  const track = useTracking('help_center_button')

  const handleButtonClick = () => {
    setIsModaleVisible(true)

    if (isPopUpOpen && isModaleVisible) {
      closeHelpCenter()
    } else {
      setIsPopUpOpen(true)
    }
  }

  const handleZendeskClick = () => {
    setIsModaleVisible(false)
  }

  return (
    <div className={s.openHelpCenterButtonContainer}>
      <Button
        className={s.openHelpCenterButton}
        onClick={() => {
          track('help_center_open')
          handleButtonClick()
        }}
      >
        <FormattedMessage
          id="help.center.link.to.help.center"
          defaultMessage="Help Center"
          description="This is a button that opens our Help Center modal."
        />
      </Button>
      {isPopUpOpen && (
        <div className={cx(s.popup, { [s.isVisible]: isModaleVisible })}>
          <HelpCenter
            handleZendeskClick={handleZendeskClick}
            canDisplayLinkToTutorial={canDisplayLinkToTutorial}
            onCloseHelpCenter={closeHelpCenter}
            noDisplayStonly={noDisplayStonly}
          />
        </div>
      )}
    </div>
  )
}
