import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import { ButtonsAction, GreyZone, Header } from '@/components/PaymentsModal/Templates'
import { VerticalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import { IconName } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const Expired: VoidFunctionComponent<
  StepProps & {
    isUserMDM: boolean
  }
> = ({ amountAndPlan, customerName, createdDate, ActionButtons, isUserMDM }) => (
  <>
    <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />
    <GreyZone>
      <VerticalTitle icon={{ icon: IconName.clock, color: 'var(--alma-yellow)' }}>
        <FormattedMessage
          id="payment.detail.requirementStep.expired"
          defaultMessage="Payment expired"
          description="Title the payment requirement step when the payment has expired"
        />
      </VerticalTitle>
      <div className={s.greyZoneDetailContainer}>
        <p>
          <FormattedMessage
            id="payment.step.detail.content.expired"
            defaultMessage="Payment has expired, please try again"
            description="Content the payment requirement step when the payment is expired"
          />
        </p>
      </div>
    </GreyZone>
    <ButtonsAction>
      {/* TODO: Maybe we can find an other way to handle mdm check (maybe more globally) */}
      {isUserMDM ? (
        <ActionButtons.Close color="primary" block />
      ) : (
        <ActionButtons.Restart color="primary" block />
      )}
    </ButtonsAction>
  </>
)
