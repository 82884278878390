import type enMessages from '@/intl/compiled/messages.en.json'
import { Locale } from '@/types/locale'
import { formatLocaleIntl, getBrowserLocale } from '@/utils/locale'
import * as Sentry from '@sentry/react'
// eslint-disable-next-line import/no-duplicates -- eslint does not want to import twice from date-fns even if the folders are different
import { Locale as LocaleDateFns } from 'date-fns'
// eslint-disable-next-line import/no-duplicates -- eslint does not want to import twice from date-fns even if the folders are different
import { de, enUS, es, fr, it, nl, pt } from 'date-fns/locale'
import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IntlProvider } from 'react-intl'

// Typing ensures that every locale must be loaded
type IntlMessages = {
  [key in Locale]: Record<string, string>
}
type MessageLoader = {
  load: Promise<IntlMessages>
} & (
  | { loaded: false }
  | {
      loaded: true
      messagesByLocale: IntlMessages
    }
)
// In tests the messages loading is synchronous (require()), in dev/prod it is async (import()).
const messagesLoader: MessageLoader =
  process.env.NODE_ENV === 'test'
    ? (() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
        const testMessages = require('@/intl/messages.compiled.json')
        const messagesByLocale = {
          fr: testMessages,
          en: testMessages,
          de: testMessages,
          es: testMessages,
          it: { ...testMessages, logout: 'Disconnessione' }, // This is needed in LanguageSelector test
          nl: testMessages,
          pt: testMessages,
        }
        return {
          load: Promise.resolve(messagesByLocale),
          loaded: true,
          messagesByLocale,
        }
      })()
    : (() => {
        // This is a mutable const: it changes when the messages are loaded
        const loader: MessageLoader = {
          load: Promise.resolve({} as IntlMessages),
          loaded: false,
        }
        loader.load = import('@/intl/messages')
          .then((messagesByLocale) => {
            Object.assign(loader, { loaded: true, messagesByLocale })
            return messagesByLocale
          })
          .catch((e) => {
            Object.assign(loader, { loaded: true, messagesByLocale: {} })
            if (process.env.NODE_ENV === 'production') {
              Sentry.captureException(e, {
                fingerprint: ['load-translation-messages'],
                level: 'fatal',
              })
            } else {
              // eslint-disable-next-line no-console -- Only in dev & storybook & tests
              console.error(
                '%cI18N messages were not found. Run\n%ctask translations:sync%c\nto fetch them.',
                'font-size: 48px; font-family: serif',
                'font-size: 48px; font-family: monospace',
                'font-size: 48px; font-family: serif'
              )
            }
            return {} as IntlMessages
          })
        return loader
      })()

const dateFnsLocale: Record<Locale, LocaleDateFns> = {
  fr,
  en: enUS,
  de,
  es,
  it,
  nl,
  pt,
}

interface I18nContextData {
  locale: Locale
  setLocale: (locale: Locale) => void
  dateFnsLocale: LocaleDateFns
}

/**
 * Context establishing a locale and allowing to changing it in app
 */
const I18nContext = React.createContext<I18nContextData>({} as I18nContextData)

// /!\ This is mutable, and mutated in the provider when the locale or messages change.
// The values represent the current messages in use in the app.
// This is intended to correctly reflect the default messages in tests, and the localized messages in storybook.
// The values are only present in tests and Storybook interactions.
// The values are only present on startup in tests, where they are synchronous.
export const messages = {
  ...(messagesLoader.loaded ? messagesLoader.messagesByLocale.en : undefined),
} as typeof enMessages

/**
 * Provider of i18n context, setting up IntlProvider
 */
const I18nContextProvider: FunctionComponent<{ loading: ReactNode }> = ({ loading, children }) => {
  const [locale, setLocale] = useState<Locale>(formatLocaleIntl(getBrowserLocale()))
  const [messagesByLocale, setMessagesByLocale] = useState(
    messagesLoader.loaded ? messagesLoader.messagesByLocale : undefined
  )

  useEffect(() => {
    if (messagesLoader.loaded && messagesByLocale) {
      return
    }

    // Only start up when messages have loaded
    Promise.resolve(messagesLoader.load)
      .then(setMessagesByLocale)
      .catch(() => setMessagesByLocale({} as IntlMessages))
  }, [messagesByLocale])

  useEffect(() => {
    if (!messagesByLocale) {
      return
    }

    document.documentElement.lang = locale
    if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'storybook') {
      Object.assign(messages, messagesByLocale[formatLocaleIntl(locale)])
    }
  }, [locale, messagesByLocale])

  const contextValue = useMemo(
    () => ({
      locale,
      setLocale,
      dateFnsLocale: dateFnsLocale[locale],
    }),
    [locale, setLocale]
  )

  if (!messagesByLocale) {
    return <>{loading}</>
  }

  return (
    <I18nContext.Provider value={contextValue}>
      <IntlProvider
        messages={messagesByLocale[formatLocaleIntl(locale)]}
        locale={formatLocaleIntl(locale)}
        defaultLocale="en"
        onError={
          // eslint-disable-next-line no-nested-ternary -- Nesting is needed to tree-shake the development code properly
          process.env.NODE_ENV !== 'production'
            ? messagesByLocale[formatLocaleIntl(locale)]
              ? undefined
              : () => null
            : undefined
        }
      >
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  )
}

export default I18nContextProvider

export const useLocale = () => useContext(I18nContext)
