import { AxiosInstance } from 'axios'
import { Me } from '@/types'

export type PostLogin = { email: string; password: string; return_url?: string }

export type RedirectMe = { redirect: string }

export const postLoginGenerator =
  (client: AxiosInstance) =>
  async ({ email, password, return_url }: PostLogin) => {
    const { data } = await client.post<Me | RedirectMe>('v1/pos/login', {
      email,
      password,
      return_url,
    })
    return data
  }
