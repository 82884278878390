import axios from 'axios'
import { useQuery } from 'react-query'
import { getMe } from '@/apis/api'
import { handleError, parseAxiosError } from '@/utils'
import { CacheKeys } from '@/apis/hooks/CacheKeys'

export const useGetMeQuery = () =>
  useQuery(
    CacheKeys.Me,
    async ({ signal }) => {
      try {
        return await getMe(signal)
      } catch (error) {
        if (axios.isAxiosError(error) && [401, 403].includes(error.response?.status ?? 0)) {
          return undefined
        }
        throw parseAxiosError(error)
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      keepPreviousData: true,
      onError: handleError,
    }
  )
