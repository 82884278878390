import { PaymentStepDetail } from '@/components/PaymentsModal/TerminalSteps/Steps/PaymentStepDetail'
import { sendErrorToSentry } from '@/thirdParties/sentry'
import React, { useEffect, VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

type Props = {
  id: string
  step: string
}

export const UnknownStep: VoidFunctionComponent<Props> = ({ id, step }) => {
  useEffect(() => {
    sendErrorToSentry(new Error(`Unknown requirement step found : ${step}`), {
      level: 'error',
      extra: {
        id,
        step,
      },
      fingerprint: ['unknown-step', step],
    })
  }, [id, step])

  return (
    <PaymentStepDetail
      title={
        <FormattedMessage
          id="payment.detail.requirementStep.unknown"
          description="Title of the payment requirement step when we could not determine the current step of the payment"
          defaultMessage="Unknown step"
        />
      }
    />
  )
}
