import { PaymentContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/types'
import { PaymentCreationContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/usePaymentCreationContext'
import React, { FunctionComponent, useMemo, useState } from 'react'

export const PaymentCreationContextProvider: FunctionComponent = ({ children }) => {
  const [paymentCreationContext, setPaymentCreationContext] = useState(PaymentContext.newPayment)

  const contextValue = useMemo(
    () => ({
      paymentCreationContext,
      setPaymentCreationContext,
    }),
    [paymentCreationContext, setPaymentCreationContext]
  )

  return (
    <PaymentCreationContext.Provider value={contextValue}>
      {children}
    </PaymentCreationContext.Provider>
  )
}
