import { FeePlan } from '@/types'

export type FeePlanKey = `p${number}x-d+${number}-m+${number}`

export function feePlanKey({
  deferred_days,
  deferred_months,
  installments_count,
}: Pick<FeePlan, 'deferred_days' | 'deferred_months' | 'installments_count'>): FeePlanKey {
  return `p${installments_count}x-d+${deferred_days}-m+${deferred_months}`
}

export const isCredit = ({ installments_count }: { installments_count: number }) =>
  installments_count > 4

export const isDeferred = ({
  deferred_days,
  deferred_months,
}: Pick<FeePlan, 'deferred_days' | 'deferred_months'>) => deferred_days > 0 || deferred_months > 0
