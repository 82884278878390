import * as Sentry from '@sentry/browser'
import { PosError } from '@/utils'
import { CaptureContext } from '@sentry/types'

export const sendErrorToSentry = (error: unknown, context?: CaptureContext) => {
  if (error instanceof PosError) {
    Sentry.captureException(error, {
      ...context,
      level: error.severity,
      extra: error.data,
      tags: error.tags,
      fingerprint: error.fingerprint,
    })
  } else {
    Sentry.captureException(error, { level: 'error', ...context })
  }
}
