import {
  GatewayErrorCode,
  GatewayPaymentStatus,
  GatewayPaymentStep,
  GatewayStepCode,
} from '@/apis/adyenGateway'
import { Icon, IconName } from '@alma/react-components'
import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type UseTerminalStatusReturn = {
  isTerminalLoading: boolean
  headerToDisplay: ReactNode
  errorIconToDisplay: ReactNode
  errorToDisplay: ReactNode
  isError: boolean
  canResend: boolean
  currentStepErrorCode: GatewayErrorCode | undefined
  currentStep: false | GatewayPaymentStep
  isTechnicalError: boolean
}

export const TITLE_BY_STEP_CODE: Record<GatewayStepCode, ReactNode> = {
  gateway_connection: (
    <FormattedMessage
      id="payment.terminal.status.gateway_connection.title"
      defaultMessage="Connecting to the payment gateway"
      description="title of terminal payment step 'gateway_connection' in the payment detail modal"
    />
  ),
  terminal_ready: (
    <FormattedMessage
      id="payment.terminal.status.terminal_ready.title"
      defaultMessage="Connecting to payment terminal"
      description="title of terminal payment step 'terminal_ready' in the payment detail modal"
    />
  ),
  printer_ready: (
    <FormattedMessage
      id="payment.terminal.status.printer_ready.title"
      defaultMessage="Checking payment terminal printer"
      description="title of terminal payment step 'printer_ready' in the payment detail modal"
    />
  ),
  fee_plan_selection: (
    <FormattedMessage
      id="payment.terminal.status.fee_plan_selection.title"
      defaultMessage="User is selecting installment count"
      description="title of terminal payment step 'fee_plan_selection' in the payment detail modal"
    />
  ),
  validate_plan: (
    <FormattedMessage
      id="payment.terminal.status.validate_plan.title"
      defaultMessage="Validation and signature"
      description="title of terminal payment step 'validate_plan' in the payment detail modal"
    />
  ),
  tokenize_card: (
    <FormattedMessage
      id="payment.terminal.status.tokenize_card.title"
      defaultMessage="Insert card"
      description="title of terminal payment step 'tokenize_card' in the payment detail modal"
    />
  ),
  scoring: (
    <FormattedMessage
      id="payment.terminal.status.scoring.title"
      defaultMessage="Processing user info"
      description="title of terminal payment step 'scoring' in the payment detail modal"
    />
  ),
  payment: (
    <FormattedMessage
      id="payment.terminal.status.payment.title"
      defaultMessage="Payment"
      description="title of terminal payment step 'payment' in the payment detail modal"
    />
  ),
  print_client_receipt: (
    <FormattedMessage
      id="payment.terminal.status.print_client_receipt.title"
      defaultMessage="Printing customer receipt"
      description="title of terminal payment step 'print_client_receipt' in the payment detail modal"
    />
  ),
  print_merchant_receipt: (
    <FormattedMessage
      id="payment.terminal.status.print_merchant_receipt.title"
      defaultMessage="Printing merchant receipt"
      description="title of terminal payment step 'print_merchant_receipt' in the payment detail modal"
    />
  ),
}

// step code can be any string, so we need to handle the undefined case
export const titleByUnknown: Record<string, ReactNode | undefined> = TITLE_BY_STEP_CODE

// Errors to Sentry
export const technicalGatewayErrors = [
  'invalid_json' as const,
  'expecting_an_object' as const,
  'update_step_failure' as const,
  'terminal_not_found' as const,
  'payment_not_found' as const,
  'diagnosis_failure' as const,
]

export type TechnicalGatewayError = typeof technicalGatewayErrors[0]
export type UserGatewayError = Exclude<GatewayErrorCode, TechnicalGatewayError>

export type IconProps = {
  icon: IconName
  color: string
}

export const PaymentErrorMessage = (
  <FormattedMessage
    id="payment-terminal-status.header.payment.error"
    defaultMessage="Payment failed"
    description="Title in the payment popup when the payment is ongoing on the payment terminal"
  />
)
export const ErrorOccurredMessage = (
  <FormattedMessage
    id="payment-terminal-status.header.error"
    defaultMessage="Error"
    description="Title in the payment popup when the payment has failed at a terminal payment step"
  />
)

export const CurrentStepMessage = (
  <FormattedMessage
    id="payment-terminal-status.header.step"
    defaultMessage="Current step"
    description="Title in the payment popup when the payment is ongoing on the payment terminal"
  />
)

export const messageForUserErrors: Record<
  UserGatewayError,
  { icon: IconProps; message: ReactNode; header: ReactNode }
> = {
  session_timeout: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.session_timeout.message"
        defaultMessage="The user took too much time to complete the step. Please try again."
        description="Message displayed when the user has had no activity on the terminal for too long. The merchant will be invited to retry sending the payment on the terminal."
      />
    ),
  },
  could_not_connect_terminal: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.could_not_connect_terminal.message"
        defaultMessage="Terminal can not be reached. Check that the terminal is started and connected to the network, or select another terminal and try again."
        description="Error displayed to the merchant when the payment terminal they selected can not be reached, is busy, or is in maintenance."
      />
    ),
  },
  alma_rejected_payment: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.alma_rejected_payment.message"
        defaultMessage="Payment rejected, Alma cannot accept the financing file."
        description="Error message displayed when the financing file get rejected by Alma."
      />
    ),
  },
  canceled_payment: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.canceled_payment.message"
        defaultMessage="Customer cancelled the payment."
        description="Error displayed when the customer cancelled the payment."
      />
    ),
  },
  card_saving_error: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.card_saving_error.message"
        defaultMessage="Could not save the card information. Please try again."
        description="Error displayed when an error happened during saving the card information."
      />
    ),
  },
  card_tokenization_error: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.card_tokenization_error.message"
        defaultMessage="Card tokenization error. Please try again."
        description="Error displayed after card tokenization error."
      />
    ),
  },
  could_not_process_payment: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.could_not_process_payment.message"
        defaultMessage="Could not process payment. Please try again."
        description="Error displayed when a payment cannot be processed."
      />
    ),
  },
  no_eligible_plan: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.no_eligible_plan.message"
        defaultMessage="No possible payment plan."
        description="Error displayed to the merchant when there is no possible payment plan."
      />
    ),
  },
  payment_collection_failed: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.payment_collection_failed.message"
        defaultMessage="Payment collection failed."
        description="Error displayed when an issue happened during payment collection."
      />
    ),
  },
  processing_payment: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.processing_payment.message"
        defaultMessage="Error processing payment. Please try again."
        description="Error displayed when an issue happened during payment processing."
      />
    ),
  },
  schedule_config_error: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.schedule_config_error.message"
        defaultMessage="An error occurred while configuring the schedule."
        description="Error displayed to the merchant when an error occurred while configuring the schedule."
      />
    ),
  },
  schedule_not_validated: {
    header: PaymentErrorMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.schedule_not_validated.message"
        defaultMessage="The schedule has not been validated. Please try again."
        description="Error displayed to the merchant when the schedule has not been validated."
      />
    ),
  },
  terminal_has_no_printer: {
    header: ErrorOccurredMessage,
    icon: {
      icon: IconName.info,
      color: 'var(--alma-blue)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.terminal_has_no_printer.message"
        defaultMessage="Terminal has no printer."
        description="Error displayed to the merchant when the terminal has no printer."
      />
    ),
  },
  terminal_printer_error: {
    header: ErrorOccurredMessage,
    icon: {
      icon: IconName.close,
      color: 'var(--alma-red)',
    },
    message: (
      <FormattedMessage
        id="payment.terminal.error.terminal_printer_error.message"
        defaultMessage="Please check the printer."
        description="Error displayed to the merchant when an error happened with the printer."
      />
    ),
  },
}

export const useTerminalStatus = (
  terminalStatus: GatewayPaymentStatus | undefined
): UseTerminalStatusReturn => {
  const isTerminalLoading =
    terminalStatus?.payment?.status === 'ongoing' &&
    terminalStatus?.payment?.steps.every((step) =>
      ['pending', 'success', 'skip'].includes(step.status)
    )

  const currentStep =
    (terminalStatus?.payment?.status !== 'locked' &&
      terminalStatus?.payment?.steps.find((step) =>
        ['ongoing', 'failure'].includes(step.status)
      )) ??
    false

  const currentStepErrorCode = currentStep ? currentStep.error?.code : undefined
  const isTechnicalError = Boolean(
    currentStepErrorCode &&
      ((technicalGatewayErrors as GatewayErrorCode[]).includes(currentStepErrorCode) ||
        // Unhandled error codes should be sent to sentry and handled as technical errors
        !messageForUserErrors[currentStepErrorCode as UserGatewayError])
  )

  const canResend = !terminalStatus || terminalStatus.payment?.status === 'retry'
  const isError =
    !terminalStatus ||
    terminalStatus.payment?.status === 'failure' ||
    terminalStatus.payment?.status === 'locked' ||
    terminalStatus.payment?.status === 'retry' ||
    terminalStatus.connexion === 'unknown error'

  const userErrorCode = !isTechnicalError && (currentStepErrorCode as UserGatewayError)
  const errorToDisplay = userErrorCode
    ? messageForUserErrors[userErrorCode].message
    : ErrorOccurredMessage

  const errorIconToDisplay = userErrorCode ? (
    <Icon
      icon={messageForUserErrors[userErrorCode].icon.icon}
      color={messageForUserErrors[userErrorCode].icon.color}
    />
  ) : (
    <Icon icon="close" color="var(--alma-red)" />
  )

  const headerToDisplay = userErrorCode
    ? messageForUserErrors[userErrorCode].header
    : CurrentStepMessage

  return {
    isTerminalLoading,
    headerToDisplay,
    errorIconToDisplay,
    errorToDisplay,
    isError,
    canResend,
    currentStepErrorCode,
    currentStep,
    isTechnicalError,
  }
}
