import { cancelPayment } from '@/apis/api'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { getPaymentPollQueryKey } from '@/apis/hooks/queries/useGetPaymentPollQuery'
import { getPaymentQueryKey } from '@/apis/hooks/queries/useGetPaymentQuery'
import { useSafeMutation, UseSafeMutationOptions } from '@/apis/hooks/utils'
import { useQueryClient } from 'react-query'

export const useCancelPaymentMutation = (
  merchantId: string,
  paymentId?: string,
  options?: UseSafeMutationOptions
) => {
  const queryClient = useQueryClient()
  return useSafeMutation(
    [CacheKeys.PaymentCancel, paymentId],
    () => {
      if (!paymentId) throw new Error(`Payment ID is not defined`)
      return cancelPayment(paymentId)
    },
    {
      ...options,
      onSuccess: async (...props) => {
        await queryClient.invalidateQueries(getPaymentPollQueryKey(merchantId))
        await queryClient.invalidateQueries(getPaymentQueryKey(paymentId))
        if (options?.onSuccess) await options.onSuccess(...props)
      },
    }
  )
}
