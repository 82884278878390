/* istanbul ignore file */
// This file is only loading & executing Delighted snippet. It will always be mocked in test, we have to assume Delighted's code is functional.
// See https://app.delighted.com/docs/api/web

import { loadExternalService } from '@/utils/externalService'

const DELIGHTED_SNIPPET =
  '!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"rXrBeOrTquaOOGkq","delightedNps");'

declare global {
  interface SurveyOptions {
    email?: string
    merchantId?: string
    properties?: Record<string, unknown>
    forceDisplay?: boolean
  }
  interface Window {
    delightedNps: {
      survey: (options?: SurveyOptions) => void
    }
  }
}

/**
 * Load Delighted library using js snippet
 */
export function loadDelighted() {
  if (process.env.NODE_ENV !== 'production') {
    // To start Delighted in local (for testing), don't return here (and don't commit please)
    return Promise.resolve()
  }

  return loadExternalService({ snippet: DELIGHTED_SNIPPET })
}

/**
 * Display the survey if the user is eligible (see web configuration from Delighted dashboard)
 */
export function enableSurvey(locale: string, email = '', merchantId = '') {
  if (process.env.NODE_ENV !== 'production') return
  window.delightedNps.survey({
    email,
    properties: { locale, newPOS: true, merchantId },
  })
}
