import { useMe } from '@/components/AuthenticationManager'
import { useDisableField } from '@/components/Forms/disabledFields'
import s from '@/components/Forms/PaymentCreate/PurchaseForm/PurchaseForm.module.css'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import {
  amountIntervalErrorMessage,
  isInInterval,
  merchantCannotCreatePaymentMessage,
  requiredFieldError,
} from '@/components/Forms/validation'
import { eventPropsFromFeePlan } from '@/thirdParties/analytics'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { isP1X, FeePlan, Eligibility } from '@/types'
import { eurosToCents } from '@/utils'
import { setOnlyNumberValue } from '@/utils/setOnlyNumberValue'
import { TextField } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { UseQueryResult } from 'react-query/types/react'

export const AmountField: VoidFunctionComponent<
  TrackerProps & { feePlan: FeePlan | undefined; eligibility: UseQueryResult<Eligibility, unknown> }
> = ({ track, feePlan, eligibility }) => {
  const me = useMe()
  const intl = useIntl()
  const { register, setValue, resetField } = useFormContext<PaymentCreateFormValues>()
  const { errors, dirtyFields } = useFormState<PaymentCreateFormValues>()
  const { disableField } = useDisableField<PaymentCreateFormValues>()

  const required = requiredFieldError(intl)

  return (
    <TextField
      {...register('purchase.formattedAmountInEuros', {
        setValueAs: (value) => {
          const newValue = setOnlyNumberValue(value)
          if (value !== newValue) {
            setValue('purchase.formattedAmountInEuros', newValue)
          }
          return newValue
        },
        validate: {
          amountInAuthorizedInterval: (value) => {
            const amountInCents = eurosToCents(Number(value))
            if (
              me.merchant.customer_choose_installments &&
              !isInInterval(amountInCents, [
                me.merchant.minimum_purchase_amount,
                me.merchant.maximum_purchase_amount,
              ])
            ) {
              return amountIntervalErrorMessage(intl, [
                me.merchant.minimum_purchase_amount,
                me.merchant.maximum_purchase_amount,
              ])
            }
            return undefined
          },
          notEligible: () => {
            if (!me.merchant.can_pay_in) {
              return merchantCannotCreatePaymentMessage(intl)
            }
            if (feePlan && eligibility.data && !eligibility.data.eligible) {
              track('amount_error', eventPropsFromFeePlan(feePlan))
              return amountIntervalErrorMessage(
                intl,
                isP1X(feePlan)
                  ? [50, feePlan.max_purchase_amount]
                  : [
                      Math.max(feePlan.min_purchase_amount, me.merchant.minimum_purchase_amount),
                      Math.min(feePlan.max_purchase_amount, me.merchant.maximum_purchase_amount),
                    ]
              )
            }
            return undefined
          },
          positiveOnly: (value) =>
            value.length > 0 && Number(value) === 0
              ? intl.formatMessage({
                  id: 'home.purchase.error.positive',
                  description: 'Error on purchase amount when the value is 0',
                  defaultMessage: 'The amount must be positive',
                })
              : undefined,
        },
        required,
      })}
      isDirty={dirtyFields.purchase?.formattedAmountInEuros}
      onClearClick={() => resetField('purchase.formattedAmountInEuros')}
      id="purchase.formattedAmountInEuros"
      unit="€"
      type="text"
      inputMode="decimal"
      error={errors.purchase?.formattedAmountInEuros?.message}
      autoComplete="off"
      placeholder="1000"
      label={
        <FormattedMessage
          id="home.purchase.amount"
          defaultMessage="Purchase amount"
          description="This is the field in the payment form where you enter the purchase amount. The information of the min/max authorised will be displayed if the filled in amount is not in the authorised range."
        />
      }
      className={s.amount}
      disabled={disableField('purchase.formattedAmountInEuros')}
    />
  )
}
