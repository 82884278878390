import { DataType, LoggerProps, LogStatus } from '@/thirdParties/datadog/types'
import { sendErrorToSentry } from '@/thirdParties/sentry'
import { camelToSnakeCase } from '@/utils/string'
import { datadogLogs } from '@datadog/browser-logs'

export const useDatadogLogger: () => Record<LogStatus, (logProps: LoggerProps) => void> = () => {
  const sendToDataDog = ({ message, data, status }: LoggerProps & { status: LogStatus }) => {
    if (process.env.NODE_ENV !== 'production') {
      // To start Datadog in local (for testing), don't return here (and don't commit please)
      return
    }

    // We want to have an object with keys in snake_case, so we can not use data as is
    // Since data can be of type Record<string, unknown>, we can not know in advance the keys
    // So we need to create the object dynamically
    const formattedData = Object.fromEntries(
      Object.keys(data).map((dataKey) => [
        camelToSnakeCase(dataKey),
        data[dataKey as keyof DataType],
      ])
    )

    try {
      datadogLogs.logger[status](`${data.eventName}: ${message}`, {
        event_data: {
          pos: formattedData,
        },
      })
    } catch (e) {
      sendErrorToSentry(e, {
        level: 'debug',
        fingerprint: ['external-services-datadog-log'],
      })
    }
  }

  return {
    [LogStatus.debug]: (logProps) => sendToDataDog({ ...logProps, status: LogStatus.debug }),
    [LogStatus.info]: (logProps) => sendToDataDog({ ...logProps, status: LogStatus.info }),
    [LogStatus.warn]: (logProps) => sendToDataDog({ ...logProps, status: LogStatus.warn }),
    [LogStatus.error]: (logProps) => sendToDataDog({ ...logProps, status: LogStatus.error }),
  }
}
