import { LoadingIndicator } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import s from '@/components/AuthenticationManager/InitialAppLoading.module.css'

export const InitialAppLoadingIndicator: VoidFunctionComponent<{ className?: string }> = ({
  className,
}) => (
  <div className={s.fullscreen} data-testid="authentication-loading-indicator">
    <LoadingIndicator className={className} />
  </div>
)
