import { useGetPaymentPollQuery } from '@/apis/hooks'
import s from '@/components/PaymentAmountForTheDay/PaymentAmountForTheDay.module.css'
import { startDateFor } from '@/components/PaymentList/PaymentList'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { centsToEuros } from '@/utils'
import { sum } from '@/utils/arrays'
import { CardTemplateCollapsible, Header } from '@alma/react-components'
import React, { useState, VoidFunctionComponent } from 'react'
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl'

export const PaymentAmountForTheDay: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const today = startDateFor.day()

  const [collapsed, setCollapsed] = useState(true)
  const {
    isError,
    isLoading,
    data: payments,
  } = useGetPaymentPollQuery({ startDate: today }, { enabled: !collapsed })

  return (
    <CardTemplateCollapsible
      collapsed={collapsed}
      data-testid="recap-payment-collapsible"
      chevronColor="var(--alma-orange)"
      onCollapse={() => {
        if (collapsed) {
          track('payment_recap_open')
        } else {
          track('payment_recap_close')
        }
        setCollapsed(!collapsed)
      }}
      header={
        <Header size="sm">
          <FormattedMessage
            id="total.purchase.amount.for.today.title"
            defaultMessage="Today's performance ({date})"
            description="On the payment's followup page, there is an collapsible component to show data of validated payment for the day, this is the title of this collapsible component. The {date} variable corresponds to the Date of the current day formatted in the locale"
            values={{ date: <FormattedDate value={Date.now()} /> }}
          />
        </Header>
      }
      isLoading={isLoading}
      padding="lg"
    >
      {isError && (
        <div>
          <FormattedMessage
            id="total.purchase.amount.for.today.error"
            defaultMessage="We failed to get data for today's validated payments. Please try again later."
            description="On the payment's followup page, this error message is displayed in the collapsible when there is an error while trying to get data from validated payments of the day."
          />
        </div>
      )}
      {payments && (
        <div className={s.performanceContainer}>
          <div className={s.performanceRow}>
            <span>
              <FormattedMessage
                id="total.purchase.amount.for.today"
                defaultMessage="Total of validated payments:"
                description="On the payment's followup page, this is the label for the total purchase amount (excl. customer fees) of validated payments for the day. It is displayed inside the corresponding collapsible component."
              />
            </span>
            <span className={s.performanceValue}>
              {payments.length > 0 ? (
                <FormattedNumber
                  value={centsToEuros(
                    payments
                      .filter((payment) => payment.status === 'validated')
                      .map((payment) => payment.purchase_amount)
                      .reduce(sum, 0)
                  )}
                  style="currency"
                  currency="EUR"
                />
              ) : (
                '-'
              )}
            </span>
          </div>
          <div className={s.performanceRow}>
            <span>
              <FormattedMessage
                id="total.payments.for.today"
                defaultMessage="Number of validated payments:"
                description="On the payment's followup page, this is the label for the number of validated payments for the day. It is displayed inside the corresponding collapsible component."
              />
            </span>
            <span className={s.performanceValue}>
              {payments.length > 0
                ? payments.filter((payment) => payment.status === 'validated').length
                : '-'}
            </span>
          </div>
        </div>
      )}
    </CardTemplateCollapsible>
  )
}
