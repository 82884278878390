import { Payment } from '@/types'
import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export interface IPaymentFollowupContext {
  sortedPayments: Array<Payment>
  filteredPayments: Array<Payment>
  setFilteredPayments: Dispatch<SetStateAction<Array<Payment>>>
}

const initialPaymentFollowupContext = {
  sortedPayments: [],
  filteredPayments: [],
  setFilteredPayments: () => {
    throw new Error('Context not initialized')
  },
}
export const PaymentFollowupContext = createContext<IPaymentFollowupContext>(
  initialPaymentFollowupContext
)

export const usePaymentFollowupContext = () => useContext(PaymentFollowupContext)
