import { Me, Payment, PaymentOrigin } from '@/types'
import { centsToEuros, getPaymentStatusString } from '@/utils'
import { ExportableObject, objectToCsv } from '@/utils/exports/csv'
import { isDefined } from '@alma/react-components'
import { fromUnixTime } from 'date-fns'
import { IntlShape } from 'react-intl'

/**
 * List of known and common column headers when dealing with payments
 * These are explicit here for an easy automatic extraction
 */
function getKnownColumnsHeaders(intl: IntlShape) {
  return {
    id: intl.formatMessage({
      id: 'export.payments.id',
      defaultMessage: 'Identifier',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column of the payment IDs.',
    }),
    created: intl.formatMessage({
      id: 'export.payments.created',
      defaultMessage: 'Creation date',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column of the payment creation date.',
    }),
    amount: intl.formatMessage({
      id: 'export.payments.amount',
      defaultMessage: 'Total amount',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column of the payment total purchase amount.',
    }),
    installments: intl.formatMessage({
      id: 'export.payments.installments',
      defaultMessage: 'Installments',
      description:
        "Appears in the payments exports CSV file. Header.tsx title for the column of the payment's number of installments.",
    }),
    customer_fee: intl.formatMessage({
      id: 'export.payments.customer_fee',
      defaultMessage: 'Customer fees',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column with the total amount of fees paid by the customer.',
    }),
    merchant_fee: intl.formatMessage({
      id: 'export.payments.merchant_fees',
      defaultMessage: 'Merchant fees',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column with the total amount of fees paid by the merchant.',
    }),
    refund: intl.formatMessage({
      id: 'export.payments.refund',
      defaultMessage: 'Refunded amount',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column with the total amount that was refunded to the customer.',
    }),
    email: intl.formatMessage({
      id: 'export.payments.email',
      defaultMessage: "Customer's email",
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column with the email address of the customer.',
    }),
    country: intl.formatMessage({
      id: 'export.payments.country',
      defaultMessage: 'Country',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column with the total country of the merchant.',
    }),
    seller: intl.formatMessage({
      id: 'export.payments.seller',
      defaultMessage: 'Seller',
      description:
        "Appears in the payments exports CSV file. Header.tsx title for the column with the name of the merchant's user account that created the payment.",
    }),
    reference: intl.formatMessage({
      id: 'export.payments.reference',
      defaultMessage: 'Order reference',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column with the reference of the payment that was set by the seller.',
    }),
    comment: intl.formatMessage({
      id: 'export.payments.comment',
      defaultMessage: 'Comment',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column with a custom comment on the payment as set by the seller.',
    }),
    status: intl.formatMessage({
      id: 'export.payments.status',
      defaultMessage: 'Status',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column with current status of the payment.',
    }),
    origin: intl.formatMessage({
      id: 'export.payments.origin',
      defaultMessage: 'Method',
      description:
        'Appears in the payments exports CSV file. Header.tsx title for the column that explains how the payment is processed (paid by link, paid on the terminal...).',
    }),
  }
}

interface PaymentsToCsvOptions {
  intl?: IntlShape
  me?: Me
}

function originName(origin: PaymentOrigin) {
  switch (origin) {
    case PaymentOrigin.posDevice:
      return 'on_screen'
    case PaymentOrigin.posLink:
      return 'by_link'
    case PaymentOrigin.posSms:
      return 'by_sms'
    case PaymentOrigin.posTerminal:
      return 'terminal'
    default:
      return 'online'
  }
}

// We want to display in CSV only "clean custom_data". That means if the customer_data value is an
// object, we don't want to display it in the CSV because it'll render "[Object, Object]" anyway.
// We only want to keep strings, number or boolean for custom_data
type CleanCustomDataValue = string | number | boolean
export const onlyStringOrNumberCustomData = (
  customData: Record<string, unknown>
): Record<string, CleanCustomDataValue> => {
  const isCleanData = (value: unknown): value is boolean | string | number =>
    typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean'

  // Prepare an object with the correct type
  const cleanData: Record<string, CleanCustomDataValue> = {}

  Object.keys(customData).reduce((result, customDataKey) => {
    // Get the value from custom data by key
    const value = customData[customDataKey]
    // If the value IS a string OR a number OR a boolean,
    if (isCleanData(value)) {
      // Add the couple key/value to cleanData object
      // That way we remove all custom_data keys/values when the value is not strings, number or boolean
      cleanData[customDataKey] = value
    }
    return result
  }, cleanData)

  return cleanData
}

/** Map a payment to a CSV exportable object */
export function mapPayment(payment: Payment, intl?: IntlShape) {
  const cleanData: Record<string, CleanCustomDataValue> = payment.custom_data
    ? onlyStringOrNumberCustomData(payment.custom_data)
    : {}
  return {
    id: payment.id,
    created: fromUnixTime(payment.created).toISOString(),
    amount: centsToEuros(payment.purchase_amount),
    installments:
      payment.requirementStep === 'payment_not_configured'
        ? intl?.formatMessage({
            id: 'export.payments.installments_count_not_ready',
            defaultMessage: 'not selected',
            description:
              'Text that will be displayed instead of the number of installments when no installment count has been selected yet. This appears in a CSV export of payment',
          })
        : payment.installments_count,
    customer_fee: centsToEuros(payment.customer_fee),
    merchant_fee: isDefined(payment.merchant_target_fee)
      ? centsToEuros(payment.merchant_target_fee)
      : undefined,
    refund: centsToEuros(payment.refunds.reduce((total, refund) => total + refund.amount, 0)),
    email: payment.customer.email,
    country: payment.transaction_country,
    seller: payment.seller?.name,
    reference: payment.orders[0]?.merchant_reference,
    comment: payment.orders[0]?.comment,
    status: intl ? getPaymentStatusString(payment.status, intl) : payment.status,
    origin: originName(payment.origin),
    ...cleanData,
  }
}

/**
 * Export a list of payments as a CSV file content
 * Provide intl in options parameter to have localized column headers
 */
export function paymentsToCsv(payments: Array<Payment>, options?: PaymentsToCsvOptions): string {
  /** All the payments formatted for the CSV */
  const csvPayments: ExportableObject = []
  /** Headers to apply to the CSV */
  const headers: Record<string, string> = {}

  const knownHeaders = options?.intl
    ? getKnownColumnsHeaders(options.intl)
    : ({} as Record<string, string>)

  payments.forEach((payment) => {
    const csvPayment = mapPayment(payment, options?.intl)
    csvPayments.push(csvPayment)

    // This is used to keep track of all the possible headers
    // Due to the use of custom fields there is no guarantee that all the payments will have the same columns
    Object.keys(csvPayment).forEach((key) => {
      if (!headers[key]) {
        const customLabelForKey = options?.me?.merchant.custom_data_template.find(
          (template) => template.key === key
        )?.label
        headers[key] = knownHeaders[key] ?? customLabelForKey ?? key
      }
    })
  })
  return objectToCsv(csvPayments, { headers })
}
