import React, { VoidFunctionComponent } from 'react'

import { FormattedMessage } from 'react-intl'
import { SimulatedPaymentItem } from '@/components/Pages/PaymentSimulatedPage/SimulatedPaymentItem'
import { SimulatedPayment } from '@/types'

type Props = {
  simulatedPayments: SimulatedPayment[]
}

export const PaymentSimulatedList: VoidFunctionComponent<Props> = ({ simulatedPayments }) => (
  <>
    {simulatedPayments?.length ? (
      simulatedPayments.map((simulatedPayment) => (
        <SimulatedPaymentItem
          key={`${simulatedPayment.created}`}
          simulatedPayment={simulatedPayment}
        />
      ))
    ) : (
      <FormattedMessage
        id="message.no.simulation.to.display"
        defaultMessage="There was no simulation made during the last 60 minutes."
        description="This is a message displayed if no simulated payment to display"
      />
    )}
  </>
)
