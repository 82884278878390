import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import React, { FunctionComponent } from 'react'

export const List: FunctionComponent = ({ children }) => <ul className={s.stepList}>{children}</ul>

export const ListItem: FunctionComponent = ({ children }) => (
  <li>
    <div className={s.listDotSpan} />
    {children}
  </li>
)
