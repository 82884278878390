import { postPaymentUpdate } from '@/apis/api'
import { createPaymentUpdatePayload } from '@/apis/createPaymentPayload'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { UseCreatePaymentMutationParameters } from '@/apis/hooks/mutations/useCreatePaymentMutation'
import { useSafeMutation, UseSafeMutationOptions } from '@/apis/hooks/utils'
import { hydratePayment } from '@/apis/utils'
import { useMe } from '@/components/AuthenticationManager'
import { Payment } from '@/types'
import { useIntl } from 'react-intl'

export const useUpdatePaymentMutation = (
  originalPayment?: Payment,
  options?: UseSafeMutationOptions<Payment, unknown, UseCreatePaymentMutationParameters>
) => {
  const me = useMe()
  const intl = useIntl()

  return useSafeMutation(
    [CacheKeys.PaymentUpdate, originalPayment?.id],
    async ({ values, options: paymentOriginOptions }: UseCreatePaymentMutationParameters) => {
      if (!originalPayment) {
        throw new Error('8bd67340: Did not wait for payment loading before mutating')
      }

      const modifiedPayment = await postPaymentUpdate(
        originalPayment.id,
        createPaymentUpdatePayload({
          ...paymentOriginOptions,
          values,
          originalPayment,
          customerChooseInstallments: me.merchant.customer_choose_installments,
          merchantCanOfferFees: me.can_disable_customer_fees,
        })
      )

      return hydratePayment(modifiedPayment)
    },
    {
      errorParser: () => ({
        userMessage: intl.formatMessage({
          id: 'toast.update.payment.error',
          defaultMessage: 'Failed to update payment',
          description: `This is a message displayed when the seller tried to update an externally created payment in the payment form and it didn't work for technical reasons.`,
        }),
      }),
      ...options,
    }
  )
}
