import { makeWebsocketClient } from '@/apis/websocketClient'
import { config } from '@/config'
import { Terminal } from '@/types'
import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

/**
 * Codes of the steps contained in the payload of the Adyen Gateway.
 *
 * They are used in `GatewayPaymentStep::code`.
 *
 * These codes are used as keys for i18n instead of the titles sent by the Adyen Gateway
 */
export type GatewayStepCode =
  | 'gateway_connection'
  | 'terminal_ready'
  | 'printer_ready'
  | 'fee_plan_selection'
  | 'validate_plan'
  | 'tokenize_card'
  | 'scoring'
  | 'payment'
  | 'print_client_receipt'
  | 'print_merchant_receipt'

/**
 * Codes of the possible errors send by the gateway.
 *
 * They are used in `GatewayPaymentStep::error?.code`.
 *
 * This mixes technical and business codes, so these codes undergo a special treatment before being used:
 * technical codes are sent as-is to Sentry, business codes are used for i18n.
 *
 * The choice for this is made in the React Component.
 */
export type GatewayErrorCode =
  | 'invalid_json'
  | 'expecting_an_object'
  | 'update_step_failure'
  | 'session_timeout'
  | 'terminal_not_found'
  | 'payment_not_found'
  | 'diagnosis_failure'
  | 'could_not_connect_terminal'
  | 'terminal_has_no_printer'
  | 'terminal_printer_error'
  | 'no_eligible_plan'
  | 'canceled_payment'
  | 'schedule_not_validated'
  | 'schedule_config_error'
  | 'card_saving_error'
  | 'card_tokenization_error'
  | 'alma_rejected_payment'
  | 'could_not_process_payment'
  | 'processing_payment'
  | 'payment_collection_failed'

/**
 * An error happening in the gateway. Only the code and the details may be of importance.
 */
export type GatewayError = { code: GatewayErrorCode; message: string; details?: unknown }

/**
 * One step in the payment process.
 *
 * We usually only need one at a time (the one with the “ongoing” or “failure” status).
 */
export type GatewayPaymentStep = {
  /**
   * Computed by the gateway, sometimes i18n'd, sometimes not (e.g. initialization steps).
   *
   * We only use the value sent by the gateway when we don't know the code.
   * As of 2022-08-22 all possible step codes are known from the client.
   */
  title: string
  /** Explicitly specifying the known step codes. Adding 'string' because on the server the step code is _not_ bounded by an enum. */
  code: GatewayStepCode | string
  /**
   * Current step status.
   *
   * Implicit rules of these statuses:
   * - the order is: 'pending' -> 'ongoing' -> 'failure' / 'skip' / 'success'
   * - only one step in the list is 'ongoing' or 'failure' at a time (the “current step”)
   * - all steps before the current step in the list are either 'success' or 'skip'
   * - all steps after the current step in the list are 'pending'
   *
   * We are only ever interested in the current step and its status.
   */
  status: 'ongoing' | 'pending' | 'success' | 'failure' | 'skip'
  /**
   * This will be non-null only when status === 'failure'.
   *
   * In the rare cases we are interested in sharing the error reason with the user, we use i18n on the `code` to do so.
   * The message is aways ignored (but sometimes sent to Sentry with the payload),
   * although its i18n can include variables that are not repeated in the details.
   *
   * The details are not used client-side but can be sent to Sentry for analysis.
   */
  error: GatewayError | null
}

export type GatewayPaymentStatus = {
  /**
   * `undefined` when establishing the connexion before the first message comes in.
   */
  payment?:
    | {
        /**
         * We will do some magic behavior depending on those statuses.
         *
         * The actual behavior also depends on step statuses and connexion status,
         * the list of possible behaviors can be found in the view (where it is defined).
         */
        status: 'ongoing' | 'success' | 'retry' | 'failure'
        /** Most steps are present from the get-go, but some can be added later */
        steps: GatewayPaymentStep[]
      }
    | { status: 'locked' }
  /**
   * This mixes server-side connexion termination codes and client-side status.
   * These are all technical statuses
   */
  connexion: /**
   * Client-side: we are establishing the connexion, server didn't answer yet.
   */
  | 'starting'
    /**
     * Connexion is live (this is the status you want to be in most of the time)
     */
    | 'live'
    /**
     * Client-side: connexion was terminated, we don't know why (e.g. onError handler of the WS connexion)
     */
    | 'unknown error'
    /**
     * Server-side: Server terminated the connexion because the terminal was not found (e.g. bad terminal ID).
     *
     * This should not happen because we are using the terminal IDs from the /me payload.
     */
    | 'terminal not found'
    /**
     * Server-side: Server terminated the connexion because the payment was not found (e.g. bad payment ID).
     *
     * This should not happen because we actually just created this payment.
     */
    | 'payment not found'
    /**
     * Server-side: Server terminated the connexion because the session expired.
     *
     * This should not happen because the call we sent to create the payment (or read it) maintains the server-side session active.
     */
    | 'session expired'
    /**
     * Server-side: Server terminated the connexion because it couldn't communicate with the terminal.
     *
     * Server sends a complete 'failure' or 'retry' payload before closing the connexion with this code to indicate what we can do.
     */
    | 'could not lock terminal'
    /**
     * Client-side: Server terminated the connexion, but the code they used is unknown or they didn't use a code.
     *
     * Usually this happens when the process is complete (e.g. payload is 'success'),
     * server will update the payload before closing the connexion.
     */
    | 'connexion lost'
}

/**
 * State of the communication with Adyen Gateway, and possible actions on it, provided by the provider below.
 *
 * The provider maintains a dict of the running processes by payment ID,
 * as it is unclear whether a user will be able to initiate several payments at the same time (shared PoS in a shop ?).
 * Note that this is a client-side map only, as it is not how the gateway itself sorts connexions and processes (see the definition of `payOnTerminal`).
 * In practice in the app we use this map to allow the pages to use their well-known `paymentId` to act on the process.
 */
type AdyenGatewayContext = {
  /**
   * Initiates a payment on terminal with the Adyen Gateway.
   *
   * This will actually open a fresh WS connexion with the gateway,
   * as gateway connexions are "by payment process"
   * (not one single connexion, not one connexion by payment ID, not one connexion by terminal).
   *
   * The gateway does not provide any way of reopening a connexion to a running process.
   * If the connexion is lost the gateway will kill the process ion the terminal,
   * or we will reopen a new one anf forcibly unlock the terminal.
   *
   * Calling this method if a process is already in progress in the Adyen Gateway will start a new process _without_ bothering to clear anything.
   *
   * @param paymentId The ID of the previously created payment (using origin === 'pos_terminal').
   *                  Will be passed to the gateway to start the process,
   *                  and will be used as the dict key for other methods and status reading.
   * @param terminal The terminal selected from the UI (we are interested in both its mandatory properties)
   */
  payOnTerminal(paymentId: string, terminal: Terminal): void
  /**
   * When the status of the gateway payment is `locked`, call this function to tell the gateway to "unlock" the terminal.
   *
   * This is not a Adyen thing, but a Gateway internal cache invalidation thing.
   *
   * Calling this method on a non-locked process will send the message anyways (it will be ignored by the Gateway).
   *
   * Calling this method on a closed or non-existing connexion will do nothing, silently.
   *
   * @param paymentId The ID of the payment (as a key to the process dict)
   */
  forceUnlockTerminal(paymentId: string): void
  /**
   * Close the connexion to the gateway for this payment.
   *
   * This also clears the process info in the internal dict.
   *
   * Calling this method on an ongoing process will not send anything to the gateway.
   * At some point the gateway will see that we bailed, and will cancel the payment on terminal
   * (the gateway does not provide a way to "cancel" the process).
   *
   * Calling this method on a closed or non-existing connexion will do nothing, silently.
   *
   * @param paymentId The ID of the payment (as a key to the process dict)
   */
  terminatePaymentOnTerminal(paymentId: string): void
  /**
   * The process dict, maps the payment ID used in `payOnTerminal` with the gateway status.
   */
  statusByPaymentId: Partial<Record<string, GatewayPaymentStatus>>
}

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare -- Context and its type are so linked it would hurt to not call them the same
const AdyenGatewayContext = createContext<AdyenGatewayContext>({
  payOnTerminal: () => undefined,
  forceUnlockTerminal: () => undefined,
  terminatePaymentOnTerminal: () => undefined,
  statusByPaymentId: {},
})

const SERVER_CLOSE_CODES: { code: number; reason: GatewayPaymentStatus['connexion'] }[] = [
  { code: 4401, reason: 'terminal not found' },
  { code: 4403, reason: 'session expired' },
  { code: 4404, reason: 'payment not found' },
  { code: 1013, reason: 'could not lock terminal' },
]

export const AdyenGatewayProvider: FunctionComponent = ({ children }) => {
  const [statusByPaymentId, setStatusByPaymentId] = useState<
    AdyenGatewayContext['statusByPaymentId']
  >({})
  const webSocketsByPaymentId = useRef<Partial<Record<string, WebSocket>>>({})

  const payOnTerminal = useCallback((paymentId: string, terminal: Terminal) => {
    const url = new URL(`${config.ADYEN_GATEWAY_WS_URL}/terminals/handle-payment`)

    url.searchParams.append('payment_id', paymentId)
    url.searchParams.append('terminal_provider', terminal.provider)
    url.searchParams.append('terminal_provider_ref', terminal.provider_reference)

    setStatusByPaymentId((statuses) => ({
      ...statuses,
      [paymentId]: {
        connexion: 'starting',
      },
    }))

    webSocketsByPaymentId.current[paymentId] = makeWebsocketClient(url, {
      onMessage: (event) => {
        setStatusByPaymentId((statuses) => ({
          ...statuses,
          [paymentId]: {
            payment: JSON.parse(event.data), // FIXME validate this payload
            connexion: 'live',
          },
        }))
      },
      onClose: (event) => {
        setStatusByPaymentId(({ [paymentId]: statusForPayment, ...statuses }) => ({
          ...statuses,
          [paymentId]: {
            ...statusForPayment,
            connexion:
              SERVER_CLOSE_CODES.find(({ code }) => code === event.code)?.reason ??
              'connexion lost',
          },
        }))
      },
      onError: () => {
        setStatusByPaymentId(({ [paymentId]: statusForPayment, ...statuses }) => ({
          ...statuses,
          [paymentId]: {
            ...statusForPayment,
            connexion: 'unknown error',
          },
        }))
      },
    })
  }, [])

  const forceUnlockTerminal = useCallback((paymentId: string) => {
    webSocketsByPaymentId.current[paymentId]?.send(JSON.stringify({ action: 'force' }))
  }, [])

  const terminatePaymentOnTerminal = useCallback((paymentId: string) => {
    webSocketsByPaymentId.current[paymentId]?.close()

    setStatusByPaymentId(({ [paymentId]: _statusForPayment, ...statuses }) => statuses)
    delete webSocketsByPaymentId.current[paymentId]
  }, [])

  const contextValue = useMemo(
    () => ({
      payOnTerminal,
      forceUnlockTerminal,
      terminatePaymentOnTerminal,
      statusByPaymentId,
    }),
    [forceUnlockTerminal, payOnTerminal, statusByPaymentId, terminatePaymentOnTerminal]
  )

  return (
    <AdyenGatewayContext.Provider value={contextValue}>{children}</AdyenGatewayContext.Provider>
  )
}

/**
 * Allow starting a payment on terminal.
 */
export function usePayOnTerminal() {
  return useContext(AdyenGatewayContext).payOnTerminal
}

/**
 * Monitor one single process
 *
 * This exposes the same functions and status than `AdyenGatewayContext`, pre-configured for one specific payment.
 */
export type TerminalPayment = {
  /**
   * The current status in the gateway.
   *
   * May be undefined if no terminal payment process is ongoing.
   */
  terminalStatus: GatewayPaymentStatus | undefined
  /**
   * When the status of the gateway payment is `locked`, call this function to tell the gateway to "unlock" the terminal.
   *
   * Calling this method on a non-locked process will send the message anyways (it will be ignored by the Gateway).
   *
   * Calling this method on a closed or non-existing connexion will do nothing, silently.
   */
  forceUnlockTerminal: () => void
  /**
   * Close the connexion to the gateway for this payment.
   *
   * This also sets the status back to `undefined`.
   *
   * Calling this method on an ongoing process will not send anything to the gateway.
   * At some point the gateway will see that we bailed, and will cancel the payment on terminal
   *
   * Calling this method on a closed or non-existing connexion will do nothing, silently.
   */
  terminatePaymentOnTerminal: () => void
}

/**
 * Monitor and act on an ongoing payment on terminal
 *
 * @param paymentId The ID of the payment, that was used in `usePayOnTerminal` to start the payment.
 */
export function useTerminalPayment(paymentId?: string): TerminalPayment {
  const {
    statusByPaymentId: { [paymentId ?? '']: terminalStatus },
    forceUnlockTerminal,
    terminatePaymentOnTerminal,
  } = useContext(AdyenGatewayContext)

  const forceUnlockTerminalForPayment = useCallback(
    () => paymentId && forceUnlockTerminal(paymentId),
    [forceUnlockTerminal, paymentId]
  )

  const terminatePaymentOnTerminalForPayment = useCallback(
    () => paymentId && terminatePaymentOnTerminal(paymentId),
    [paymentId, terminatePaymentOnTerminal]
  )

  return useMemo(
    () => ({
      terminalStatus: paymentId ? terminalStatus : undefined,
      forceUnlockTerminal: forceUnlockTerminalForPayment,
      terminatePaymentOnTerminal: terminatePaymentOnTerminalForPayment,
    }),
    [forceUnlockTerminalForPayment, paymentId, terminalStatus, terminatePaymentOnTerminalForPayment]
  )
}

/**
 * Used in tests, do not use in app code.
 *
 * This allows us to create/manipulate the context as we see fit to verify functions behavior.
 *
 * Concept and naming of the '__UNSAFE__' context prefix are taken from React Router.
 */
export { AdyenGatewayContext as __UNSAFE__AdyenGatewayContext }
