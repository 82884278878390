import { BoundaryType } from '@/components/AppErrorPage/BoundaryType'
import React, { ReactNode, VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export type KnownError = {
  isKnown(error: Error): boolean
  shouldReport: boolean
  message: Record<BoundaryType, VoidFunctionComponent<{ error: Error }>>
}

const DEFAULT_KNOWN_ERROR: KnownError = {
  // Default known error
  isKnown() {
    return true
  },
  shouldReport: true,
  message: {
    global: () => (
      <>
        <p>We encountered a problem.</p>
        <p>Please refresh the page.</p>
      </>
    ),
    translated: () => (
      <FormattedMessage
        id="global-error.text"
        defaultMessage="<p>We have encountered a problem.</p><p>We invite you to refresh the page.</p>"
        description="Content of the general error page"
        values={{ p: (content: ReactNode) => <p>{content}</p> }}
      />
    ),
  },
}
const knownErrors: KnownError[] = [
  {
    isKnown(error: Error) {
      return error.message.includes(
        "Failed to read the 'localStorage' property from 'Window': Access is denied for this document"
      )
    },
    shouldReport: false,
    message: {
      translated: () => (
        <FormattedMessage
          id="app-error-enable-cookies"
          defaultMessage="<p>Alma PoS needs access to cookies and browser storage to function properly.</p><p>Please re-enable cookies for {site} in privacy settings.</p>"
          description="Explanation message displayed when the user has disabled storage access in Chrome Privacy Settings. Variables: {site}: the URL to PoS if the user only wants to add an exception to their policy, <p></p> to wrap paragraphs."
          values={{
            site: process.env.NODE_ENV === 'storybook' ? 'https://shop.almapay.com' : window.origin,
            p: (text: ReactNode) => <p>{text}</p>,
          }}
        />
      ),
      global: () => (
        <>
          <p>Alma PoS needs access to cookies and browser storage to function properly.</p>
          <p>
            Please re-enable cookies in privacy settings for $
            {process.env.NODE_ENV === 'storybook' ? 'https://shop.almapay.com' : window.origin}.
          </p>
        </>
      ),
    },
  },
  DEFAULT_KNOWN_ERROR,
]

export function knownError(error: Error) {
  return knownErrors.find(({ isKnown }) => isKnown(error)) ?? DEFAULT_KNOWN_ERROR
}
