import { PurchaseForm } from '@/components/Forms/PaymentCreate'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { CardTemplate, Header } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const Purchase: VoidFunctionComponent<TrackerProps> = ({ track }) => (
  <CardTemplate
    padding="lg"
    header={
      <Header size="sm">
        <FormattedMessage
          id="home.purchase.title"
          defaultMessage="Your sale"
          description="This is the title of the part of the payment form where the purchase amount and feeplan are filled"
        />
      </Header>
    }
  >
    <PurchaseForm track={track} />
  </CardTemplate>
)
