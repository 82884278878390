import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { IDCheckModal } from '@/components/PaymentsModal/TerminalSteps/Steps'
import s from '@/components/SelectTerminal/SelectTerminal.module.css'
import { SelectTerminalDropdown } from '@/components/SelectTerminal/SelectTerminalDropdown'
import { isTerminal, useLastTerminal } from '@/components/SelectTerminal/useLastTerminal'
import { useTerminalValidation } from '@/components/SelectTerminal/useTerminalValidation'
import { Button, LegendMessage } from '@alma/react-components'
import React, { BaseSyntheticEvent, useMemo, useState, VoidFunctionComponent } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { ActiveTags, hasFeatureTag } from '@/types'
import { useMe } from '@/components/AuthenticationManager'

export const useDefaultTerminalFormValues = ():
  | Pick<PaymentCreateFormValues, 'terminal'>
  | undefined => {
  const [lastTerminal] = useLastTerminal()
  return useMemo(
    () => (isTerminal(lastTerminal) ? { terminal: { selected: lastTerminal } } : undefined),
    [lastTerminal]
  )
}
interface SelectTerminalProp {
  onSubmit: (e: BaseSyntheticEvent) => void
  mdmShouldNotDisplayEPT: boolean
}

export const SelectTerminal: VoidFunctionComponent<SelectTerminalProp> = ({
  onSubmit,
  mdmShouldNotDisplayEPT,
}) => {
  const { watch, trigger } = useFormContext<PaymentCreateFormValues>()
  const { isSubmitting } = useFormState<PaymentCreateFormValues>()

  const [feePlan, amount, selectedTerminal, filledBy] = watch([
    'purchase.feePlan',
    'purchase.formattedAmountInEuros',
    'terminal.selected',
    'customer.filledBy',
  ])

  const terminalValidationWarning = useTerminalValidation(feePlan)

  const me = useMe()
  const isUserMdm = hasFeatureTag(ActiveTags.mdm, me)
  const shouldAskForID = useMemo(() => Number(amount) >= 1000, [amount]) && !isUserMdm
  const [isOpenIDModal, setIsOpenModal] = useState(false)

  const onTerminalSelect = async (e: BaseSyntheticEvent) => {
    if (shouldAskForID) {
      // If ID is needed, we first check if the form is correctly filled
      const isFormValid = await trigger()
      if (isFormValid) {
        // If the form is valid, we open the modal to check ID
        setIsOpenModal(true)
      }
    } else {
      // If ID is not needed, we can submit form directly
      onSubmit(e)
    }
  }

  if (mdmShouldNotDisplayEPT) {
    return null
  }

  return (
    <div className={s.selectTerminal}>
      <SelectTerminalDropdown
        name="terminal.selected"
        disabled={Boolean(terminalValidationWarning)}
      />
      <Button
        block
        type={shouldAskForID ? 'button' : 'submit'}
        color="primary"
        disabled={
          (!feePlan?.installments_count && !me.merchant.customer_choose_installments) ||
          !selectedTerminal ||
          Boolean(terminalValidationWarning) ||
          isSubmitting ||
          filledBy === 'customer'
        }
        onClick={onTerminalSelect}
      >
        <FormattedMessage
          id="home.submit.ept.pay"
          defaultMessage="Pay on this terminal"
          description="When paying from an EPT, button to create the payment through the terminal"
        />
      </Button>
      {Boolean(terminalValidationWarning) && (
        <LegendMessage size="lg">{terminalValidationWarning}</LegendMessage>
      )}
      <IDCheckModal
        isOpen={isOpenIDModal}
        onClose={() => setIsOpenModal(false)}
        onSubmit={onSubmit}
      />
    </div>
  )
}
