import { useMe } from '@/components/AuthenticationManager'
import { PaymentListFormValues } from '@/components/Forms/PaymentList/types'
import { usePaymentFollowupContext } from '@/components/Pages'
import { startDateFor } from '@/components/PaymentList/PaymentList'
import s from '@/components/PaymentsExport/PaymentsExport.module.css'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { paymentsToCsv, triggerFileDownload } from '@/utils'
import { Button, Card, Header } from '@alma/react-components'
import { format } from 'date-fns'
import React, { useCallback, VoidFunctionComponent } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

const FILE_NAME_DATE_FORMAT = 'dd_MM_yyyy'

/** Card that allows the user to export the payments that have been filtered in the payment list as CSV */
export const PaymentsExport: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const { watch } = useFormContext<PaymentListFormValues>()

  const { filteredPayments } = usePaymentFollowupContext()
  const me = useMe()
  const period = watch('period')
  const intl = useIntl()

  /**
   * Generates the CSV file of the filtered payment list
   * and triggers a "download" of the file to the user's disk
   */
  const exportPayments = useCallback(() => {
    track('generate_report')

    const csvString = paymentsToCsv(filteredPayments, { me, intl })

    // Calculate file name
    // merchant_name-21_01_2022 (Daily)
    // merchant_name-21_01_2022-06_02_2022 (Period)
    const today = new Date()
    const merchantName = me.merchant.name.toLowerCase().replace(/ /g, '_')
    const endDate = format(today, FILE_NAME_DATE_FORMAT)
    const startDate = format(startDateFor[period](), FILE_NAME_DATE_FORMAT)
    const fileTitle =
      startDate === endDate
        ? `${merchantName}-${endDate}`
        : `${merchantName}-${startDate}-${endDate}`

    triggerFileDownload(csvString, fileTitle, { type: 'text/csv' })
  }, [filteredPayments, period, me, intl, track])

  return (
    <Card data-testid="paymentsExport" className={s.container} padding="lg">
      <Header size="sm">
        <FormattedMessage
          id="payments.export.title"
          defaultMessage="Export"
          description={`This is the title of the right-hand section dedicated to payment reports on the payments page that opens when clicking on the button "Consult payments" on the right-hand corner of POS page. This section contains a button to generate reports in CSV format.`}
        />
      </Header>
      <Button onClick={exportPayments} block color="secondary" data-testid="button-export-csv">
        <FormattedMessage
          id="payments.report.generate"
          defaultMessage="Export list (.CSV)"
          description={`Feature available on the page that opens when clicking on the button "Consult payments" on the right-hand corner of POS page. In this payments page, you have a button to generate a CSV export of all payments. This translation string is about the button label. Clicking on the button makes the button become grey while generating the CSV file.`}
        />
      </Button>
    </Card>
  )
}
