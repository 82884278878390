import { config } from '@/config'

import { sendErrorToSentry } from '@/thirdParties/sentry/sendErrorToSentry'
import { datadogLogs } from '@datadog/browser-logs'

const clientToken = config.DATADOG_CLIENT_TOKEN

export const initDatadog = () => {
  if (process.env.NODE_ENV !== 'production' || !clientToken) {
    // To start Datadog in local (for testing), don't return here (and don't commit please)
    // Don't forget to do it also in sendLogToDatadog.ts
    return
  }

  try {
    // Init Datadog
    datadogLogs.init({
      clientToken,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: 'pos',
      env: config.ENV,
    })
  } catch (e) {
    sendErrorToSentry(e, {
      level: 'debug',
      fingerprint: ['external-services-datadog-init'],
    })
  }
}
