import { useMediaQuery } from 'react-responsive'

export type Breakpoint = 'sm' | 'md' | 'lg'

const breakpointsValues: { [key in Breakpoint]: number } = {
  sm: 320,
  md: 769,
  lg: 1025,
}

export const useScreenBreakpoint = (bp: Breakpoint) =>
  useMediaQuery({ query: `(min-width: ${breakpointsValues[bp]}px)` })
