import { useMe } from '@/components/AuthenticationManager'
import { useDisableField } from '@/components/Forms/disabledFields'
import s from '@/components/Forms/PaymentCreate/CustomerInfoForm/CustomerInfoForm.module.css'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { maxLengthFieldError, requiredFieldError } from '@/components/Forms/validation'
import { ActiveTags, hasFeatureTag } from '@/types'
import { TextField } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { DeepPartial, useFormContext, useFormState } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

export const DEFAULT_ORDER_FORM_VALUES: Pick<DeepPartial<PaymentCreateFormValues>, 'order'> = {
  order: {
    reference: '',
    comment: '',
  },
}

export const OrderForm: VoidFunctionComponent = () => {
  const me = useMe()
  const intl = useIntl()
  const { register, resetField } = useFormContext<PaymentCreateFormValues>()
  const { errors, dirtyFields } = useFormState<PaymentCreateFormValues>()
  const { disableField } = useDisableField<PaymentCreateFormValues>()

  const required = requiredFieldError(intl)
  const maxLength = maxLengthFieldError(intl)
  return (
    <div className={s.formContainer} data-testid="order-form">
      {!hasFeatureTag(ActiveTags.mdm, me) && (
        <TextField
          {...register('order.reference', {
            required: me.merchant.order_reference.required ? required : undefined,
            maxLength,
          })}
          error={errors.order?.reference?.message}
          type="text"
          isDirty={dirtyFields.order?.reference}
          onClearClick={() => resetField('order.reference')}
          id="order.reference"
          label={
            me.merchant.order_reference.required ? (
              <FormattedMessage
                id="order.form.reference.required"
                defaultMessage="Order reference"
                description="Field label in POS page to fill the internal id of the payment on the merchant side. This is displayed when the merchant decided to make it mandatory. In new POS, this field appears in the customer details section."
              />
            ) : (
              <FormattedMessage
                id="order.form.reference.optional"
                defaultMessage="Order reference (optional)"
                description="Field label in POS page to fill the internal id of the payment on the merchant side. Optional field unless the merchant decided to make it mandatory. In new POS, this field appears in the customer details section."
              />
            )
          }
          placeholder={me.merchant.order_reference.tip || undefined}
          disabled={disableField('order.reference')}
        />
      )}
      <TextField
        {...register('order.comment', {
          maxLength,
        })}
        error={errors.order?.comment?.message}
        type="text"
        isDirty={dirtyFields.order?.comment}
        onClearClick={() => resetField('order.comment')}
        id="order.comment"
        label={
          <FormattedMessage
            id="order.form.comment"
            defaultMessage="Commentary (optional)"
            description="Field label in POS page. Optional field where the seller can indicate some comments like the details of the purchase. In new POS, this field appears in the customer details section."
          />
        }
        disabled={disableField('order.comment')}
      />
    </div>
  )
}
