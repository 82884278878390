import s from '@/components/Modals/CopyLinkModal/CopyLinkModal.module.css'
import { useTracking } from '@/thirdParties/analytics'
import { COPY_IS_DISABLED, useCopy } from '@/utils/copyToClipboard'
import { Button, Header, Input, Modal } from '@alma/react-components'
import React, { useRef, VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

interface CopyLinkModalProps {
  isOpen: boolean
  onClose: () => void
  link: string
  paymentId: string
}

export const CopyLinkModal: VoidFunctionComponent<CopyLinkModalProps> = ({
  isOpen,
  onClose,
  link,
  paymentId,
}) => {
  const intl = useIntl()
  const track = useTracking('create_link', isOpen)
  const linkInput = useRef<HTMLInputElement | null>(null)
  const navigate = useNavigate()

  const handleCopy = useCopy(
    link,
    intl.formatMessage({
      id: 'toast.copied.link.validation',
      defaultMessage: 'Payment link copied',
      description:
        'This is a message displayed when a payment link was successfully copied. It is displayed for 5 seconds as a confirmation.',
    }),
    () => track('copy_link'),
    intl
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={s.copyLinkModal}
      shouldCloseOnOverlayClick={false}
    >
      <Header level={5} as="h2">
        <FormattedMessage
          id="copyLink.header"
          defaultMessage="Create payment link"
          description="When the seller click on the pay by link CTA in the payment form, there is a popup that appears with the payment link displayed and a CTA allowing to copy the link (to paste it in an email for instance). This is the title of the popup"
        />
      </Header>
      <div className={s.copyContainer}>
        <Input ref={linkInput} value={link} readOnly />
        {handleCopy !== COPY_IS_DISABLED && (
          <Button
            color="primary"
            block
            onClick={handleCopy}
            className={s.copyButton}
            data-testid="copy-link-button"
          >
            <FormattedMessage
              id="copyLink.copy"
              defaultMessage="Copy"
              description="When the seller click on the pay by link CTA in the payment form, there is a popup that appears with the payment link displayed and a CTA allowing to copy the link (to paste it in an email for instance). This is the title of the CTA"
            />
          </Button>
        )}
      </div>
      <Button
        color="secondary"
        block
        onClick={() => navigate(`/payment/${paymentId}`, { replace: true })}
        data-testid="see-payment-button"
        className={s.copyButton}
      >
        <FormattedMessage
          id="copyLink.see.payment"
          defaultMessage="View payment"
          description="When the seller click on the pay by link CTA in the payment form, there is a popup that appears with the payment link and there is a button to show the payment details"
        />
      </Button>
    </Modal>
  )
}
