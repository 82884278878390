/* istanbul ignore file */

type WebsocketClientOptions = {
  onMessage?: (event: MessageEvent) => void
  onClose?: (event: CloseEvent) => void
  onError?: (event: Event) => void
}

export function makeWebsocketClient(
  url: URL,
  { onMessage, onClose, onError }: WebsocketClientOptions
): WebSocket {
  const socket = new WebSocket(url)

  socket.onmessage = onMessage ?? null
  socket.onclose = onClose ?? null
  socket.onerror = onError ?? null

  return socket
}
