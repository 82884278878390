import { isDefined } from '@alma/react-components'

export type OnlyDefined<T extends object> = {
  [key in keyof T]?: NonNullable<T[key]>
}

export const onlyDefined = <T extends object>(obj: T): OnlyDefined<T> =>
  Object.fromEntries(Object.entries(obj).filter(([, item]) => isDefined(item))) as OnlyDefined<T>

export const typedEntries: <T extends string, V>(obj: Record<T, V>) => (readonly [T, V])[] =
  Object.entries
export const typedFromEntries: <T extends string, V>(array: (readonly [T, V])[]) => Record<T, V> =
  Object.fromEntries

export const silentJSONParse = (data: string | null | undefined) => {
  try {
    return data ? JSON.parse(data) : undefined
  } catch (e) {
    return undefined
  }
}
