import { useGetPaymentQuery } from '@/apis/hooks'
import { WithActionButtonsProps } from '@/components/ActionsButtons/ActionsButtons'
import s from '@/components/Pages/PaymentDetails/PaymentDetails.module.css'
import { usePaymentNotRelatedToMerchant } from '@/components/PaymentNotRelatedToMerchant/usePaymentNotRelatedToMerchant'
import { NoPaymentError } from '@/components/PaymentsModal/ModalContent/NoPaymentError'
import { OnlineSteps } from '@/components/PaymentsModal/OnlineSteps'
import { ButtonsAction } from '@/components/PaymentsModal/Templates'
import { PaymentTerminalStatus } from '@/components/PaymentsModal/TerminalSteps/PaymentTerminalStatus'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { isPaymentOnTerminal } from '@/types'
import { LoadingIndicator } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'

type Props = {
  getPaymentQuery: ReturnType<typeof useGetPaymentQuery>
  pathname: string
} & TrackerProps &
  WithActionButtonsProps

export const ModalContent: VoidFunctionComponent<Props> = ({
  getPaymentQuery,
  pathname,
  track,
  ActionButtons,
}) => {
  const payment = getPaymentQuery.data
  const paymentNotRelatedToMerchant = usePaymentNotRelatedToMerchant(payment)

  if (getPaymentQuery.isLoading) {
    return (
      <div className={s.pendingOrError}>
        <LoadingIndicator />
      </div>
    )
  }

  if (!payment) {
    return (
      <div className={s.pendingOrError}>
        <NoPaymentError />
      </div>
    )
  }
  if (paymentNotRelatedToMerchant) {
    return <div className={s.pendingOrError}>{paymentNotRelatedToMerchant}</div>
  }

  return (
    <div className={s.paymentDetails} data-testid="payment-status-modal-without-error">
      {isPaymentOnTerminal(payment) ? (
        <>
          <PaymentTerminalStatus
            payment={payment}
            refreshPayment={getPaymentQuery.refetch}
            pathname={pathname}
            ActionButtons={ActionButtons}
          />
          {payment?.status !== 'validated' && payment.cancelable && (
            <ButtonsAction>
              <ActionButtons.Cancel block color="primary-destructive" />
              <ActionButtons.Restart data-testid="restart-payment-button" block color="secondary" />
            </ButtonsAction>
          )}
        </>
      ) : (
        <OnlineSteps
          payment={payment}
          track={track}
          pathname={pathname}
          ActionButtons={ActionButtons}
        />
      )}
    </div>
  )
}
