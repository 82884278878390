import { BaseSyntheticEvent } from 'react'
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form'

const swallowError = () => undefined

export const handleSubmitSilent =
  <T extends FieldValues>(
    handler: (values: T) => Promise<unknown>,
    handleSubmit: UseFormHandleSubmit<T>
  ) =>
  (e: BaseSyntheticEvent) =>
    handleSubmit((values) => handler(values as T))(e).catch(swallowError)
