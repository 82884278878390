import { AdditionalActionPerOrigin } from '@/components/PaymentsModal/OnlineSteps/Steps/StartPayment/AdditionalActionPerOrigin'
import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import {
  ButtonsAction,
  GreyZone,
  Header,
  ItDoesNotWorkCollapsible,
  List,
  ListItem,
} from '@/components/PaymentsModal/Templates'
import { HorizontalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import { PaymentOrigin, Payment } from '@/types'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const StartPayment: VoidFunctionComponent<StepProps & { payment: Payment }> = ({
  id,
  paymentLink,
  updatedDate,
  paymentOrigin,
  customerPhone,
  amountAndPlan,
  createdDate,
  customerName,
  ActionButtons,
  track,
  payment,
}) => {
  const intl = useIntl()
  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />
      <GreyZone
        progressBarTitle={intl.formatMessage({
          id: 'payment.step.detail.progress.title.summary',
          defaultMessage: 'Summary',
          description:
            'Title of the progress bar in the payment detail modal for the summary of the payment',
        })}
        progress={10}
      >
        <HorizontalTitle updated={updatedDate}>
          <FormattedMessage
            id="payment.detail.requirementStep.valid_payment_method"
            defaultMessage="Entering the payment method"
            description="Title of the payment requirement step in which the client's personal information and credit card have to be filled"
          />
        </HorizontalTitle>
        <List>
          <ListItem>
            <FormattedMessage
              id="payment.detail.payment.method.validation.info"
              defaultMessage="Your customer have to fill their payment card information"
              description="In the payment's detail modal this explains that the customer should fill their card info at this step."
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="payment.detail.customer.info"
              defaultMessage="Your customer have to fill their personal information"
              description="In the payment's detail modal this explains that the customer should fill their personal information at this step."
            />
          </ListItem>
          {paymentOrigin === PaymentOrigin.posSms && (
            <ListItem>
              <FormattedMessage
                id="payment.detail.phone.number.link.sent"
                defaultMessage="The link has been sent to <b>{phoneNumber}</b>"
                description="In the payment's detail modal, when the link has been sent by SMS, there is a text to show ne number it has been sent to."
                values={{
                  phoneNumber: customerPhone,
                  b: (content: string) => <strong className={s.phoneNumber}>{content}</strong>,
                }}
              />
            </ListItem>
          )}
        </List>
        <AdditionalActionPerOrigin
          paymentOrigin={paymentOrigin}
          paymentLink={paymentLink}
          customerPhone={customerPhone}
          id={id}
          track={track}
          payment={payment}
        />
      </GreyZone>
      <ItDoesNotWorkCollapsible>
        <List>
          <ListItem>
            <FormattedMessage
              id="it.does.not.work.card.accepted"
              defaultMessage="We accept cards from FR-BE-DE-IT-ES-AT-LU-NL-IE countries"
              description="On the payments details page, in the 'it does no work' collapsible, this explains what bank card we accept"
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="it.does.not.work.card.not.accepted"
              defaultMessage="Cards from other countries and prepaid (like Revolut) are not allowed."
              description="On the payments details page, in the 'it does no work' collapsible, this explains what bank card we don't accept"
            />
          </ListItem>
        </List>
        <ButtonsAction>
          <ActionButtons.Restart color="link" />
          <ActionButtons.Cancel color="link" />
        </ButtonsAction>
      </ItDoesNotWorkCollapsible>
    </>
  )
}
