import { config } from '@/config'
import { MapboxGeocodingResult, MapboxGeoFeature, MapboxPlaceType } from '@/types/Mapbox'
import axios from 'axios'

export const MAPBOX_BASE_URL = 'https://api.mapbox.com/geocoding/v5'

export interface FullAddress {
  address: string
  zipCode: string
  city: string
  country: string
  state?: string
  district?: string
  label: string
}

export async function searchAddress(search: string, signal?: AbortSignal) {
  const { data } = await axios.get<MapboxGeocodingResult>(
    `${MAPBOX_BASE_URL}/mapbox.places/${encodeURIComponent(search)}.json`,
    {
      params: {
        access_token: config.MAPBOX_ACCESS_TOKEN,
        autocomplete: true,
        types: 'place,postcode,address,country,district,region',
        proximity: 'ip',
      },
      timeout: 10_000,
      signal,
    }
  )
  return data
}

export function readMapboxFeature({
  place_name,
  address,
  text,
  context,
}: MapboxGeoFeature): FullAddress {
  const postCode = context?.find((c) => c.id.startsWith(MapboxPlaceType.postcode))
  const place = context?.find((c) => c.id.startsWith(MapboxPlaceType.place))
  const country = context?.find((c) => c.id.startsWith(MapboxPlaceType.country))
  const region = context?.find((c) => c.id.startsWith(MapboxPlaceType.region))
  const district = context?.find((c) => c.id.startsWith(MapboxPlaceType.district))

  return {
    address: `${(address && `${address} `) || ''}${text}`,
    zipCode: postCode?.text ?? '',
    city: place?.text ?? '',
    country: country?.text ?? '',
    state: region?.text,
    district: district?.text,
    label: place_name,
  }
}
