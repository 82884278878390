import s from '@/components/PaymentsModal/Templates/Templates.module.css'
import React, { VoidFunctionComponent } from 'react'

type Props = {
  name: string
  createdDate: string
  amountAndPlan: string
}

export const Header: VoidFunctionComponent<Props> = ({ name, createdDate, amountAndPlan }) => (
  <div className={s.header}>
    <div className={s.headerName}>{name}</div>
    <div>|</div>
    <div>{createdDate}</div>
    <div>|</div>
    <div>{amountAndPlan}</div>
  </div>
)
