import React from 'react'
import { Icon, IconName, IconPill } from '@alma/react-components'
import cx from 'classnames'
import s from '@/components/HelpCenter/HelpCenter.module.css'

type HelpCenterLinkProps = {
  icon: IconName
  href?: string
  onClick?: () => void
  iconColor?: 'green' | 'blue' | 'red' | 'orange' | 'yellow' | 'grey' | 'white' | undefined
  buttonTitle: React.ReactNode
}

export const HelpCenterLink: React.FC<HelpCenterLinkProps> = ({
  icon,
  buttonTitle,
  href,
  onClick,
  iconColor,
}) => (
  <div className={s.buttonContainer}>
    {href ? (
      <a href={href} className={s.helpButton} onClick={onClick} target="_blank" rel="noreferrer">
        <div className={s.helpButtonContent}>
          <div className={s.helpCenterIcon}>
            <IconPill iconSize="md" icon={icon} color={iconColor} isColored />
          </div>
          {buttonTitle}
        </div>
      </a>
    ) : (
      <button type="button" className={cx(s.helpButton)} onClick={onClick}>
        <div className={s.helpButtonContent}>
          <div className={s.helpCenterIcon}>
            <IconPill iconSize="md" icon={icon} color={iconColor} isColored />
          </div>
          {buttonTitle}
        </div>
      </button>
    )}
    <Icon icon="chevron-right" width="24px" />
  </div>
)
