import { useMe } from '@/components/AuthenticationManager'
import { CustomerInfo } from '@/components/CustomerInfo/CustomerInfo'
import { DisabledFields, DisabledFieldsProvider } from '@/components/Forms/disabledFields'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { Installments } from '@/components/Installments/Installments'
import s from '@/components/Pages/Home/CreatePaymentPage/Home.module.css'
import { PaymentMethodsHandler } from '@/components/Pages/Home/usePaymentMethods'
import { PaymentSubmit } from '@/components/PaymentSubmit/PaymentSubmit'
import { Purchase } from '@/components/Purchase/Purchase'
import { ClickTracker } from '@/thirdParties/analytics'
import { useScreenBreakpoint } from '@/utils/useScreenBreakpoint'
import cx from 'classnames'
import React, { ReactNode, VoidFunctionComponent } from 'react'
import { DeepPartial, useForm, useFormContext } from 'react-hook-form'

type Props = {
  track: ClickTracker
  disabledFields?: DisabledFields
  forms?: ReactNode
  methods?: ReactNode
  paymentMethodsHandler: PaymentMethodsHandler
}

export function usePaymentCreateForm(defaultValues: DeepPartial<PaymentCreateFormValues>) {
  return useForm<PaymentCreateFormValues>({
    mode: 'onTouched',
    defaultValues,
  })
}

export const PaymentCreateForm: VoidFunctionComponent<Props> = ({
  track,
  disabledFields = [],
  forms,
  methods,
  paymentMethodsHandler,
}) => {
  const me = useMe()
  const form = useFormContext<PaymentCreateFormValues>()

  const isLargeScreen = useScreenBreakpoint('lg')

  const isSubmitSuccessful = form.formState.isSubmitSuccessful

  return (
    <DisabledFieldsProvider disabledFields={isSubmitSuccessful || disabledFields}>
      <form noValidate className={cx(s.home, { [s.loginAs]: me.real_user })} data-testid="home">
        {!isLargeScreen && (
          <div className={s.smallLayout} data-testid="small-layout">
            <Purchase track={track} />
            <Installments />
            <CustomerInfo track={track} />
            {forms}
            {methods}
            <PaymentSubmit handler={paymentMethodsHandler} />
          </div>
        )}
        {isLargeScreen && (
          <div className={s.largeLayout} data-testid="large-layout">
            <div className={cx(s.column, s.formColumn)}>
              <Purchase track={track} />
              <CustomerInfo track={track} />
              {forms}
            </div>
            <div className={cx(s.column, s.paymentColumn)}>
              <Installments />
              {methods}
              <PaymentSubmit handler={paymentMethodsHandler} />
            </div>
          </div>
        )}
      </form>
    </DisabledFieldsProvider>
  )
}
