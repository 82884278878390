import { useFeePlansQuery } from '@/apis/hooks'
import { useMe } from '@/components/AuthenticationManager'
import s from '@/components/Forms/PaymentCreate/PurchaseForm/PurchaseForm.module.css'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { LoadingIndicator } from '@alma/react-components'
import cx from 'classnames'
import { onlyDefined } from '@/utils'
import React, { useMemo, VoidFunctionComponent } from 'react'
import { DeepPartial, useFormContext } from 'react-hook-form'
import { PhoneNumberForm } from '@/components/Forms/PaymentCreate/PhoneNumberForm'
import { AmountField } from '@/components/Forms/PaymentCreate/PurchaseForm/AmountField'
import { InstallmentsField } from '@/components/Forms/PaymentCreate/PurchaseForm/InstallmentsField'
import { useCurrentEligibility } from '@/components/Forms/PaymentCreate/useCurrentEligibility'

const DEFAULT_VALUES_NOT_YET_AVAILABLE = Symbol('DEFAULT_VALUES_NOT_YET_AVAILABLE')

export const isDefaultPurchaseFormValues = (
  values:
    | Pick<DeepPartial<PaymentCreateFormValues>, 'purchase'>
    | typeof DEFAULT_VALUES_NOT_YET_AVAILABLE
): values is Pick<DeepPartial<PaymentCreateFormValues>, 'purchase'> =>
  values !== DEFAULT_VALUES_NOT_YET_AVAILABLE

export const useDefaultPurchaseFormValues = ():
  | Pick<DeepPartial<PaymentCreateFormValues>, 'purchase'>
  | typeof DEFAULT_VALUES_NOT_YET_AVAILABLE => {
  const me = useMe()
  const feePlans = useFeePlansQuery('pos', 'all', true)

  return useMemo(
    () =>
      feePlans.data
        ? {
            purchase: onlyDefined({
              formattedAmountInEuros: '',
              feePlan: me.merchant.customer_choose_installments
                ? undefined
                : feePlans.data.find(
                    (item) =>
                      item.installments_count === me.merchant.default_installments_count &&
                      item.deferred_days === 0 &&
                      item.deferred_months === 0
                  ),
            }),
          }
        : DEFAULT_VALUES_NOT_YET_AVAILABLE,
    [
      feePlans.data,
      me.merchant.customer_choose_installments,
      me.merchant.default_installments_count,
    ]
  )
}

export const PurchaseForm: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const feePlans = useFeePlansQuery('pos', 'all', true)
  const { trigger } = useFormContext<PaymentCreateFormValues>()

  // Watch eligibility to validate purchase amount
  const { eligibility, feePlan } = useCurrentEligibility({
    onSuccess: (data) => {
      // Whenever eligibility is updated, the amount field validation is executed
      if (data) {
        requestAnimationFrame(() => trigger('purchase.formattedAmountInEuros'))
      }
    },
  })

  if (feePlans.isLoading) {
    return <LoadingIndicator />
  }
  if (!feePlans.data) {
    return <div>Error message</div>
  }

  return (
    <div className={s.container}>
      {feePlans.data.length > 10 ? (
        <>
          <div className={s.manyInstallmentsPurchaseForm} data-testid="purchase-form">
            <AmountField eligibility={eligibility} feePlan={feePlan} track={track} />{' '}
            <PhoneNumberForm />
          </div>
          <div data-testid="many-installments-feePlans">
            <InstallmentsField feePlan={feePlan} feePlans={feePlans.data} track={track} />
          </div>
        </>
      ) : (
        <>
          <div
            className={cx(s.purchaseForm, { [s.hasManyInstallments]: feePlans.data.length > 10 })}
            data-testid="purchase-form"
          >
            <AmountField eligibility={eligibility} feePlan={feePlan} track={track} />
            <InstallmentsField feePlan={feePlan} feePlans={feePlans.data} track={track} />
          </div>
          <div className={s.phoneNumber}>
            <PhoneNumberForm />
          </div>
        </>
      )}
    </div>
  )
}
