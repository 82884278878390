import { Locale } from '@/types'
import { DropdownOptions } from '@alma/react-components'

export const LanguagesSelection: DropdownOptions = [
  {
    value: Locale.fr,
    label: 'Français',
  },
  {
    value: Locale.en,
    label: 'English',
  },
  {
    value: Locale.de,
    label: 'Deutsch',
  },
  {
    value: Locale.es,
    label: 'Español',
  },
  {
    value: Locale.it,
    label: 'Italiano',
  },
  {
    value: Locale.nl,
    label: 'Nederlands',
  },
  {
    value: Locale.pt,
    label: 'Português',
  },
]
