import { EventProps, PageViewDataType } from '@/thirdParties/analytics/pianoAnalytics'
import { FeePlan, Me } from '@/types'
import { feePlanKey, isCredit, isDeferred } from '@/utils/utils'

export const getAvailablePaymentMethods = (me: Me): PageViewDataType['available_payments'] =>
  [
    me.merchant.has_pos_pay_by_sms ? 'sms' : '',
    me.merchant.has_pos_pay_on_device ? 'on-screen' : '',
    me.merchant.has_pos_pay_by_link ? 'link' : '',
  ].filter((method) => !!method)

// AT Internet does not support correctly spaces in string value
export const escapeForPianoAnalytics = (string: string) => string.replace(/ /g, '_')

export const getPaymentType = (feePlan: FeePlan): 'deferred' | 'credit' | 'pnx' => {
  if (isDeferred(feePlan)) {
    return 'deferred'
  }
  if (isCredit(feePlan)) {
    return 'credit'
  }
  return 'pnx'
}

export function eventPropsFromFeePlan(
  feePlan?: FeePlan,
  feesOffered?: boolean
): EventProps | undefined {
  if (!feePlan) return undefined
  return {
    installments: feePlanKey(feePlan),
    payment_type: getPaymentType(feePlan),
    fees_offered: feesOffered,
  }
}
