import { useMe } from '@/components/AuthenticationManager'
import { getTerminal } from '@/components/SelectTerminal/utils'
import { Terminal } from '@/types'
import { useCallback, useMemo } from 'react'

type UseLastTerminalResponse = [
  lastTerminal: LastTerminal,
  setLastTerminalToStorage: (terminalName: string) => void
]

const USER_HAS_NO_TERMINAL = Symbol('USER_HAS_NO_TERMINAL')

export type LastTerminal = Terminal | typeof USER_HAS_NO_TERMINAL

export const isTerminal = (terminal: LastTerminal): terminal is Terminal =>
  terminal !== USER_HAS_NO_TERMINAL

const LAST_TERMINAL_KEY = 'lastTerminal'

export const useLastTerminal: () => UseLastTerminalResponse = () => {
  const me = useMe()

  const setLastTerminalToStorage = useCallback(
    (terminalName: string) => localStorage.setItem(LAST_TERMINAL_KEY, terminalName),
    []
  )

  const lastTerminal = useMemo(
    () =>
      getTerminal(me, localStorage.getItem(LAST_TERMINAL_KEY) ?? '') ??
      me.terminals[0] ??
      USER_HAS_NO_TERMINAL,
    [me]
  )

  return [lastTerminal, setLastTerminalToStorage]
}
