import { useMe } from '@/components/AuthenticationManager'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { Payment, isAtLeastRole } from '@/types'
import { Button } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { ButtonsAction } from '@/components/PaymentsModal/Templates'
import { WithActionButtonsProps } from '@/components/ActionsButtons/ActionsButtons'

type Props = WithActionButtonsProps & {
  paymentId: string
  pathname: string
  payment: Payment
} & TrackerProps
export const MdMValidatedPayment: VoidFunctionComponent<Props> = ({
  paymentId,
  pathname,
  ActionButtons,
  payment,
  track,
}) => {
  const navigate = useNavigate()
  const me = useMe()
  return (
    <>
      {isAtLeastRole(me, 'salesperson', { exclusive: true }) && (
        <ButtonsAction>
          <ActionButtons.GoToDashboard color="secondary" block paymentId={paymentId} />
        </ButtonsAction>
      )}
      {me.merchant.id === payment.merchant_id ? (
        <Button
          block
          data-testid="open-barcode-button"
          color="secondary"
          role="link"
          onClick={() => {
            track('payment_barcode_open')
            navigate(`/payment${pathname === '/' ? '' : 's'}/${paymentId}/barcode`)
          }}
        >
          <FormattedMessage
            id="paymentDetails.barcode"
            defaultMessage="Display barcode"
            description="In the detail payment popup, when the payment is validated and it's a MDM payment, there is a CTA to display the payment's barcode."
          />
        </Button>
      ) : (
        <ActionButtons.Close color="primary" block />
      )}
    </>
  )
}
