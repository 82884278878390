import { AppErrorPage, AppErrorPageProps } from '@/components/AppErrorPage/AppErrorPage'
import { BoundaryType } from '@/components/AppErrorPage/BoundaryType'
import { knownError } from '@/components/AppErrorPage/knownErrors'
import * as Sentry from '@sentry/react'
import React, { PropsWithChildren, PureComponent } from 'react'

type Props = PropsWithChildren<
  {
    boundary: BoundaryType
  } & AppErrorPageProps
>
interface State {
  error: Error | null
}

export class AppErrorBoundary extends PureComponent<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  override state: State = { error: null }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const shouldReport = knownError(error).shouldReport

    if (shouldReport) {
      const { boundary } = this.props
      Sentry.captureException(error, {
        level: 'error',
        tags: { boundary },
        extra: { errorInfo },
      })
    }

    this.setState({ error })
  }

  override render() {
    const { children, boundary } = this.props
    const { error } = this.state
    if (!error) {
      return <>{children}</>
    }

    const KnownErrorMessage = knownError(error).message[boundary]

    return (
      <AppErrorPage {...this.props}>
        <KnownErrorMessage error={error} />
      </AppErrorPage>
    )
  }
}
