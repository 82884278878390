import s from '@/components/BurgerButton/BurgerButton.module.css'
import { Button, IconName } from '@alma/react-components'
import React, { ButtonHTMLAttributes, VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const BurgerButton: VoidFunctionComponent<
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'>
> = ({ ...buttonProps }) => {
  const intl = useIntl()

  return (
    <div className={s.burgerButton}>
      <Button
        color="secondary"
        small
        {...buttonProps}
        className={s.buttonContainer}
        icon={{ left: IconName['bars-3'] }}
        title={intl.formatMessage({
          id: 'menu.button.title',
          defaultMessage: 'show menu',
          description:
            'There is a menu on the left side of the payment form where the seller can switch languages, go to the dashboard, log out and switch shops. This is the button to open the menu from the payment form',
        })}
        data-testid="menu-button"
        data-stonly-anchor="menuButton"
      >
        <span className={s.desktopText}>
          <FormattedMessage
            id="menu.title"
            defaultMessage="Menu"
            description="Title of the menu panel that appears on the left side of the POS page when clicking on the menu button. Below this title, there are various admin functions (set the language, sign out, link to dashboard)"
          />
        </span>
      </Button>
    </div>
  )
}
