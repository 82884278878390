import { useSelectMerchantMutation } from '@/apis/hooks'
import { useMe, useReloadAuthentication } from '@/components/AuthenticationManager'
import s from '@/components/MerchantSelector/MerchantSelector.module.css'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { isAtLeastRole } from '@/types'
import { Dropdown } from '@alma/react-components'
import cx from 'classnames'
import React, { ComponentProps, useMemo, VoidFunctionComponent } from 'react'

type Props = Omit<ComponentProps<typeof Dropdown>, 'options' | 'value' | 'onChange'> & TrackerProps

export const MerchantSelector: VoidFunctionComponent<Props> = ({ className, track, ...props }) => {
  const me = useMe()
  const reloadAuthentication = useReloadAuthentication()

  const selectMerchant = useSelectMerchantMutation({
    onMutate: () => track('shop'),
    onSuccess: () => reloadAuthentication(),
  })

  const options = useMemo(
    () =>
      me.merchants
        // We remove the merchants for which the user has a role < to 'salesperson'
        // Because disabled & salesperson_finalize_only roles do not allow to be logged into PoS anyway
        .filter((merchant) =>
          isAtLeastRole({ role_for_merchant: merchant.user_role }, 'salesperson')
        )
        .map((merchant) => ({
          value: merchant.value,
          label: merchant.name,
        })),
    [me.merchants]
  )

  return (
    <Dropdown
      {...props}
      className={cx(s.dropdown, className)}
      options={options}
      value={me.merchant.id}
      chevronColor="--off-black"
      onChange={(event) =>
        typeof event.target.value === 'string' &&
        selectMerchant.mutate({ merchantId: event.target.value })
      }
    />
  )
}
