import { RequirementsForm } from '@/components/Forms/PaymentCreate'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import s from '@/components/Requirements/Requirements.module.css'
import { RequirementsTable } from '@/components/RequirementsTable/RequirementsTable'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { Button, CardTemplate, Header, Modal } from '@alma/react-components'
import React, { useState, VoidFunctionComponent } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

export const Requirements: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const [showDetails, setShowDetails] = useState(false)
  const { watch } = useFormContext<PaymentCreateFormValues>()
  const mode = watch('requirements.mode')

  return (
    <CardTemplate
      padding="lg"
      header={
        <Header size="sm">
          <FormattedMessage
            id="home.requirements.title"
            defaultMessage="Requirements"
            description="In the payment form, title of the card allowing to choose between standard mode (all requirements) and fast mode (few requirements)."
          />
        </Header>
      }
      corner={
        <Button
          color="link"
          onClick={() => {
            track('fast_mode_details')
            setShowDetails(true)
          }}
          data-stonly-anchor="fastModeLinkSeeMore"
        >
          <FormattedMessage
            id="home.requirements.learnMore"
            defaultMessage="Learn more"
            description="In the requirements card, link in the upright corner that opens a modal to explain differences between standard and fast mode."
          />
        </Button>
      }
      borderColor={mode === 'fast' ? 'yellow' : undefined}
      data-testid="requirements-section"
      data-stonly-anchor="fastModeCard"
    >
      <RequirementsForm track={track} />
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={showDetails}
        onClose={() => setShowDetails(false)}
        size="lg"
        className={s.requirementsModal}
        scrollable
      >
        <Header level="6" as="h2">
          <FormattedMessage
            id="home.requirements.title-table"
            defaultMessage="Requirements"
            description="Title of the modal containing the table explaining requirements modes"
          />
        </Header>
        <RequirementsTable selectedMode={mode} />
        <div className={s.modalActions}>
          <Button onClick={() => setShowDetails(false)} color="secondary">
            <FormattedMessage
              id="home.requirements.learnMore.close"
              defaultMessage="Close"
              description="Close button at the bottom of the modal with the requirements mode table"
            />
          </Button>
        </div>
      </Modal>
    </CardTemplate>
  )
}
