import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

type CountdownContextValue = [number, Dispatch<SetStateAction<number>>]

const CountdownContext = createContext<CountdownContextValue>([0, () => {}])

export const useCountdown = () => useContext(CountdownContext)

export const CountdownContextProvider: FunctionComponent = ({ children }) => {
  const initialCount = Number(localStorage.getItem('send_sms_countdown'))
  const [countdown, setCountdown] = useState(Number.isNaN(initialCount) ? 0 : initialCount)
  useEffect(() => {
    if (countdown <= 0) return undefined

    const timeout = setTimeout(() => {
      setCountdown(countdown - 1)
      localStorage.setItem('send_sms_countdown', `${countdown - 1}`)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [countdown])
  const value: CountdownContextValue = useMemo(() => [countdown, setCountdown], [countdown])
  return <CountdownContext.Provider value={value}>{children}</CountdownContext.Provider>
}
