import { checkAccess, filterReachable, HostsToCheck } from '@/accessChecker/checkAccess'
import { logUnreachableHosts } from '@/apis/api'
import { handleError, parseAxiosError } from '@/utils'
import { silentJSONParse } from '@/utils/objects'
import { addDays, differenceInMilliseconds, isPast, parse, parseISO } from 'date-fns'
import { z } from 'zod'

const SCHEDULER_EXECUTION_TIME = '15:00:00.000'
const SCHEDULER_EXECUTION_TIME_FORMAT = 'HH:mm:ss.SSS'

// eslint-disable-next-line @typescript-eslint/no-inferrable-types -- widening the type to allow mocking
export const SCHEDULING_STATUS_KEY: string = 'almaAccessCheckStatus'

const NOT_RUN_YET = Symbol('NOT_RUN_YET')

const SchedulerStatus = z.object({
  lastRun: z.string().transform((date) => parseISO(date)),
  reachableHosts: z.array(z.string()),
  unreachableHosts: z.array(z.string()),
})
// eslint-disable-next-line no-redeclare,@typescript-eslint/no-redeclare -- Type has same name as type
type SchedulerStatus = z.infer<typeof SchedulerStatus>

export const isSchedulerStatus = (
  status: SchedulerStatus | typeof NOT_RUN_YET
): status is SchedulerStatus => status !== NOT_RUN_YET

export const readSchedulingStatusFromStorage = (): SchedulerStatus | typeof NOT_RUN_YET => {
  const parsedStatus = SchedulerStatus.safeParse(
    silentJSONParse(localStorage.getItem(SCHEDULING_STATUS_KEY))
  )
  if (parsedStatus.success) {
    return parsedStatus.data
  }
  return NOT_RUN_YET
}

export const saveSchedulingStatusToStorage = (status: SchedulerStatus): void => {
  localStorage.setItem(
    SCHEDULING_STATUS_KEY,
    JSON.stringify(status, (_, val) => (val instanceof Date ? val.toISOString() : val))
  )
}

export const removeSchedulingStatusFromStorage = (): void => {
  localStorage.removeItem(SCHEDULING_STATUS_KEY)
}

export const scheduleHostCheck = <HostNames extends string>(
  merchantId: string,
  hostsToCheck: HostsToCheck<HostNames>,
  onChecked?: (status: SchedulerStatus) => void
): (() => void) => {
  const status = readSchedulingStatusFromStorage()
  let cancelNextCheck: () => void = () => undefined

  const doCheck = async () => {
    const checked = await checkAccess(hostsToCheck)
    const unreachableHosts = filterReachable(checked, false)
    const reachableHosts = filterReachable(checked, true)

    const newStatus = {
      // Note that when the date is saved in the localStorage, it does not take
      // TZ in consideration, so lastRun will mention "13h00" if the check has run at "15h00" in France (GMT+2).
      lastRun: new Date(),
      reachableHosts,
      unreachableHosts,
    }
    saveSchedulingStatusToStorage(newStatus)
    cancelNextCheck = scheduleHostCheck(merchantId, hostsToCheck, onChecked)
    onChecked?.(newStatus)
    if (unreachableHosts.length > 0) {
      try {
        await logUnreachableHosts(merchantId, unreachableHosts)
      } catch (e) {
        handleError(parseAxiosError(e))
      }
    }
  }

  if (!isSchedulerStatus(status)) {
    void doCheck()
    return cancelNextCheck
  }

  const reachableHosts = new Set(status.reachableHosts)
  if (Object.keys(hostsToCheck).every((host) => reachableHosts.has(host))) {
    return cancelNextCheck
  }

  const todayAt15 = parse(SCHEDULER_EXECUTION_TIME, SCHEDULER_EXECUTION_TIME_FORMAT, new Date())
  const next15 = isPast(todayAt15) ? addDays(todayAt15, 1) : todayAt15
  const timeout = setTimeout(doCheck, differenceInMilliseconds(next15, new Date()))

  return () => {
    clearTimeout(timeout)
    cancelNextCheck()
  }
}
