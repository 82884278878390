import { config } from '@/config'
import { Me } from '@/types'
import { loadExternalService } from '@/utils/externalService'

const SNIPPET =
  '!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,(g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),(l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())}(window,document,"script","https://stonly.com/js/widget/v2/")'

declare global {
  interface Window {
    STONLY_WID?: string
    StonlyWidget?: (command: string, ...args: unknown[]) => void
  }
}

/**
 * Load Stonly library using js snippet
 */
export function loadStonly() {
  if (
    process.env.NODE_ENV !== 'production' ||
    !['dev', 'staging', 'sandbox', 'prod'].includes(config.ENV)
  ) {
    // To start Stonly in local (for testing), don't return here (and don't commit please)
    return Promise.resolve()
  }

  window.STONLY_WID = '4ebe2052-cc84-11ec-9fb8-0ae9fa2a18a2'
  return loadExternalService({ snippet: SNIPPET })
}

export function isStonlyLoaded(): boolean {
  return Boolean(window.StonlyWidget)
}

export function openStonlyGuidedTour(guideId: string) {
  window.StonlyWidget?.('openGuidedTour', { guideId })
}

export function updateStonlyMe(me: Pick<Me, 'id' | 'locale' | 'role_for_merchant'> | null) {
  if (!me) {
    // This may (or may not) re-identify all previous actions of the user, we don't know.
    // We don't care, as the ID and role are used in a live context (show some steps in the Stonly stories)
    window.StonlyWidget?.('identify', 'anonymous', {
      role: 'unknown',
    })
    return
  }

  window.StonlyWidget?.('setWidgetLanguage', me.locale)
  window.StonlyWidget?.('identify', me.id, {
    role: me.role_for_merchant,
  })
}
