import { MemoryHistory } from 'history'
import { useContext } from 'react'
import { Navigator, To, UNSAFE_NavigationContext, useNavigate } from 'react-router-dom'

const isMemoryHistory = (navigator: Navigator): navigator is MemoryHistory => 'index' in navigator
const isReactRouterInitialized = () =>
  window.history.state !== null && 'idx' in window.history.state

export const useGoBack = (defaultRoute: To): (() => void) => {
  const navigate = useNavigate()
  const { navigator } = useContext(UNSAFE_NavigationContext)
  const isInitialRoute = isMemoryHistory(navigator)
    ? navigator.index === 0
    : isReactRouterInitialized() && window.history.state.idx === 0

  return () => (isInitialRoute ? navigate(defaultRoute, { replace: true }) : navigate(-1))
}
