export const setOnlyNumberValue = (value: string | number): string => {
  if (value === '') {
    return ''
  }

  const formattedStringValue = value
    .toString()
    .replace(/[^0-9.,]/gi, '')
    .replace(/,/g, '.')
    // https://stackoverflow.com/a/48505111
    .replace(/\./g, (val, index, str) => (index === str.indexOf('.') ? val : ''))

  if (Number.isNaN(parseFloat(formattedStringValue))) {
    return ''
  }

  if (formattedStringValue.match(/[,.]/) && formattedStringValue.split('.')[1].length > 2) {
    return formattedStringValue.slice(0, formattedStringValue.indexOf('.') + 3)
  }

  return formattedStringValue
}
