import React, { VoidFunctionComponent } from 'react'
import { useMe } from '@/components/AuthenticationManager'
import s from '@/components/LoginAs/LoginAsTopBar.module.css'
import { config } from '@/config'

export const LoginAsTopBar: VoidFunctionComponent = () => {
  const me = useMe()

  if (!me.real_user) {
    return null
  }

  return (
    <div className={s.container} data-testid="login-as-warning">
      Staff member <abbr title={me.real_user.email}>{me.real_user.name}</abbr> logged in as user{' '}
      <abbr title={me.email}>{me.name}</abbr> of merchant{' '}
      <abbr title={me.merchant.id}>{me.merchant.name}</abbr> -{' '}
      <a href={config.DASHBOARD_URL}>Return to admin dashboard</a>
    </div>
  )
}
