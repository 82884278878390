import s from '@/components/Forms/PaymentCreate/OfferFeesForm/OfferFeesForm.module.css'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { Switch } from '@alma/react-components'
import React, { useEffect, VoidFunctionComponent } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

export const DEFAULT_OFFER_FEES_FORM_VALUES: Pick<
  Partial<PaymentCreateFormValues>,
  'offerFees'
> = {}

type Props = {
  display: boolean
}

export const OfferFeesForm: VoidFunctionComponent<Props> = ({ display }) => {
  const { register, watch, setValue, getValues } = useFormContext<PaymentCreateFormValues>()
  const { isSubmitSuccessful } = useFormState<PaymentCreateFormValues>()

  // Disable offer-fees button when the user changes fee plan or amount
  useEffect(() => {
    setValue('offerFees.offered', getValues('offerFees.offer') ?? false)
    const subscription = watch((value, { name }) => {
      if (name && ['purchase.feePlan', 'purchase.formattedAmountInEuros'].includes(name))
        setValue('offerFees.offered', getValues('offerFees.offer') ?? false)
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue, getValues])

  return display ? (
    <div data-testid="offer-fees-form">
      <label htmlFor="offerFees.offered" className={s.switchLabel}>
        <FormattedMessage
          id="installments.fees.offer"
          defaultMessage="Offer fees"
          description="This is a toggle available for merchants who share Alma fees with end customers. It allows the merchant to carry all fees in order to help sellers close a deal with a reluctant customer"
        />
        <Switch
          id="offerFees.offered"
          containerProps={{
            'data-stonly-anchor': 'offerFeesSwitch',
          }}
          {...register('offerFees.offered', { disabled: isSubmitSuccessful })}
        />
      </label>
      <p className={s.offerFeesDescription}>
        <FormattedMessage
          id="installments.fees.offer.description"
          defaultMessage="Bear the cost for a commercial gesture."
          description="Description that appears bellow the button that lets a merchant take all the fees for himself"
        />
      </p>
    </div>
  ) : null
}
