import { patchUpdateUserLocale } from '@/apis/api'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { useSafeMutation, UseSafeMutationOptions } from '@/apis/hooks/utils'
import { Locale } from '@/types'

interface UseChangeLanguageMutationParameters {
  locale: Locale
}

export const useChangeLanguageMutation = (
  myId: string,
  options?: UseSafeMutationOptions<void, unknown, UseChangeLanguageMutationParameters>
) =>
  useSafeMutation(
    [CacheKeys.LocaleChange, myId],
    ({ locale }: UseChangeLanguageMutationParameters) => patchUpdateUserLocale(myId, locale),
    options
  )
