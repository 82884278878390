import { useChangeLanguageMutation } from '@/apis/hooks'
import { useMe } from '@/components/AuthenticationManager'
import { LanguagesSelection } from '@/components/Menu/LanguagesSelection'
import { LogoutButton } from '@/components/Menu/LogoutButton'
import s from '@/components/Menu/Menu.module.css'
import { countriesEligibleToReferral } from '@/components/ReferralProgram/MerchantLanguageCode'
import { ReferralProgram } from '@/components/ReferralProgram/ReferralProgram'
import { config } from '@/config'
import { useLocale } from '@/intl/I18nContext'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { isStonlyLoaded, openStonlyGuidedTour } from '@/thirdParties/stonly/stonly'
import { isAtLeastRole, Locale } from '@/types'
import { Button, Header, Icon, LanguageSelector, Logo } from '@alma/react-components'
import cx from 'classnames'
import React, { useMemo, VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

type Props = TrackerProps & { onCloseMenu: () => void }

export const Menu: VoidFunctionComponent<Props> = ({ onCloseMenu, track }) => {
  const intl = useIntl()
  const me = useMe()
  const { locale, setLocale } = useLocale()

  const canDisplayLinkToReferral = useMemo(
    () =>
      me.merchant.can_pay_in &&
      me.merchant.can_pay_out &&
      isAtLeastRole(me, 'admin') &&
      countriesEligibleToReferral.find(
        (lang) => lang.value.toLowerCase() === me.merchant.country.toLowerCase()
      ),
    [me]
  )
  const canDisplayLinkToTutorial = useMemo(() => isStonlyLoaded(), [])

  const changeLanguage = useChangeLanguageMutation(me.id, {
    onSuccess: (_, variables) => {
      setLocale(variables.locale)
      track('change-language')
    },
    onError: () => {
      track('change-language-error')
    },
  })

  return (
    <div className={s.menu} data-testid="menu">
      <div>
        <div className={s.menuHeader}>
          <div className={s.titleContainer}>
            <Logo className={s.logo} logo="alma-orange" />
            <button
              type="button"
              onClick={onCloseMenu}
              className={s.closeButton}
              title={intl.formatMessage({
                id: 'menu.button.close.title',
                defaultMessage: 'close menu',
                description:
                  'There is a menu on the left side of the payment form where the seller can switch languages, go to the dashboard, log out and switch shops. This is the button to close the menu once opened',
              })}
            >
              <Icon icon="close" width="20px" height="20px" />
            </button>
          </div>
          <div className={s.merchantInfo}>
            <Header size="xl">{me.name}</Header>
            <Header size="sm">{me.merchant.name}</Header>
          </div>
        </div>
        {canDisplayLinkToTutorial && (
          <div className={s.menuSection}>
            <div className={s.sectionContent}>
              <Button
                data-stonly-anchor="guidedTourStonly"
                color="link"
                onClick={() => {
                  track('tutorial_open')
                  openStonlyGuidedTour('WdKOqkRIV0')
                  onCloseMenu()
                }}
                className={cx(s.menuLink, s.linkDashboard)}
              >
                <FormattedMessage
                  id="link.to.tutorial"
                  defaultMessage="Tutorial"
                  description="IMPORTANT: The tutorial only exists in French right now, please translate with '(FR)' at the end as an indicator. Label of a link that starts the Stonly tutorial. Only visible if Stonly has loaded."
                />
              </Button>
            </div>
          </div>
        )}
        {isAtLeastRole(me, 'salesperson', { exclusive: true }) && (
          <div className={s.menuSection}>
            <div className={s.sectionContent}>
              <a
                href={config.DASHBOARD_URL}
                target="_blank"
                rel="noreferrer"
                onClick={() => track('dashboard')}
                className={s.menuLink}
              >
                <div className={s.linkDashboard}>
                  <FormattedMessage
                    id="link.to.dashboard"
                    defaultMessage="Alma dashboard"
                    description="Label of a link that opens, in a new page, the merchant dashboard related to the POS application. This link is displayed in the menu panel that appears on the left side of the POS page when clicking on the menu button."
                  />
                  <Icon icon="external-link" width="20px" height="20px" />
                </div>
              </a>
            </div>
            <div className={s.sectionContent}>
              {canDisplayLinkToReferral && <ReferralProgram track={track} />}
            </div>
          </div>
        )}
        <div className={s.sectionContent}>
          <a
            href={`${config.DASHBOARD_URL}/how-to-use-alma`}
            target="_blank"
            rel="noreferrer"
            onClick={() => track('training materials')}
            className={s.menuLink}
          >
            <div className={s.linkDashboard}>
              <FormattedMessage
                id="link.to.training"
                defaultMessage="Training Materials"
                description="Label of a link that opens, in a new page, the training page related to the Merchant Dashboard application. This link is displayed in the menu panel that appears on the left side of the POS page when clicking on the menu button."
              />
              <Icon icon="external-link" width="20px" height="20px" />
            </div>
          </a>
        </div>
      </div>
      <div className={s.bottomSection}>
        <div className={s.menuSection} data-testid="language-selector-container">
          <LanguageSelector
            id="language-selector"
            options={LanguagesSelection}
            onChange={(value) => changeLanguage.mutate({ locale: value as Locale })}
            value={LanguagesSelection.find((option) => option.value === locale)}
            inputId="language-selector"
            menuPlacement="top"
          />
        </div>
        <LogoutButton track={track} />
      </div>
    </div>
  )
}
