// Logger status enum
import { PaymentContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/types'
import { PaymentOrigin } from '@/types'

export enum LogStatus {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

// Event Name Enum
export enum LogEventName {
  finalizePayment = 'finalize_payment',
  createPayment = 'create_payment',
}

// For every kind of event, we want to log specific data
export type PoSDictType = {
  paymentId: string
  merchantId: string
  paymentMerchantId?: string
  finalizeFromOtherMerchant?: boolean
  isMerchantIobsp?: boolean
  canMerchantOfferFees?: boolean
  feesOffered?: boolean
  merchantActiveTags?: string[]
  paymentMethodUsed?: PaymentOrigin
  installmentCount?: number
  paymentContext?: PaymentContext
  requirements_mode?: 'fast' | 'standard'
}

// Global log data type
export type DataType = {
  eventName: LogEventName
} & (PoSDictType | Record<string, unknown>)

export type LoggerProps = {
  message: string
  data: DataType
}
