import { useLogoutMutation } from '@/apis/hooks'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { Button } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const LogoutButton: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const logout = useLogoutMutation({ onMutate: () => track('log_out') })
  return (
    <Button
      color="secondary-destructive"
      onClick={() => logout.mutate()}
      disabled={logout.isLoading}
      block
      data-testid="logout-button"
    >
      <FormattedMessage
        id="logout"
        defaultMessage="Sign out"
        description="Label of a link that will disconnect the user from the POS application, when clicking on it. It is displayed in the menu panel that appears on the left side of the POS page when clicking on the menu button."
      />
    </Button>
  )
}
