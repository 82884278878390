import axios, { AxiosInstance } from 'axios'

export const onErrorStatusHandlerGenerator =
  (client: AxiosInstance) =>
  (handler: () => void, ...statuses: number[]) => {
    const handlerId = client.interceptors.response.use(undefined, (error) => {
      if (axios.isAxiosError(error) && statuses.includes(error.response?.status ?? 0)) {
        handler()
      }
      return Promise.reject(error)
    })

    return () => client.interceptors.response.eject(handlerId)
  }

export function makeClient(baseUrl = '') {
  const buildVersion = import.meta.env.VITE_BUILD_VERSION ?? 'build-local'

  return axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
      'X-Alma-Agent': `Alma POS/${buildVersion}`,
    },
  })
}
