export interface MapboxGeoFeature {
  id: string
  type: 'Feature'
  center: number[]
  place_name: string
  place_type: MapboxPlaceType[]
  relevance: number
  text: string
  address: string
  context?: MapboxContext[]
}

export enum MapboxPlaceType {
  place = 'place',
  postcode = 'postcode',
  country = 'country',
  locality = 'locality',
  district = 'district',
  poi = 'poi',
  neighborhood = 'neighborhood',
  region = 'region',
  address = 'address',
}

export interface MapboxContext {
  id: string
  text: string
}

export interface MapboxGeocodingResult {
  type: 'FeatureCollection'
  query: string[]
  features: MapboxGeoFeature[]
}
