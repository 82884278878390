import s from '@/components/PaymentsModal/Templates/Templates.module.css'
import { Card, ProgressBar } from '@alma/react-components'
import React, { FunctionComponent } from 'react'

type Props = {
  children: React.ReactNode
  progressBarTitle?: string
  progress?: number
}

export const GreyZone: FunctionComponent<Props> = ({ children, progressBarTitle, progress }) => (
  <Card color="gray" className={s.greyZone} padding="lg" data-testid="paymentStatus">
    <>
      {progress && <ProgressBar title={progressBarTitle} progress={progress} />}
      {children}
    </>
  </Card>
)
