import { useFeePlansQuery } from '@/apis/hooks'
import { readPaymentFormValues } from '@/apis/readPaymentFormValues'
import { InitialAppLoadingIndicator, useMe } from '@/components/AuthenticationManager'
import {
  isDefaultPaymentFormValues,
  useDefaultPaymentFormValues,
} from '@/components/Forms/PaymentCreate'
import { FinalizePayment } from '@/components/Pages/Home/FinalizePaymentPage/FinalizePayment/FinalizePayment'
import {
  ERROR_JSON_PARSING,
  mergeFormValues,
  useValuesFromSearchParams,
} from '@/components/Pages/Home/searchParams'
import { useUpdatePaymentMethods } from '@/components/Pages/Home/usePaymentMethods'
import { usePaymentNotRelatedToMerchant } from '@/components/PaymentNotRelatedToMerchant'
import { useTracking } from '@/thirdParties/analytics'
import { getPaymentStatusString } from '@/utils'
import { Banner } from '@alma/react-components'
import React, { useEffect, useState, VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Navigate, useParams } from 'react-router'

export const FinalizePaymentPage: VoidFunctionComponent = () => {
  const intl = useIntl()
  const me = useMe()
  const track = useTracking('finalize')
  const defaultFormValues = useDefaultPaymentFormValues()
  const presetValues = useValuesFromSearchParams()
  const { data: feePlans } = useFeePlansQuery('pos', 'all', true)
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValues)
  const { paymentId } = useParams<'paymentId'>()
  const updatePaymentMethodsHandlers = useUpdatePaymentMethods(paymentId, track)
  const { payment } = updatePaymentMethodsHandlers
  const paymentNotRelatedToMerchant = usePaymentNotRelatedToMerchant(payment)

  useEffect(() => {
    if (!isDefaultPaymentFormValues(defaultFormValues)) {
      return
    }
    if (payment && feePlans && presetValues && presetValues !== ERROR_JSON_PARSING) {
      setInitialFormValues(
        mergeFormValues(
          defaultFormValues,
          mergeFormValues(presetValues, readPaymentFormValues(payment, feePlans, me))
        )
      )
    } else if (payment && feePlans) {
      setInitialFormValues(
        mergeFormValues(defaultFormValues, readPaymentFormValues(payment, feePlans, me))
      )
    }
  }, [defaultFormValues, feePlans, me, payment, presetValues])

  if (!isDefaultPaymentFormValues(initialFormValues)) {
    return <InitialAppLoadingIndicator />
  }

  if (!paymentId) {
    return <Navigate to="/" />
  }

  if (!payment) {
    return <InitialAppLoadingIndicator />
  }

  if (paymentNotRelatedToMerchant) {
    return paymentNotRelatedToMerchant
  }

  if (payment.status !== 'pending') {
    return (
      <Banner type="error">
        <FormattedMessage
          id="finalize.payment.error.not.pending"
          defaultMessage="This payment can not be finalized because it is {status}. Only {pending} payments can be finalized."
          values={{
            status: getPaymentStatusString(payment.status, intl),
            pending: getPaymentStatusString('pending', intl),
          }}
          description="Error message shown when a user tries to finalize a payment that is not pending. Variables: 'status' is the (already translated) status of the payment, 'pending' is the translation for the 'pending' status."
        />
      </Banner>
    )
  }

  return (
    <FinalizePayment
      initialFormValues={initialFormValues}
      updatePaymentMethodsHandlers={updatePaymentMethodsHandlers}
      track={track}
    />
  )
}
