import s from '@/components/PaymentsModal/Templates/Templates.module.css'
import { Header, Icon, IconName } from '@alma/react-components'
import cx from 'classnames'
import React, { FunctionComponent } from 'react'

type Props = {
  icon: { icon: IconName; color: string }
}
export const VerticalTitle: FunctionComponent<Props> = ({ icon, children }) => (
  <div className={cx(s.greyZoneVerticalTitleContainer)}>
    <div>
      <Icon {...icon} />
      <Header size="md">{children}</Header>
    </div>
  </div>
)
