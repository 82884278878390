import { AxiosInstance } from 'axios'
import { ApiFeePlan, FeePlan, isAllowedFeePlan } from '@/types'

export const getFeePlansGenerator =
  (client: AxiosInstance) =>
  async (
    kind: string,
    only: string,
    deferred: boolean,
    signal?: AbortSignal
  ): Promise<FeePlan[]> => {
    const { data } = await client.get<ApiFeePlan[]>(
      `v1/me/fee-plans?kind=${kind}&only=${only}&deferred=${deferred}`,
      { signal }
    )
    return data.filter(isAllowedFeePlan)
  }
