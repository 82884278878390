import s from '@/components/Pagination/Pagination.module.css'
import { useScreenBreakpoint } from '@/utils/useScreenBreakpoint'
import { Card, Icon } from '@alma/react-components'
import cx from 'classnames'
import React, { VoidFunctionComponent } from 'react'
import ReactPaginate from 'react-paginate'

type PaginationProps = {
  nbPages: number
  onChange: ({ selected }: { selected: number }) => void
  activePage: number
}

export const Pagination: VoidFunctionComponent<PaginationProps> = ({
  nbPages,
  onChange,
  activePage,
}) => {
  const isNotMobile = useScreenBreakpoint('md')
  return (
    <>
      {nbPages > 1 && (
        <Card>
          <div className={s.pagination}>
            <ReactPaginate
              breakLabel="..."
              onPageChange={(selectedPage: { selected: number }) => onChange(selectedPage)}
              pageRangeDisplayed={isNotMobile ? 2 : 0}
              marginPagesDisplayed={isNotMobile ? 1 : 0}
              pageCount={nbPages}
              previousLabel={
                <Icon
                  data-testid="pagination-left-chevron"
                  icon="chevron-left"
                  className={s.paginationChevron}
                  color="var(--alma-orange)"
                />
              }
              nextLabel={
                <Icon
                  data-testid="pagination-right-chevron"
                  icon="chevron-right"
                  className={s.paginationChevron}
                  color="var(--alma-orange)"
                />
              }
              forcePage={activePage}
              activeClassName={s.active}
              previousClassName={cx({ [s.hidden]: activePage === 0 })}
              nextClassName={cx({ [s.hidden]: activePage >= nbPages - 1 })}
              pageLinkClassName={s.pageNumber}
            />
          </div>
        </Card>
      )}
    </>
  )
}
