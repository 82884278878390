import { createDefaultCustomValues } from '@/apis/readPaymentFormValues'
import { useMe } from '@/components/AuthenticationManager'
import { useDisableField } from '@/components/Forms/disabledFields'
import s from '@/components/Forms/PaymentCreate/CustomDataForm/CustomDataForm.module.css'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { patternFieldError, requiredFieldError } from '@/components/Forms/validation'
import { CustomDataTemplate } from '@/types'
import { TextField } from '@alma/react-components'
import React, { useMemo, VoidFunctionComponent } from 'react'
import { DeepPartial, useFormContext, useFormState } from 'react-hook-form'
import { useIntl } from 'react-intl'

export const useDefaultCustomFormValues = (): Pick<
  DeepPartial<PaymentCreateFormValues>,
  'custom'
> => {
  const me = useMe()
  return useMemo(
    () => ({ custom: createDefaultCustomValues(me.merchant.custom_data_template) }),
    [me.merchant.custom_data_template]
  )
}

function toPattern(regex: CustomDataTemplate['regex']): RegExp | undefined {
  const cleanedRegexp = regex?.replace(/^\s+/, '')
  return cleanedRegexp ? new RegExp(cleanedRegexp) : undefined
}

export const CustomDataForm: VoidFunctionComponent = () => {
  const intl = useIntl()
  const { errors, dirtyFields } = useFormState<PaymentCreateFormValues>()
  const { disableField } = useDisableField<PaymentCreateFormValues>()
  const { register, resetField } = useFormContext<PaymentCreateFormValues>()

  const me = useMe()
  const templates = useMemo(
    () =>
      me.merchant.custom_data_template.map((template) => ({
        ...template,
        pattern: toPattern(template.regex),
      })),
    [me]
  )

  const required = requiredFieldError(intl)

  if (templates.length === 0) {
    return null
  }

  return (
    <div className={s.customDataForm} data-testid="custom-data-form">
      {templates.map((template) => (
        <TextField
          key={template.key}
          {...register(`custom.${template.key}`, {
            required: template.required && required,
            pattern:
              template.pattern && patternFieldError(intl, template.pattern, template.desc ?? ''),
          })}
          isDirty={dirtyFields.custom?.[template.key]}
          onClearClick={() => resetField(`custom.${template.key}`)}
          id={template.key}
          label={template.label}
          placeholder={template.desc ?? ''}
          required={template.required}
          pattern={template.pattern?.source}
          error={errors.custom?.[template.key]?.message}
          disabled={disableField(`custom.${template.key}`)}
        />
      ))}
    </div>
  )
}
