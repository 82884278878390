import { useCallback, useEffect, useRef, useState } from 'react'

export function useThrottle<T>(source: T, interval = 1000): T {
  const [throttled, setThrottled] = useState(source)
  const [locked, setLocked] = useState(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const startDelay = useCallback(() => {
    setLocked(true)
    timeout.current = setTimeout(() => setLocked(false), interval)
  }, [interval])

  useEffect(() => {
    if (!locked && source !== throttled) {
      setThrottled(source)
      startDelay()
    }
  }, [source, locked, throttled, startDelay])

  // Clear timeout on unmount
  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    },
    []
  )

  return throttled
}

export function useDebounce<T>(source: T, interval = 1_000) {
  const [debounced, setDebounced] = useState(source)

  useEffect(() => {
    if (source !== debounced) {
      const timeout = setTimeout(() => setDebounced(source), interval)
      return () => clearTimeout(timeout)
    }
    return undefined
  }, [source, debounced, interval])

  return debounced
}
