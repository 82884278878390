import { Logo } from '@/assets/img/Logo'
import { useMe } from '@/components/AuthenticationManager'
import { useCurrentEligibility } from '@/components/Forms/PaymentCreate/useCurrentEligibility'
import s from '@/components/Installments/Installments.module.css'
import { InstallmentsContent } from '@/components/Installments/InstallmentsContent'
import { ActiveTags, hasFeatureTag } from '@/types'
import { Button, CardTemplate, Header } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

export const Installments: VoidFunctionComponent = () => {
  const me = useMe()
  const navigate = useNavigate()

  const { eligibility, debouncedAmountInCents, feePlan } = useCurrentEligibility()

  const getContentToDisplay = () => {
    if (me.merchant.customer_choose_installments) {
      return (
        <div className={s.errorMessage} data-testid="healthcareOptionInstallments">
          <FormattedMessage
            id="installments.message.customer-choose-installments"
            defaultMessage="Installments will be available to the customer on their Alma payment page."
            description="On credit feeplans (over 4 installments), we can't display the installment plan information to the seller because of our credit indicator status (and not IOBSP). In this case, the end customer will see the information once he entered the checkout process."
          />
        </div>
      )
    }
    if (!debouncedAmountInCents) {
      return (
        <div className={s.errorMessage} data-testid="errorNoAmount">
          <FormattedMessage
            id="installments.error.no-amount"
            defaultMessage="Enter a payment amount."
            description="This is a message in the installment plan summary, it is displayed when the seller did not input an amount"
          />
          {hasFeatureTag(ActiveTags.selfServedCheckout, me) && (
            <div>
              <Button
                color="link"
                onClick={() => navigate('/simulated-payments')}
                data-testid="self-served-checkout-button"
              >
                <FormattedMessage
                  id="installments.message.self-served.checkout.list.link"
                  defaultMessage="Display the lasts simulations"
                  description="This is a link displayed on specific feature flag, to show the list of the sef served checkout prepayment made by customer on simulation"
                />
              </Button>
            </div>
          )}
        </div>
      )
    }
    if (!feePlan) {
      return (
        <div className={s.errorMessage} data-testid="errorNoAmount">
          <FormattedMessage
            id="installments.error.feePlan"
            defaultMessage="Select a fee plan."
            description="This is a message in the installment plan summary, it is displayed when the seller didn't input a feeplan and tried to validate the payment form"
          />
        </div>
      )
    }
    return <InstallmentsContent eligibility={eligibility.data} />
  }
  return (
    <CardTemplate
      data-testid="cardInstallments"
      padding="lg"
      header={
        <Header size="sm">
          <FormattedMessage
            id="installments.Title"
            defaultMessage="Installments"
            description="This is the title of the part of the payment form where the installments are filled"
          />
        </Header>
      }
      corner={<Logo className={s.logo} />}
    >
      {getContentToDisplay()}
    </CardTemplate>
  )
}
