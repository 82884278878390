type CsvScalar = string | number | undefined | null

export type ExportableObject = Array<{ [column: string]: CsvScalar }>

export interface CsvOptions {
  /**
   * Custom mapping for the CSV column headers
   * The keys will be used to match values in the row elements
   * The values will be used to display the column's header title
   */
  headers: Record<string, string>
}

const escapeCsvString = (input: string): string => input.replace(/"/g, '""')

/**
 * Turn an array of CSV exportable objects to a CSV file string
 * By default the keys of the first element in the list will be used as column headers
 * Custom column header mapping can be specified in the options
 */
export function objectToCsv(source: ExportableObject, options?: CsvOptions): string {
  const columns = options?.headers ? Object.keys(options.headers) : Object.keys(source?.[0] ?? {})
  const headerTitles = options?.headers ? columns.map((key) => options.headers[key]) : columns

  const lines: Array<string> = []
  lines.push(`"${headerTitles.map(escapeCsvString).join('","')}"`)
  source.forEach((entry) => {
    let line = ''
    columns.forEach((column, i) => {
      const item = entry[column]
      if (item) line += typeof item === 'string' ? `"${escapeCsvString(item)}"` : item
      if (i < columns.length - 1) line += ','
    })
    lines.push(line)
  })

  return lines.join('\n')
}
