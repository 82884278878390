import { differenceInCalendarDays, format, fromUnixTime } from 'date-fns'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { centsToEuros } from '@/utils/prices'

export const useCreatedDate = (created: number): string => {
  const intl = useIntl()

  return useMemo(() => {
    const createdPaymentDate = fromUnixTime(created)

    return intl.formatMessage(
      {
        id: 'payment.detail.date.time',
        defaultMessage: `{fromToday, select,
                        0 {Today}
                        1 {Yesterday}
                        other {On {date, date, long}}
                      } at {date, time, short}`,
        description:
          'This is the date format of the time at which the payment displayed in the payment tracking popup was created by the seller',
      },
      {
        date: createdPaymentDate,
        formattedTime: format(
          createdPaymentDate,
          intl.formatMessage({
            id: 'payment.detail.time.format',
            defaultMessage: "HH':'mm",
            description:
              'This is the time format of the time at which the payment displayed in the payment tracking popup was created by the seller',
          })
        ),
        fromToday: differenceInCalendarDays(new Date(), createdPaymentDate),
      }
    )
  }, [created, intl])
}

export const useCustomerName = (firstName?: string | null, lastName?: string | null): string => {
  const intl = useIntl()

  return firstName && lastName
    ? `${firstName} ${lastName}`
    : intl.formatMessage({
        id: 'payment.detail.emptyName',
        defaultMessage: 'New payment',
        description:
          'A payment could be display but but not having customer information set yet, in this case we want to display this message instead of the customer first and last name',
      })
}

export const useAmountAndPlanForConfiguredPayment = (
  installmentsCount: number | null,
  totalAmount: number,
  deferredDays?: number | null,
  deferredMonths?: number | null,
  option?: { isAlreadyInEuros?: boolean }
): string => {
  const intl = useIntl()
  const amountInEuros = option?.isAlreadyInEuros ? totalAmount : centsToEuros(totalAmount)

  return useMemo(() => {
    if (deferredDays) {
      return intl.formatMessage(
        {
          id: 'payment.detail.deferred.day',
          defaultMessage: '{amount, number, ::currency/EUR} at D+{days}',
          description:
            'In the payment tracking popup, this field displays the amount for a deferred payment set in days (in d+X days)',
        },
        {
          amount: amountInEuros,
          days: deferredDays,
        }
      )
    }
    if (deferredMonths) {
      return intl.formatMessage(
        {
          id: 'payment.detail.deferred.month',
          defaultMessage: '{amount, number, ::currency/EUR} at M+{months}',
          description:
            'In the payment tracking popup, this field displays the amount for a deferred payment set in month (in m+X days)',
        },
        {
          amount: amountInEuros,
          months: deferredMonths,
        }
      )
    }
    return intl.formatMessage(
      {
        id: 'payment.detail.pnx',
        defaultMessage: '{amount, number, ::currency/EUR} in {count} times',
        description:
          'In the payment tracking popup, this field displays the amount for a split payment',
      },
      {
        amount: amountInEuros,
        count: installmentsCount,
      }
    )
  }, [amountInEuros, deferredDays, deferredMonths, installmentsCount, intl])
}
