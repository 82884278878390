import { MdMValidatedPayment } from '@/components/PaymentsModal/ModalContent/MdMValidatedPayment'
import { PaymentSummary } from '@/components/PaymentsModal/OnlineSteps/Steps/Converted/PaymentSummary'
import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import { ButtonsAction, GreyZone, Header } from '@/components/PaymentsModal/Templates'
import { VerticalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import { Payment, isAtLeastRole } from '@/types'
import { COPY_IS_DISABLED, useCopy } from '@/utils/copyToClipboard'
import { Button, IconName, TextField } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMe } from '@/components/AuthenticationManager'

type Props = StepProps & {
  pathname: string
  isUserMDM: boolean
  payment: Payment
  customerEmail: Payment['customer']['email']
}

export const Converted: VoidFunctionComponent<Props> = ({
  firstAmountInEuros,
  amountInEuros,
  customerEmail,
  deferredDays,
  deferredMonths,
  amountAndPlan,
  customerName,
  createdDate,
  paymentShortId,
  pathname,
  isUserMDM,
  ActionButtons,
  payment,
  track,
  id,
}) => {
  const intl = useIntl()

  const me = useMe()

  const handleCopyShortPaymentId = useCopy(
    paymentShortId,
    intl.formatMessage({
      id: 'toast.copied.id.validation',
      defaultMessage: 'Payment id has been copied',
      description:
        'This is the message displayed when the user clicks on the copy button to copy the payment id',
    }),
    () => track('copyShortPaymentId'),
    intl
  )

  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />{' '}
      <GreyZone>
        <VerticalTitle icon={{ icon: IconName.tick, color: 'var(--alma-green)' }}>
          <FormattedMessage
            id="payment.status.titleConverted"
            defaultMessage="Everything is in order!"
            description="Title of the popup where the seller can see detailed information on a payment, when a payment has been successfully processed"
          />
        </VerticalTitle>
        <div className={s.greyZoneDetailContainer}>
          <p>
            <PaymentSummary
              firstAmountInEuros={firstAmountInEuros}
              amountInEuros={amountInEuros}
              deferredDays={deferredDays}
              deferredMonths={deferredMonths}
            />
          </p>
          {handleCopyShortPaymentId !== COPY_IS_DISABLED && (
            <div>
              <TextField
                id="copyButton"
                label={intl.formatMessage({
                  id: 'payment.step.detail.copy.label',
                  defaultMessage: 'Payment id',
                  description: `This is the label for the input that displays the payment id `,
                })}
                value={paymentShortId}
                readOnly
                className={s.copyInput}
              />
              <Button color="secondary" className={s.copyButton} onClick={handleCopyShortPaymentId}>
                <FormattedMessage
                  id="payment.detail.copy"
                  defaultMessage="Copy"
                  description="This button allows the merchant to copy the payment's id."
                />
              </Button>
            </div>
          )}
        </div>
      </GreyZone>
      <div className={s.convertedFooter}>
        <FormattedMessage
          id="payment.step.detail.content.converted"
          defaultMessage="We have sent the customer a confirmation email to this address: <strong>{email}</strong>"
          description="Content of the payment requirement step when the payment was successful"
          values={{
            email: customerEmail,
            strong: (chunks: string) => <strong>{chunks}</strong>,
          }}
        />
      </div>
      <div className={s.mdmButtons}>
        {isUserMDM ? (
          <MdMValidatedPayment
            payment={payment}
            paymentId={id}
            pathname={pathname}
            track={track}
            ActionButtons={ActionButtons}
          />
        ) : (
          <ButtonsAction>
            <ActionButtons.NewPayment color="secondary" block />
            {isAtLeastRole(me, 'salesperson', { exclusive: true }) ? (
              <ActionButtons.GoToDashboard color="secondary" block paymentId={id} />
            ) : (
              <ActionButtons.Close color="primary" block />
            )}
          </ButtonsAction>
        )}
      </div>
    </>
  )
}
