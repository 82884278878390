import { useFeePlansQuery } from '@/apis/hooks'
import { readPaymentFormValues } from '@/apis/readPaymentFormValues'
import { useActionsButtons } from '@/components/ActionsButtons'
import { useMe } from '@/components/AuthenticationManager'
import { PhoneNumberForm } from '@/components/Forms/PaymentCreate/PhoneNumberForm'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { mergeFormValues } from '@/components/Pages/Home/searchParams'
import { useCreatePaymentMethods } from '@/components/Pages/Home/usePaymentMethods'
import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import { GreyZone, Header } from '@/components/PaymentsModal/Templates'
import { Payment } from '@/types'
import { Button, Header as HeaderRC } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

export const UpdatePhoneNumber: VoidFunctionComponent<StepProps & { payment: Payment }> = ({
  amountAndPlan,
  createdDate,
  customerName,
  track,
  payment,
}) => {
  const intl = useIntl()
  type UpdatePhoneNumberValues = { update: { phone: { number: string } } }
  const form = useForm<UpdatePhoneNumberValues>({
    mode: 'onTouched',
    defaultValues: {
      update: { phone: { number: payment?.customer?.phone ?? '' } },
    },
  })
  const { cancelPaymentMutation } = useActionsButtons(track, payment)

  const { payBySms } = useCreatePaymentMethods(track)
  const me = useMe()
  const { data: feePlans } = useFeePlansQuery('pos', 'all', true)

  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />
      <GreyZone
        progressBarTitle={intl.formatMessage({
          id: 'payment.step.detail.progress.title.summary',
          defaultMessage: 'Summary',
          description:
            'Title of the progress bar in the payment detail modal for the summary of the payment',
        })}
        progress={10}
      >
        <HeaderRC size="md">
          <FormattedMessage
            id="payment.details.update.phone.number.title"
            defaultMessage="Update phone number"
            description="In the payment details modal, if the link was sent to a wrong number, the merchant can update it, this is a title of this step."
          />
        </HeaderRC>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((data) => {
              if (!payment) return
              if (payment.cancelable) cancelPaymentMutation.mutate()

              const paymentFormValues = readPaymentFormValues(payment, feePlans ?? [], me)
              const newPaymentFormValues = mergeFormValues(paymentFormValues, {
                phone: { number: data.update.phone.number },
              }) as PaymentCreateFormValues

              // payBySms is async, but it does not seem to work when submitting and async/await function
              void payBySms(newPaymentFormValues)
            })}
          >
            <div className={s.updatePhoneContainer}>
              <PhoneNumberForm name="update.phone.number" />
              <Button color="primary" type="submit">
                <FormattedMessage
                  id="payment.details.update.resend"
                  defaultMessage="Update phone number"
                  description="In the payment details modal, if the link was sent to a wrong number, the merchant can update it, this is the text in the button to validate new number"
                />
              </Button>
            </div>
          </form>
        </FormProvider>
      </GreyZone>
    </>
  )
}
