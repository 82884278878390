import { checkImageAccess, checkPredicate, HostsToCheck } from '@/accessChecker/checkAccess'
import { scheduleHostCheck } from '@/accessChecker/scheduleHostCheck'
import { searchAddress } from '@/apis/geocoding'
import { useMe } from '@/components/AuthenticationManager'
import { isPianoAnalyticsLoaded } from '@/thirdParties/analytics'
import axios from 'axios'
import { useEffect, VoidFunctionComponent } from 'react'

// Change this to true if you want to launch an access check in local
// In tests scheduleHostCheck() is mocked by default in setUpTests.ts
const ACTIVATE_IN_LOCAL = false

const HOSTS_TO_CHECK: HostsToCheck<string> = {
  'almapay.com': () =>
    axios
      .get('https://almapay.com/robots.txt', { timeout: 10_000 })
      .then(() => true)
      .catch(() => false),
  'api.mapbox.com': () =>
    searchAddress('alma')
      .then(() => true)
      .catch(() => false),
  'stonly.com': checkImageAccess('https://stonly.com/favicon.ico'),
  // delighted
  'd2yyd1h5u9mauk.cloudfront.net': checkImageAccess(
    'https://d2yyd1h5u9mauk.cloudfront.net/favicon.ico'
  ),
  // zendesk
  'static.zdassets.com': checkImageAccess('https://static.zdassets.com/favicon.ico'),
  // AT Internet
  'tag.aticdn.net': checkPredicate(isPianoAnalyticsLoaded),
  'logs1412.xiti.com': checkImageAccess(
    // https://logs1412.xiti.com/ is the domain used to log all the piano analytics events, the loggers generates an image
    'https://logs1412.xiti.com/'
  ),
  'sentry.io': checkImageAccess(
    // Sentry hosts their favicon on a CDN with another hostname (sentry-cdn.com). They serve the Alma organization avatar on their API, unprotected, though.
    'https://sentry.io/organization-avatar/69516dee6e1c4a6d98a2f4bf56c760a2/?s=120'
  ),
}

export const AccessChecker: VoidFunctionComponent = () => {
  const me = useMe()

  useEffect(
    () =>
      process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test' || ACTIVATE_IN_LOCAL
        ? scheduleHostCheck(me.merchant.id, HOSTS_TO_CHECK)
        : undefined,
    [me.merchant.id]
  )

  return null
}
