import { computeDisabledFieldsForUpdate } from '@/apis/readPaymentFormValues'
import { useActionsButtons } from '@/components/ActionsButtons'
import {
  PaymentCreateForm,
  usePaymentCreateForm,
} from '@/components/Forms/PaymentCreate/PaymentCreateForm'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { CopyLinkModal } from '@/components/Modals/CopyLinkModal/CopyLinkModal'
import s from '@/components/Pages/Home/FinalizePaymentPage/FinalizePaymentPage.module.css'
import { useUpdatePaymentMethods } from '@/components/Pages/Home/usePaymentMethods'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { useGoBack } from '@/utils/useGoBack'
import React, { FunctionComponent, useMemo } from 'react'
import { DeepPartial, FormProvider } from 'react-hook-form'

interface Props extends TrackerProps {
  initialFormValues: DeepPartial<PaymentCreateFormValues>
  updatePaymentMethodsHandlers: ReturnType<typeof useUpdatePaymentMethods>
}

export const FinalizePayment: FunctionComponent<Props> = ({
  initialFormValues,
  updatePaymentMethodsHandlers: { payment, isLinkAvailable, ...paymentMethodsHandler },
  track,
}) => {
  const goBack = useGoBack('/')

  const { CancelOrRestartDialogs, CancelOrRestartButtons } = useActionsButtons(track, payment)

  const form = usePaymentCreateForm(initialFormValues)

  // Disable all fields that already have a value in the existing payment (they can't be edited)
  const disabledFields = useMemo(
    () => (payment ? computeDisabledFieldsForUpdate(payment) : []),
    [payment]
  )

  return (
    <FormProvider {...form}>
      <CancelOrRestartDialogs onCancelled={goBack} />
      <PaymentCreateForm
        track={track}
        disabledFields={disabledFields}
        forms={
          <div className={s.cancelButtons}>
            <CancelOrRestartButtons color="link" />
          </div>
        }
        paymentMethodsHandler={paymentMethodsHandler}
      />
      <CopyLinkModal
        isOpen={Boolean(isLinkAvailable && payment)}
        onClose={goBack}
        link={payment?.url ?? ''}
        paymentId={payment?.id ?? ''}
      />
    </FormProvider>
  )
}
