import { hydratePayment } from '@/apis/utils'
import { Payment, PaymentData } from '@/types'
import { AxiosInstance } from 'axios'
import { getUnixTime } from 'date-fns'

export type GetPaymentPollOptions = {
  startDate: Date
}
export const getPaymentPollGenerator =
  (client: AxiosInstance) =>
  async (options: GetPaymentPollOptions, signal?: AbortSignal): Promise<Array<Payment>> => {
    const start = getUnixTime(options.startDate)
    const end = getUnixTime(Date.now())
    const { data } = await client.get<PaymentData>(
      `v1/pos/payments-poll?start=${start}&end=${end}`,
      {
        signal,
      }
    )
    return data.data.map((payment) => hydratePayment(payment))
  }
