import { useGetPaymentQuery } from '@/apis/hooks'
import s from '@/components/Pages/PaymentBarcode/PaymentBarcode.module.css'
import { PaymentBarcodeContent } from '@/components/Pages/PaymentBarcode/PaymentBarcodeContent'
import { useTracking } from '@/thirdParties/analytics'
import { useGoBack } from '@/utils/useGoBack'
import { LoadingIndicator, Modal } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams, useResolvedPath } from 'react-router-dom'

export const PaymentBarcode: VoidFunctionComponent = () => {
  const { paymentId } = useParams<'paymentId'>()
  const { pathname } = useResolvedPath('..')
  const goBack = useGoBack(`/payment${pathname === '/' ? '' : 's'}/${paymentId}`)
  const { data: payment, isLoading, isError } = useGetPaymentQuery(paymentId)
  useTracking('payment_barcode')

  return (
    <Modal shouldCloseOnOverlayClick={false} isOpen onClose={goBack} scrollable size="lg">
      {!payment && (
        <div className={s.loading}>
          {isLoading && <LoadingIndicator />}
          {isError && (
            <FormattedMessage
              id="paymentDetails.error"
              defaultMessage="Unable to retrieve payment"
              description="This is the error displayed when the popup to track payment progress could not be displayed for technical reasons"
            />
          )}
        </div>
      )}
      {payment && <PaymentBarcodeContent goBack={goBack} payment={payment} />}
    </Modal>
  )
}
