import { useSimulatedPayment } from '@/apis/hooks/queries/UseSimulatedPayment'
import { PaymentSimulatedList } from '@/components/Pages/PaymentSimulatedPage/PaymentSimulatedList'
import s from '@/components/Pages/PaymentSimulatedPage/PaymentSimulatedPage.module.css'
import { useTracking } from '@/thirdParties/analytics'
import { useGoBack } from '@/utils/useGoBack'
import { Button, Header, LoadingIndicator, Modal } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const PaymentSimulatedPage: VoidFunctionComponent = () => {
  const goBack = useGoBack('/')
  const track = useTracking('simulated_payments')
  const simulatedPayments = useSimulatedPayment()
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen
      onClose={goBack}
      scrollable
      size="lg"
      padding="xl"
      closeIcon
    >
      <Header size="md">
        <FormattedMessage
          id="payment.simulated.list.header"
          defaultMessage="Lasts simulations created"
          description="Header of the payment simulated list modal."
        />
      </Header>
      <div className={s.container}>
        {simulatedPayments.isLoading && <LoadingIndicator />}
        {simulatedPayments.isError && <div>Error page</div>}
        {!simulatedPayments.isError && simulatedPayments.data && (
          <>
            <PaymentSimulatedList simulatedPayments={simulatedPayments.data} />
            <Button
              color="link"
              onClick={() => {
                goBack()
                track('no_matching_simulations')
              }}
              className={s.noMatchingSimulationsButton}
              data-testid="no-matching-payment-button"
            >
              <FormattedMessage
                id="message.no.matching.simulations.button"
                defaultMessage="I can't find the simulation, I create a new payment."
                description="This is a message displayed if no simulated payment matches. When clicked it takes the merchant back to the home page"
              />
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}
