import { sendPaymentBySms } from '@/apis/api'
import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import { PaymentOrigin, Payment } from '@/types'
import { handleError, parseAxiosError } from '@/utils'
import { COPY_IS_DISABLED, useCopy } from '@/utils/copyToClipboard'
import { useCountdown } from '@/utils/useCountdown'
import { Button, Spinner, toastMessage } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { useMe } from '@/components/AuthenticationManager'

type Props = {
  paymentOrigin: StepProps['paymentOrigin']
  track: StepProps['track']
  paymentLink: StepProps['paymentLink']
  customerPhone: StepProps['customerPhone']
  id: StepProps['id']
}
export const AdditionalActionPerOrigin: VoidFunctionComponent<Props & { payment: Payment }> = ({
  paymentOrigin,
  track,
  paymentLink,
  customerPhone,
  id,
  payment,
}) => {
  const intl = useIntl()
  const [countdown, setCountdown] = useCountdown()
  const [searchParams, setSearchParams] = useSearchParams()
  const me = useMe()

  const handleCopyPaymentLink = useCopy(
    paymentLink,
    intl.formatMessage({
      id: 'toast.copied.paymentLink',
      defaultMessage: 'Payment link copied',
      description:
        'This is a message displayed when the payment link has been copied. It is displayed for 5 seconds as a' +
        ' confirmation.',
    }),
    () => track('copy_payment_link'),
    intl
  )
  if (paymentOrigin === PaymentOrigin.posLink && handleCopyPaymentLink !== COPY_IS_DISABLED) {
    return (
      <Button color="link" onClick={handleCopyPaymentLink}>
        <FormattedMessage
          id="payment.step.detail.copy.paymentLink"
          defaultMessage="Copy the payment link again"
          description="Button to copy the payment link"
        />
      </Button>
    )
  }
  if (paymentOrigin === PaymentOrigin.posSms) {
    return (
      <div className={s.additionalButtonsContainer}>
        <Button
          color="link"
          disabled={countdown > 0}
          onClick={async () => {
            try {
              setCountdown(10)
              await sendPaymentBySms(id)
              toastMessage({
                type: 'info',
                title: intl.formatMessage(
                  {
                    id: 'toast.sms.send.confirmation',
                    defaultMessage: 'Payment link sent to {phoneNumber}',
                    description:
                      'This is a message displayed when a payment link was successfully sent by text message. It is displayed for 5 seconds as a confirmation.',
                  },
                  { phoneNumber: customerPhone }
                ),
                duration: 5000,
                closable: true,
              })
            } catch (e) {
              handleError(
                parseAxiosError(e, {
                  userMessage: intl.formatMessage({
                    id: 'toast.sms.send.error',
                    defaultMessage: 'Failed to send payment link by text',
                    description:
                      'This is a message displayed when a payment link was not sent for technical reasons. It is displayed for 5 seconds as a confirmation.',
                  }),
                })
              )
            }
          }}
        >
          <FormattedMessage
            id="payment.step.detail.resendSMS"
            defaultMessage="Resend payment link"
            description="Button to resend by SMS the payment link"
          />
          {countdown > 0 && (
            <div className={s.countdownContainer} data-testid="countdown">
              <span>{countdown}</span>
              <Spinner />
            </div>
          )}
        </Button>
        {me.merchant.id === payment.merchant_id ? (
          <Button
            color="link"
            onClick={() => {
              searchParams.set('update-phone-number', '')
              setSearchParams(searchParams, { replace: true })
            }}
          >
            <FormattedMessage
              id="payment.details.update.phone.button"
              defaultMessage="Update phone number"
              description="In the payment details modal, if the link was sent to a wrong number, the merchant can update it, this is the text in the button to update the number"
            />
          </Button>
        ) : null}
      </div>
    )
  }
  return null
}
