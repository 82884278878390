import { useGetPaymentPollQuery } from '@/apis/hooks'
import { useMe } from '@/components/AuthenticationManager'
import { PaymentSearchForm } from '@/components/Forms/PaymentList'
import { PaymentListFormValues, PaymentPeriodFilter } from '@/components/Forms/PaymentList/types'
import s from '@/components/Pages/PaymentFollowup/PaymentFollowup.module.css'
import { PaymentFollowupContext } from '@/components/Pages/PaymentFollowup/PaymentFollowupContext'
import { PaymentAmountForTheDay } from '@/components/PaymentAmountForTheDay'
import { PaymentList } from '@/components/PaymentList'
import { startDateFor } from '@/components/PaymentList/PaymentList'
import { PaymentsExport } from '@/components/PaymentsExport'
import { useLocale } from '@/intl/I18nContext'
import { useTracking } from '@/thirdParties/analytics'
import { enableSurvey } from '@/thirdParties/survey/delightedSurvey'
import { isAtLeastRole, Payment } from '@/types'
import { LoadingIndicator } from '@alma/react-components'
import { fromUnixTime, isWithinInterval, subDays } from 'date-fns'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Outlet } from 'react-router-dom'

export const PaymentFollowup: FunctionComponent = () => {
  const track = useTracking('payments')
  const me = useMe()

  const form = useForm<PaymentListFormValues>({
    mode: 'onTouched',
    defaultValues: { period: PaymentPeriodFilter.week, status: 'all', name: '' },
  })

  const [period, status] = form.watch(['period', 'status', 'name'])

  // Track filter for period
  useEffect(() => {
    track(`filter-payments-period-${period}`)
  }, [period, track])

  // Track filter for status
  useEffect(() => {
    track(`filter-payments-status-${status}`)
  }, [status, track])

  // Track filter for name
  useEffect(() => {
    if (form.formState.dirtyFields?.name) {
      track(`filter-payments-name-search`)
    }
  }, [form.formState.dirtyFields, track])

  const startDate = startDateFor[period]()

  const payments = useGetPaymentPollQuery({ startDate })

  const [filteredPayments, setFilteredPayments] = useState([] as Array<Payment>)

  const sortedPayments = useMemo(() => {
    if (payments.isLoading || !payments.data) return []
    return payments.data.sort((a, b) => b.created - a.created)
  }, [payments])

  const { locale } = useLocale()
  useEffect(() => {
    if (
      sortedPayments.length > 0 &&
      isWithinInterval(fromUnixTime(sortedPayments[0].created), {
        start: subDays(new Date(), 5),
        end: new Date(),
      })
    ) {
      enableSurvey(locale, me.email, me.merchant.id)
    }
  }, [sortedPayments, locale, me])

  const contextValue = useMemo(
    () => ({ sortedPayments, filteredPayments, setFilteredPayments }),
    [filteredPayments, sortedPayments]
  )

  return (
    <div className={s.container} data-testid="paymentsPage">
      {payments.isLoading && <LoadingIndicator />}
      {payments.isError && <div>Error page</div>}
      {payments.data && (
        <PaymentFollowupContext.Provider value={contextValue}>
          <FormProvider {...form}>
            <form
              noValidate
              className={s.columns}
              data-testid="payments-page"
              onSubmit={(event) => {
                event.preventDefault()
              }}
            >
              <div className={s.buttonGroups}>
                <PaymentSearchForm track={track} />
                {isAtLeastRole(me, 'salesperson', { exclusive: true }) && (
                  <PaymentsExport track={track} />
                )}
                <PaymentAmountForTheDay track={track} />
              </div>

              <div className={s.list}>
                <PaymentList track={track} />
              </div>
            </form>
          </FormProvider>
        </PaymentFollowupContext.Provider>
      )}
      <Outlet />
    </div>
  )
}
