import { Payment, PaymentOrigin } from '@/types'
import { getPaymentStatusString } from '@/utils/payment'
import { paymentIdToShortPaymentId } from '@/utils/paymentIdToShortPaymentId'
import { centsToEuros } from '@/utils/prices'
import { fromUnixTime } from 'date-fns'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
  useAmountAndPlanForConfiguredPayment,
  useCreatedDate,
  useCustomerName,
} from '@/utils/usePartialPaymentDetails'

export type PaymentDetailsProps = Pick<
  Payment,
  | 'created'
  | 'customer'
  | 'deferred_days'
  | 'deferred_months'
  | 'installments_count'
  | 'payment_plan'
  | 'purchase_amount'
  | 'requirementStep'
  | 'status'
  | 'updated'
  | 'id'
  | 'origin'
  | 'url'
>

type UsePaymentDetailsResult = {
  createdDate: string
  updatedDate: Date
  customerName: string
  customerPhone: string
  amountAndPlan: string
  paymentStatus: {
    label: string
    color: 'green' | 'red' | 'blue' | 'orange'
  }
  amountInEuros: number
  firstAmountInEuros: number
  paymentShortId: string
  paymentOrigin: PaymentOrigin
  paymentLink: string
  id: string
  deferredDays: number
  deferredMonths: number
}

export const usePaymentDetails = ({
  created,
  customer,
  deferred_days: deferredDays,
  deferred_months: deferredMonths,
  installments_count,
  purchase_amount,
  status,
  updated,
  requirementStep,
  payment_plan,
  id,
  origin: paymentOrigin,
  url: paymentLink,
}: PaymentDetailsProps): UsePaymentDetailsResult => {
  const intl = useIntl()
  const amountInEuros = centsToEuros(purchase_amount)
  const paymentShortId = paymentIdToShortPaymentId(id)
  const createdDate = useCreatedDate(created)
  const customerName = useCustomerName(customer.first_name, customer.last_name)
  const amountAndPlanForConfiguredPayment = useAmountAndPlanForConfiguredPayment(
    installments_count,
    purchase_amount,
    deferredDays,
    deferredMonths
  )

  const firstAmountInEuros = useMemo(() => {
    if (payment_plan.length > 1 && !deferredDays && !deferredMonths) {
      return centsToEuros(payment_plan[0].purchase_amount + payment_plan[0].customer_fee)
    }
    // When a payment is deferred, a first payment_plan is created with the amount 0 (since the customer will only pay later), so the amount that should be displayed in the Step is actually the one from the 2nd payment_plan
    if (deferredDays || deferredMonths) {
      return payment_plan[1]
        ? centsToEuros(payment_plan[1].purchase_amount + payment_plan[1].customer_fee)
        : centsToEuros(purchase_amount)
    }
    return centsToEuros(purchase_amount)
  }, [payment_plan, deferredDays, deferredMonths, purchase_amount])

  const customerPhone = customer.phone || ''
  const amountAndPlan = useMemo(() => {
    if (
      requirementStep === 'payment_not_configured' ||
      // When the installments_count is 0, we don't want to display "in 0 times".
      // (It should only happen in the case of an healthcare payment, when the customer has not configured its plan.)
      installments_count === 0
    ) {
      return intl.formatMessage(
        {
          id: 'payment.detail.not_started',
          defaultMessage: '{amount, number, ::currency/EUR}',
          description:
            'Displays the total purchase amount of a payment in a monetary style. This is reused in multiple places.',
        },
        {
          amount: amountInEuros,
        }
      )
    }

    return amountAndPlanForConfiguredPayment
  }, [installments_count, amountInEuros, intl, requirementStep, amountAndPlanForConfiguredPayment])

  const paymentStatus = useMemo(() => {
    const label = getPaymentStatusString(status, intl)
    switch (status) {
      case 'expired':
        return { color: 'orange' as const, label }
      case 'validated':
        return { color: 'green' as const, label }
      case 'denied':
        return { color: 'red' as const, label }
      default:
        return { color: 'blue' as const, label }
    }
  }, [intl, status])

  const updatedDate = fromUnixTime(updated)

  return {
    createdDate,
    updatedDate,
    customerName,
    amountAndPlan,
    paymentStatus,
    amountInEuros,
    firstAmountInEuros,
    paymentShortId,
    paymentOrigin,
    paymentLink,
    id,
    customerPhone,
    deferredDays,
    deferredMonths,
  }
}
