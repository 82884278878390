import { RedirectMe } from '@/apis/api/postLogin'
import { ApiPayment, Me, Payment, PaymentRequirementStep, PaymentStatus } from '@/types'
import { isDefined } from '@alma/react-components'

export function isRedirectMe(value: Me | RedirectMe): value is RedirectMe {
  return 'redirect' in value
}

export function getPaymentStatus(payment: ApiPayment): PaymentStatus {
  if (payment.state === 'scored_no') return 'denied'
  if (payment.expired_at) return 'expired'
  if (['paid', 'in_progress', 'late', 'default'].includes(payment.state)) return 'validated'
  return 'pending'
}

export function isPaymentCancellable(payment: ApiPayment): boolean {
  return (
    ['not_ready', 'not_started', 'scored_maybe', 'scored_yes'].includes(payment.state) &&
    !payment.expired_at
  )
}

/*
 * 'valid_payment_method' is the default message for the first step of the payment process.
 * Some step have a specific return because depending on some condition the step is net exactly the same
 * Also, some steps are not yet implemented or ready to display so we return a default value
 * banking_data_verification has two different meaning, one for the Business Insight option and the second for the declaration of banking data
 * not_ready is the default status when a user is in healthcare mode and didn't have yet configure his payment on checkout, in this case we display the default message that is "valid_payment_method" it return payment_not_configured
 * A started payment with no requirements, that is not expired or paid, should never happen. If it does, it indicates an error on the backend. Sentry should therefore be used here to alert an error.
 * */

export function getPaymentRequirementStep(
  payment: ApiPayment,
  status: PaymentStatus
): PaymentRequirementStep {
  if (status === 'expired') return 'expired'
  if (status === 'validated') return 'converted'
  if (status === 'denied') return 'rejected'

  if (payment.state === 'not_ready') return 'payment_not_configured'
  if (payment.state === 'scored_yes' && !payment.customer.card?.verified) {
    return 'payment_validation'
  }

  const currentStep = payment.requirements.find((requirement) => !requirement.is_met)

  // FIXME : When statuses are refactored, we will want to differentiate valid_payment_method and customer_info
  if (payment.state === 'not_started' && !currentStep) return 'valid_payment_method'

  if (currentStep?.name === 'banking_data_verification') {
    if (currentStep.details?.banking_provider === 'customer_financial_declaration') {
      return 'banking_data_verification_declaration'
    }

    return 'banking_data_verification_BI'
  }

  return currentStep?.name ?? 'error_step'
}

export function hydratePayment(payment: ApiPayment): Payment {
  const status = getPaymentStatus(payment)

  return {
    ...payment,
    status,
    cancelable: isPaymentCancellable(payment),
    requirementStep: getPaymentRequirementStep(payment, status),
  }
}

export const hasInstallments = (payment: ApiPayment): boolean =>
  isDefined(payment.installments_count) && payment.installments_count > 0
