import { z } from 'zod'

export interface EligibilityValidatedBase {
  eligible: true
  customer_total_cost_amount: number
  customer_total_cost_bps: number
  deferred_days: number
  deferred_months: number
  installments_count: number
  payment_plan: PaymentPlan[]
}

export interface EligibilityValidatedCredit extends EligibilityValidatedBase {
  annual_interest_rate: number
  customer_fee: 0
  customer_interest: number
}

export function isEligibilityValidatedCredit(
  eligibility: EligibilityValidated
): eligibility is EligibilityValidatedCredit {
  return eligibility.installments_count > 4
}

export interface EligibilityValidatedNonCredit extends EligibilityValidatedBase {
  customer_fee: number
  customer_interest: 0
}

export type EligibilityValidated = EligibilityValidatedNonCredit | EligibilityValidatedCredit

export interface EligibilityRefused {
  eligible: false
  constraints: unknown
  reasons: {
    installments_count?: string
    merchant?: string
    purchase_amount?: string
  }
  deferred_days: number
  deferred_months: number
  installments_count: number
}

export type Eligibility = EligibilityValidated | EligibilityRefused

export interface PaymentPlan {
  customer_fee: number
  customer_interest: number
  due_date: number
  purchase_amount: number
  total_amount: number
}

export const PaymentPlanStarted = z.object({
  customer_fee: z.number(),
  customer_interest: z.number(),
  due_date: z.number(),
  original_purchase_amount: z.number(),
  purchase_amount: z.number(),
  state: z.enum(['pending', 'paid', 'covered', 'incident', 'awaiting_confirmation']),
  id: z.string(),
  total_amount: z.number().optional(),
})

export type PaymentPlanStarted = z.infer<typeof PaymentPlanStarted>
