import installmentsStyle from '@/components/Installments/Installments.module.css'
import s from '@/components/Installments/InstallmentsTotal/InstallmentsTotal.module.css'
import cx from 'classnames'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

type Props = {
  totalInEuros: number
}

export const InstallmentsTotal: VoidFunctionComponent<Props> = ({ totalInEuros }) => (
  <div className={cx(installmentsStyle.installment, s.total)}>
    <div>
      <FormattedMessage
        id="installments.total-amount"
        defaultMessage="Total (incl. VAT)"
        description="This is the total amount of the operation displayed in the installment plan summary in the payment form, including fees if the merchant decided to apply some fee sharing and did not waive it"
      />
    </div>
    <div>
      <FormattedNumber value={totalInEuros} style="currency" currency="EUR" />
    </div>
  </div>
)
