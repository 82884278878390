import { z } from 'zod'

export type SimulatedPayment = {
  purchase_amount: number
  created: string
  fee_plan: {
    installments_count: number
    deferred_days?: number
    deferred_months?: number
  }
  address: {
    first_name?: string
    last_name?: string
    company?: string
    line1?: string
    line2?: string
    postal_code?: string
    city?: string
    county_sublocality?: string
    state_province?: string
    country?: string
    email?: string
    phone?: string
  }
}

export const SimulatedAddress = z.object({
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  company: z.string().optional(),
  line1: z.string().optional(),
  line2: z.string().optional(),
  postal_code: z.string().optional(),
  city: z.string().optional(),
  county_sublocality: z.string().optional(),
  state_province: z.string().optional(),
  country: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
})

export type SimulatedAddress = z.infer<typeof SimulatedAddress>

export const SimulatedFeePlan = z.object({
  installments_count: z.number(),
  deferred_days: z.number().optional(),
  deferred_months: z.number().optional(),
})

export type SimulatedFeePlan = z.infer<typeof SimulatedFeePlan>

export const SimulatedPaymentApi = z.object({
  purchase_amount: z.number(),
  address: SimulatedAddress,
  created: z.string(),
  fee_plan: SimulatedFeePlan,
})

export type SimulatedPaymentApi = z.infer<typeof SimulatedPaymentApi>
