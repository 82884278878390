import { SimulatedPaymentApi } from '@/types/SimulatedPayment'
import { AxiosInstance } from 'axios'

export type GetSimulatedPayment = { data: Array<SimulatedPaymentApi> }

export const getSimulatedPaymentGenerator =
  (client: AxiosInstance) => async (signal?: AbortSignal) => {
    const { data } = await client.get<GetSimulatedPayment>(`v1/pos/simulator-data`, {
      signal,
    })
    return data.data
  }
