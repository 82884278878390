import { AxiosInstance } from 'axios'

interface GetZendeskTokenResponseBody {
  token: string
}

// This function is only ever called in an untested component
// See components/ZendeskButton
/* istanbul ignore next */
export const getZendeskTokenGenerator = (client: AxiosInstance) => async () => {
  const {
    data: { token },
  } = await client.get<GetZendeskTokenResponseBody>('v1/pos/zendesk/token')
  return token
}
