import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import {
  ButtonsAction,
  GreyZone,
  Header,
  ItDoesNotWorkCollapsible,
  List,
  ListItem,
} from '@/components/PaymentsModal/Templates'
import { HorizontalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const PhonePnxVerification: VoidFunctionComponent<StepProps> = ({
  amountAndPlan,
  customerName,
  createdDate,
  updatedDate,
  customerPhone,
  ActionButtons,
}) => {
  const intl = useIntl()

  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />{' '}
      <GreyZone
        progressBarTitle={intl.formatMessage({
          id: 'payment.step.detail.progress.title.verifications',
          defaultMessage: 'Verifications',
          description:
            'Title of the progress bar in the payment detail modal for the verifications steps',
        })}
        progress={40}
      >
        <HorizontalTitle updated={updatedDate}>
          <FormattedMessage
            id="payment.detail.requirementStep.phone_number_verification"
            defaultMessage="Checking the phone number"
            description="Title of the payment requirement step in which the client have to verify their phone number"
          />
        </HorizontalTitle>
        <List>
          <ListItem>
            <FormattedMessage
              id="payment.detail.phone.number.code.sent"
              defaultMessage="The code has been sent to <b>{phoneNumber}</b>"
              description="In the payment's detail modal, when the link has been sent by SMS, there is a text to show ne number it has been sent to."
              values={{
                phoneNumber: customerPhone,
                b: (content: string) => <strong className={s.phoneNumber}>{content}</strong>,
              }}
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="payment.detail.phone.number.code.validity"
              defaultMessage="Your client has 10 minutes to enter the verification code"
              description="In the payment's detail modal, when the link has been sent by SMS, there is a text to show ne number it has been sent to."
            />
          </ListItem>
        </List>
      </GreyZone>
      <ItDoesNotWorkCollapsible>
        <List>
          <ListItem>
            <FormattedMessage
              id="payment.detail.phone.number.not.working.time"
              defaultMessage="The verification code is valid for 10 minutes"
              description="This message let the merchant know that the verification code is valid for 10 minutes"
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="payment.detail.phone.number.not.working.restart"
              defaultMessage="The phone number is incorrect? We invite you to restart the payment."
              description="This message let the merchant know that if the phone number is incorrect, they can restart the payment"
            />
          </ListItem>
        </List>
        <ButtonsAction>
          <ActionButtons.Restart color="link" />
          <ActionButtons.Cancel color="link" />
        </ButtonsAction>
      </ItDoesNotWorkCollapsible>
    </>
  )
}
