import { PaymentContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/types'
import { createContext, useContext } from 'react'

/* For log/tracking reasons, we want to know the context of the payment's creation
 * It can be :
 * - A payment created from scratch by filling the form
 * - A payment that has been restarted
 * - A payment that has been pre-filled from URL Params
 * - A payment that has been pre-filled from simulated payment (self-served checkout)
 */

type PaymentCreationContextType = {
  paymentCreationContext: PaymentContext
  setPaymentCreationContext: (context: PaymentContext) => void
}

const initialPaymentContext = {
  paymentCreationContext: PaymentContext.newPayment,
  setPaymentCreationContext: () => {},
}

export const PaymentCreationContext =
  createContext<PaymentCreationContextType>(initialPaymentContext)

export const usePaymentCreationContext = (): PaymentCreationContextType =>
  useContext(PaymentCreationContext)
