import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

type Props = {
  firstAmountInEuros: StepProps['firstAmountInEuros']
  amountInEuros: StepProps['amountInEuros']
  deferredDays: StepProps['deferredDays']
  deferredMonths: StepProps['deferredMonths']
}
export const PaymentSummary: VoidFunctionComponent<Props> = ({
  firstAmountInEuros,
  amountInEuros,
  deferredDays,
  deferredMonths,
}) => {
  if (deferredDays) {
    return (
      <FormattedMessage
        id="payment.detail.requirementStep.deferred.days"
        defaultMessage="Your customer will be charged {firstAmount, number, ::currency/EUR} in {deferredDays, plural, one {# day} other {# days}}.{newline}The total amount of {amount, number, ::currency/EUR} will be paid to you in the next few days."
        description="Title of the payment requirement step when the payment was successful"
        values={{
          firstAmount: firstAmountInEuros,
          newline: <br />,
          amount: amountInEuros,
          deferredDays,
        }}
      />
    )
  }
  if (deferredMonths) {
    return (
      <FormattedMessage
        id="payment.detail.requirementStep.deferred.months"
        defaultMessage="Your customer will be charged {firstAmount, number, ::currency/EUR} in {deferredMonths, plural, one {# month} other {# months}}.{newline}The total amount of {amount, number, ::currency/EUR} will be paid to you in the next few days."
        description="Title of the payment requirement step when the payment was successful"
        values={{
          firstAmount: firstAmountInEuros,
          newline: <br />,
          amount: amountInEuros,
          deferredMonths,
        }}
      />
    )
  }
  return (
    <FormattedMessage
      id="payment.detail.requirementStep.converted"
      defaultMessage="Your customer has been charged their first installment of {firstAmount, number, ::currency/EUR}.{newline}The total amount of {amount, number, ::currency/EUR} will be paid to you in the next few days."
      description="Title of the payment requirement step when the payment was successful"
      values={{
        firstAmount: firstAmountInEuros,
        newline: <br />,
        amount: amountInEuros,
      }}
    />
  )
}
